import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function ExplainerVideos() {
    const data ={
        title: `Explaniner\n Videos`,
        description:"Are you looking for engrossing educational movies that will engage your interest, educate you, and make you smile? You've found it! Our talented team specializes in producing animated explanation videos that give your concepts life via imaginative storytelling. We've got you covered, whether it's for your enterprise, goods, or services. Let us produce an engaging explanation video for you that is visually spectacular and makes a lasting impression on your audience. Get in touch with us right away to begin this thrilling journey!",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712597/lotties%20Json%20Files/explainer_cjmwvt.json",
        head:{
          title:"Explainer Videos | NYC Pro Web Designers | NY 10036",
          description:"NYC's leading web designers specialize in crafting compelling explainer videos for your brand.",
          canonical:`<link href="https://nycprowebdesigners.com/explainer-videos" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. How much time is typically needed to create an explanatory video?",
        answer: `<p>Project duration can vary based on complexity, typically taking around 2 to 4 weeks to craft an informative video.</p>`,
      },
      {
        question: "2. Is it possible for me to supply my script and voiceover for the explanatory video?",
        answer: `<p>Absolutely! We're ready to collaborate with your script and voiceover, breathing life into your vision.</p>`,
      },
      {
        question: "3. Do you provide the option for revisions if I find the initial video unsatisfactory",
        answer:
          "<p>Yes, we provide revisions to ensure your complete satisfaction with the outcome.</p>",
      },
      {
        question: "4. In what formats will the final explanatory video be delivered?",
        answer:
          "<p>The finished explainer video is delivered in popular file formats like MP4 or MOV.</p>",
      },
      {
        question: "5. Can you assist with distributing and promoting my explanatory video, including strategies for marketing it effectively?",
        answer:
          "<p>Certainly, we can offer guidance on distributing your video and effective marketing strategies to amplify its impact.</p>",
      },
    ];
  return (
    <ServicesPage data={data} >
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Introducing Our Alluring Explainer Video Services: 
          </h2>
          <br />
          <p className="text-justify text-lg">
          Enticing, Educate, and Delight with Animated Explanatory Videos!
            <br />
            In search of spellbinding explanatory videos that thoroughly engage, educate, and delight? Your search concludes here! 
            <br />
            Our proficient team is dedicated to the craft of crafting animated explanatory videos. Melding creativity with storytelling finesse, we breathe life into your concepts. Whether your requirement is explanatory videos for your enterprise, merchandise, or offerings, our animated explanatory video provisions envelop all. Permit us to mold a visually magnificent and enthralling explanatory video, bound to inscribe an enduring mark on your spectators. 
            <br />
            Reach out to us at your earliest convenience to explain this journey!
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Board On Our Innovative Journey: 
          </h2>
          <br />
          <p className="text-justify">
          Transforming Your Vision Into Reality With Unparalleled Expertise!
          </p>
          <ul>
            <li>
              <strong>
              Embrace The Spotlight - Initial Consultation: 
              </strong>
            </li>
            <p>
            Engage with our team to fully comprehend your vision and objectives, fostering a collaborative foundation.
            </p>
            <li>
              <strong>Crafting Enchantment With Words - Script Elevation: </strong>
            </li>
            <p>
            Our team of skilled writers weaves an engaging script that enthralls and resonates with your target audience, elevating the storytelling experience.
            </p>

            <li>
              <strong>Unveiling Visual Narratives - Storyboard Ingenuity:</strong>
            </li>
            <p>
            Immerse yourself in the dynamic visualization of the narrative's ebb and flow, as our experts meticulously craft a storyboard that accentuates scene compositions.
            </p>

            <li>
              <strong>Artistry Beyond Imagination - Illustration Conjuring: </strong>
            </li>
            <p>
            Witness the infusion of life into your story through the artistic prowess of our adept illustrators, who conjure up stunning visuals that transcend the ordinary.
            </p>


            <li>
              <strong>Characters Speak - Voice Over Enchantment: </strong>
            </li>
            <p>
            Select from a diverse array of professional voice actors who lend their voices to your characters, imbuing them with depth and personality.
            </p>

            <li>
              <strong>Animating Dreams - Extravaganza Of Motion:</strong>
            </li>
            <p>
            Observe the enchanting transformation of your narrative into a living, breathing entity through the seamless and attractive artistry of animation.
            </p>
            <li>
              <strong>Symphony Of Sonic Enhancement - Sound Design Alchemy:</strong>
            </li>
            <p>
            Immerse your audience in an auditory journey like never before, as our audio experts skillfully blend sounds to create an immersive and enriching sonic landscape.
            <br />
            Indulge in the enchantment woven by our creative odyssey, as we actualize your concepts into tangible wonders! Connect with us today.
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Accouter The Potency Of Animated Presentations For Your Enterprise
          </h2>
          <br />
          <p className="text-justify">
          Subsume the power of dynamic visual statements of your firm through enlightened communication with animated presentations that summarily convey complex ideas, leaving a long-lasting impression. Infuse Life into Information through our animated presentations transcend traditional methods, infusing vitality and clarity into your content, attracting audiences, and enhancing understanding.
          <br />
          Providing the best through our amazing offering of;
          </p>
          <ul>
            <li>
              <strong>
              Accelerated Omni channel Engagement: 
              </strong>
            </li>
            <p>
            Seamlessly extend your reach across diverse platforms and enthrall your audience using meticulously crafted animated videos.
            </p>
            <li>
              <strong>Spark Brand Awareness: </strong>
            </li>
            <p>
            Rise above your competitors by creating an indelible impact through visually mesmerizing animations that etch your brand into viewers' minds.
            </p>

            <li>
              <strong>Animated Showcase Of Products And Services: </strong>
            </li>
            <p>
            Give life to your offerings through dynamic and unforgettable portrayals, etching a vivid memory that lingers.
            </p>

            <li>
              <strong>Unleashing Leads And Conversions: </strong>
            </li>
            <p>
            Command attention, kindle curiosity, and steer viewers towards conversion with animated videos that resonate and compel action.
            </p>

            <li>
              <strong>Animated Customer Support:  </strong>
            </li>
            <p>
            Enlighten and aid your customers with immersive animated videos, unraveling intricate concepts with simplicity and engagement
            </p>

            <li>
              <strong>The Animated Sales Pitch</strong>
            </li>
            <p>
            Raise your sales presentation using enthralling visuals and narrative finesse that ensnare the interest of your potential clients.
            </p>
            <p className="text-justify">
              Discover the untapped potential of animated videos. Allow us to assist you in constraining the dynamic prowess of animation for your enterprise! 
              <br />
              Get in touch with us today and unveil the amazing opportunities waiting for your business to spark!
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Enhance Your Sales Funnel With Dynamic Video Solutions
          </h2>
          <br />
          <p className="text-justify">
          Rise your sales with our amazing video services that inform and generate conversions. Covert your viewers into loyal customers through our engaging visual storytelling;
          </p>
          <ul>
            <li>
              <strong>Spark Awareness And Ignite Interest: </strong>
            </li>
            <p>
            Command attention and generate excitement through amazing videos that introduce your brand to the global stage. Our videos are designed to leave a lasting impression and get people talking.
            </p>
            <li>
              <strong>Nurture Consideration And Fuel Engagement: </strong>
            </li>
            <p>
            Immerse your audience in informative and compelling videos that expertly showcase your products or services. These videos are tailored to keep viewers engaged, curious, and eager to learn more.
            </p>
            <li>
              <strong>Drive Conversions With Impactful Persuasion: </strong>
            </li>
            <p>
            Accelerate conversions and supercharge sales with persuasive videos that skillfully highlight the unmatched value of your offerings. Our videos are strategically crafted to encourage action and inspire confidence in your brand.
            </p>

            <li>
              <strong>Cultivate Reach And Foster Customer Loyalty:</strong>
            </li>
            <p>
            Cultivate lasting relationships and promote repeat business with interactive videos that consistently captivate your audience. These videos are designed to create a strong bond between your brand and customers, ensuring their return.
            <br />
            From the initial spark of awareness to the grand expansion, our unparalleled video solutions cater to each stage of the funnel.
            <br />
            Let us partner with you to leave an indelible mark at every pivotal step.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-seo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Mobilize The Potential Of Animated Explainer Videos
          </h2>
          <br />
          <p className="text-justify">
          Unlock the influence of animated explainer videos to capture audiences and convert complex ideas into visually appealing narratives that oscillate. Uplift your communication technique with our experienced team that crafts impactful and engaging animated explainer videos for you.
          </p>
          <ul>
            <li>
              <strong>Seconds Transformed Into Success Stories: </strong>
            </li>
            <p>
            Seamlessly capture attention and effectively deliver your core message within mere seconds. Our animated explainer videos make every moment count.
            </p>
            <li>
              <strong>Conversion Boosters Extraordinaire: </strong>
            </li>
            <p>
            Ignite action and witness remarkable conversion rate surges through the persuasive power of our engaging animated explainer videos. Compelling narratives are guaranteed.
            </p>
            <li>
              <strong>Lead Generation Enchantment: </strong>
            </li>
            <p>
            Ensnare the curiosity of potential clientele, skillfully converting them into invaluable leads through the mesmerizing allure of our animated explainer videos.
            </p>

            <li>
              <strong>Bounce Rate Battle Winners: </strong>
            </li>
            <p>
            Wage war against high bounce rates as our amazing and visually enticing animated explainer videos grip visitors, holding their attention and reducing bounce-offs significantly.
            </p>
            <li>
              <strong>Brand Awareness Magnification:</strong>
            </li>
            <p>
            Amplify brand recognition, etching an indelible imprint within the minds of viewers, all accomplished through the creation of unforgettable animated explainer videos.
            </p>
            <p className="text-justify">
            Set out on a Journey of Animated Explainer Video Discovery, Revolutionizing Your Company's Potential for Unparalleled Triumph! 
            <br />
            Connect with us now and initiate this transformative process!
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default ExplainerVideos