import React from 'react';
import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import "../App.css"
import {motion} from "framer-motion"
import Triangle from "../Asset/Animation/triangle.json"
import Loader from '../Components/Loader';

const Section1 = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
      gsap.to('#video', {
        scrollTrigger: {
          trigger: '#container',
          start: 'top center',
          end: 'center center',
          // markers: true,
          scrub: 2,
          pinSpacing: true,
        },
        width: '80vw',
      });
  }, []);

  return (
    <>
    <section id='section1' className='h-[950px]  w-full overflow-hidden relative'>


    {/* Triangle Animation */}
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{once:true}}
      transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
      
      className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{once:true}}
      // onAnimationStart={()=>alert("first uppper to animation style")}
      transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
      className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>



      {/* section 1 header What we do */}
        <motion.div 
        initial={{ opacity: 0,y:200 }}
        whileInView={{ opacity: 1,y:0}}
        viewport={{ once: true }}
        transition={{duration:2,}}
        
        className='flex gap-x-4 text-8xl font-bold justify-center mb-6 xsm:flex-col xsm:items-center mm:flex-row'>
          <h2
          className='xsm:text-5xl xsm:leading-[50px] mm:text-5xl mm:leading-5xl  lm:text-6xl  lg:text-7xl xl:text-8xl xl:leading-8xl font-heading '
          
          
          >WHAT WE</h2>
          <h2
          className='xsm:text-[50px] xsm:leading-[50px] mm:text-5xl mm:leading-5xl  lm:text-6xl  lg:text-7xl xl:text-8xl xl:leading-8xl'
          style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "2px",
                color:"white"
              }}>DO</h2>
        </motion.div>

      <div id="video_sec_scroll">
        <div id="container" className="section_video">
          <div className="video_component">
            <div className="video_card-container hidden xl:block is-smallest">
              <img
              title='Website'
                src={"https://res.cloudinary.com/dueulh8fr/image/upload/v1692726769/ncy%20Home%20Images/What%20We%20Do/1_kyz44d.webp"}
                loading="eager"
                width="600"
                height="400"
                alt="Project"
                className="header_preview-image"
              />
            </div>
            <div className="video_card-container hidden lg:block is-smaller">
              <img
              title='UI UX'
                src={"https://res.cloudinary.com/dueulh8fr/image/upload/v1692726769/ncy%20Home%20Images/What%20We%20Do/2_tclgai.webp"}
                loading="eager"
                width="800"
                height="400"
                alt="project"
                className="header_preview-image"
              />
            </div>
            <div
              id="video"
              data-w-id="6921ce9f-ee5b-b4d8-9eaf-cd79acc5da26"
              className="video_card-container is-middle"
            >
              <div className="div-block-11">
                <div className="showreel w-embed">
                  <div className="styleIt">
                    <lite-vimeo
                      videoid="827169514"
                      autoload=""
                  
                      // autoplay={true}
                      id="v1"
                      width="auto"
                      height="auto"
                    ></lite-vimeo>
                  </div>
                </div>
              </div>
              <div
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="background-video w-background-video w-background-video-atom"
              >
                <video
                  id="cfb37b17-22d1-08c2-0403-ba82b45bc384-video"
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                >
                
                  <source src={"https://res.cloudinary.com/dueulh8fr/video/upload/v1692726771/ncy%20Home%20Images/What%20We%20Do/video1_os3g2t.mp4"} data-wf-ignore="true"   type='video/mp4' />
                </video>
              </div>
              <div className="showreel-video">
                <div className="play-button-circle-2">
                  <img
                  title='stop'
                    src={"https://res.cloudinary.com/dueulh8fr/image/upload/v1692726770/ncy%20Home%20Images/What%20We%20Do/play_am92sa.svg"}
                    loading="lazy"
                    width="40"
                    height="40"
                    alt="play button"
                    className="play-button"
                  />
                </div>
              </div>
            </div>
            <div className="video_card-container hidden lg:block is-smaller">
              <img
              title='animation'
                src={"https://res.cloudinary.com/dueulh8fr/image/upload/v1692726769/ncy%20Home%20Images/What%20We%20Do/3_uwsq1y.webp"}
                loading="eager"
                
                width="800"
                height="600"
                alt="project"
                className="header_preview-image"
              />
            </div>
            <div className="video_card-container hidden xl:block is-smallest">
              <img
              title='Modern Desig'
                src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726770/ncy%20Home%20Images/What%20We%20Do/4_rdlcwj.webp"
                loading="eager"
                width="800"
                height="600"
                alt="project"
                className="header_preview-image"
              />
            </div>
          </div>
        </div>
      </div>
</section>

    </>
  );
};

export default Section1;