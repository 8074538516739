import React from 'react'
import {motion}  from "framer-motion"

// import Mobile1 from "../Asset/Images/section2/AI Mobile App Development.webp"
// import Mobile2 from "../Asset/Images/section2/mobile App development.webp"
// import Mobile3 from "../Asset/Images/section2/Application Development.webp"
// import Mobile4 from "../Asset/Images/section2/App Desinging.webp"
// import Mobile5 from "../Asset/Images/section2/Custom App development.webp"
// import Tab1 from "../Asset/Images/section2/crm.webp"
// import Laptop2 from "../Asset/Images/section2/webdevelopment.webp"
// import Tab2 from "../Asset/Images/section2/desktop Application Development.webp"

import CustomButton from '../Components/CustomButton'
import { useNavigate } from 'react-router-dom'

const ServicesSection = () => {
  const navigate = useNavigate()

  const seeMorehandle = ()=>{
    navigate("/services")

  }


  return (
    <div className='h-[50vh] sm:h-[80vh] lg:h-screen relative overflow-hidden  flex justify-center items-center' >

        <div className=' w-full flex flex-col justify-center items-center    text-3xl  lm:text-4xl sm:text-4xl   md:text-5xl  lg:text-6xl xl:text-7xl  font-heading font-semibold '>
          <motion.h2 
          initial={{opacity:0,y:100}}
          whileInView={{opacity:1,y:0}}
          viewport={{once:true}}
          transition={{duration:1.5}}
          
          
          >WE HAVE MULTIPLE</motion.h2>
          <motion.h2 
           style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
                color:"white",
                paddingBottom:"10px"
              }} 
              
              initial={{opacity:0,x:-100}}
              whileInView={{opacity:1,x:0}}
              viewport={{once:true}}
              transition={{duration:1.5}}

              
              >SERVICES</motion.h2>
              <motion.div 
              initial={{opacity:0,y:100}}
              whileInView={{opacity:1,y:0}}
              viewport={{once:true}}
              transition={{duration:1.5}}
              >
 
              <CustomButton title={"See More"} padding={true}  onClick={seeMorehandle}   />
              </motion.div>

        </div>


        <motion.img width={"100%"}
          height={"100%"}
        title='Web Development'
        loading='eager'
            initial={{opacity:0,y:200}}
            whileInView={{y:0,opacity:1}}
            transition={{duration:2}}
            viewport={{once:true}}
              className="absolute h-[29.72%] w-[31.09%] top-[0%] left-[3.28%] max-w-full overflow-hidden max-h-full object-contain"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/webdevelopment_icxiu9.webp"
              alt="Web Development"
            />

          <motion.img width={"100%"}
          height={"100%"}
          title='CRM Software Development'
          loading='eager'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}
              className=" absolute h-[34.19%] w-[15%] top-[0%] right-[2.76%]  max-w-full overflow-hidden object-contain"
              alt="Crm Software Development"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/crm_irgnmj.webp"
            />

          <motion.img width={"100%"}
          height={"100%"}
          title='Custom App Development'
          loading='eager'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}

              className="absolute h-[27.61%] w-[9.39%] top-[5.56%] right-[20.61%]   max-w-full overflow-hidden max-h-full object-contain "
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/Custom_App_development_hrvydy.webp"
              alt="Custom App Development"
            />


<motion.img width={"100%"}
          height={"100%"}
title='Application Development'
loading='eager'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}
              className="absolute h-[34.54%] w-[11.39%]  right-[15.65%] -bottom-[6%]  max-w-full max-h-full  object-contain"
              alt="App Designing"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/App_Desinging_lm9lop.webp"
            />
            <motion.img width={"100%"}
          height={"100%"}
            loading='eager'
            title='Application Development'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}
              className="absolute h-[34.54%] w-[11.39%]  right-[3.25%] -bottom-[6%] max-w-full  max-h-full object-contain "
              alt="Application Development"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/Application_Development_fmelis.webp"
            />



<motion.img width={"100%"}
          height={"100%"}
title='Desktop Application Development'
loading='eager'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}
              className="absolute h-[24.09%] w-[19.95%] top-[39.45%]  left-[0.76%] max-w-full overflow-hidden max-h-full object-contain "
              alt="Desktop Application Development"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/desktop_Application_Development_u3f59z.webp"
            />
            <motion.img width={"100%"}
          height={"100%"}
            title='Mobile App Development'
            loading='eager'
               initial={{opacity:0,y:200}}
               whileInView={{y:0,opacity:1}}
            viewport={{once:true}}

               transition={{duration:2}}
              className="absolute h-[48.54%] w-[12.39%]   bottom-[-17.81%] left-[4.27%] max-w-full overflow-hidden max-h-full  object-contain"
              alt="Mobile App Development"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/mobile_App_development_sr0fgv.webp"
            />
            <motion.img width={"100%"}
          height={"100%"}
            title='AI Mobile App Development'
            loading='eager'
             initial={{opacity:0,y:200}}
             whileInView={{y:0,opacity:1}}
          viewport={{once:true}}

             transition={{duration:2}}
              className="absolute h-[48.54%] w-[12.39%] bottom-[-17.81%] left-[17.91%] max-w-full overflow-hidden max-h-full object-contain "
              alt="AI Mobile App Development"
              src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726720/ncy%20Home%20Images/Services%20Section/AI_Mobile_App_Development_rqqlgw.webp"
            />
        

    </div>


  );
};

export default ServicesSection;
