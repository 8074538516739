import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function ThreeDModeling() {
    const data ={
        title: `3D\n Modeling`,
        description:"Learn how 3D modeling and visualization can be used to enthrall your audience, present your thoughts, and give your notions life. Our talented group of designers and artists specializes in offering premium 3D modeling services customized to meet your particular requirements.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712601/lotties%20Json%20Files/threedmodeling_vxro8j.json",
        head:{
          title:"3d Modeling | NYC Pro Web Designers | NY 10036",
          description:" NYC's web design experts specialize in dynamic 3D modeling for immersive digital experiences.",
          canonical:`<link href="https://nycprowebdesigners.com/3d-modeling" rel="canonical">`
        } 
    }
    const faqs = [
      {
        question: "1. Why does 3D Modeling hold significance?",
        answer: `<p>To discover the transformative potential of 3D modeling, whether it's for architecture, product design, entertainment, or virtual reality, 3D modeling breathes life into concepts through stunning visualizations. It enhances communication, facilitates realistic simulations, and enables precise prototyping.</p>`,
      },
      {
        question: "2. Is 3D Modeling More Challenging Than Drawing?",
        answer: `<p>3D modeling entails mastering software tools and grasping spatial relationships, presenting a unique challenge. Yet, with dedication and practice, anyone can embark on the creative journey of 3D modeling. </p>`,
      },
      {
        question: "3. What Determines the Cost of 3D Modeling Design?",
        answer:
          "<p>The expense of 3D modeling design varies depending on factors like project complexity, scope, and the desired level of intricacy. It's advisable to engage in a tailored discussion with a professional service provider like us to receive a customized quotation. </p>",
      },
      {
        question: "4. Who Can Benefit from 3D Modeling Services?",
        answer:
          "<p>Diverse industries reap the rewards of 3D modeling services, encompassing architecture, product design, gaming, advertising, film production, and more. Whether you're an individual, a small enterprise, or a large corporation, if you seek visually attractive and immersive experiences, 3D modeling services cater to your needs. </p>",
      },
      {
        question: "5. What are the techniques Employed for Crafting 3D Models?",
        answer:
          "<p>Our team leverages an impressive range of state-of-the-art approaches to bring your creative concepts to vibrant reality. From employing polygonal modeling and sculpting to enhancing with textures and delivering stunning renders, we wholeheartedly embrace the most widely accepted practices in the field. With our deep expertise, we aim to craft intricately detailed, astonishingly lifelike, and visually amazing 3D models. </p>",
      },
    ];

  return (
    <ServicesPage data={data} >
          <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Step Into The World Of 3D Modeling And Visualization
          </h2>
          <br />
          <p className="text-justify text-lg">
          Our specialized 3D modeling services will make you stand out from the competition whether you're an architect, product designer, or creative visionary. We'll accurately and imaginatively realize your concept, from complex architectural designs to lifelike product visualizations.
            <br />
            Your clients will be taken into a virtual world by our architectural 3D modeling services so they can view your concepts from every perspective. We'll produce immersive visualizations that leave an impression by paying close attention to every tiny detail.
            <br />
            Looking to present your goods in a brand-new light? 
            <br />
            Your designs will come to life thanks to our 3D product modeling services, allowing you to showcase your goods most compellingly and realistically possible.
            <br />
            Watch your concepts come to life in front of you. Our team will produce engaging visual experiences that inspire and engage, from beautiful animations to lifelike models.
            <br />
            To begin your path of creativity, innovation, and visual perfection, get in touch with us right away!
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Services We Provide:
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Incredible 3D Modelling Services To Enter The Metaverse 
              </strong>
            </li>
            <p>
            Make use of our state-of-the-art 3D modeling services to enter the metaverse's immersive universe. We'll assist you in realizing your metaverse goals, from building virtual worlds to developing amazing avatars.
            </p>
            <li>
              <strong>3D Character Modelling</strong>
            </li>
            <p>
            Whether it's for movies, games, or cartoons, our talented artists will give your characters life and make them genuinely memorable.
            </p>

            <li>
              <strong>3d Games Modelling</strong>
            </li>
            <p>
            Our team works with you to create a universe that players won't want to leave, whether that means creating realistic game assets or creating alluring settings.
            </p>

            <li>
              <strong>3D Environment Modelling</strong>
            </li>
            <p>
            We will design breathtaking worlds that enthrall and inspire, whether they are for architectural visualizations or virtual reality experiences.
            </p>


            <li>
              <strong>AV/VR Modelling To Experience The Future</strong>
            </li>
            <p>
            We'll construct immersive digital worlds that blend the boundaries between reality and imagination, from virtual reality simulations to augmented reality experiences.
            </p>

            <li>
              <strong>Digital Twin Modelling</strong>
            </li>
            <p>
            We'll produce precise and thorough digital reproductions that revolutionize the way you assess and maximize your assets, from industrial simulations to smart city planning.
            </p>
            <li>
              <strong>3D Product Modelling </strong>
            </li>
            <p>
            We'll work with you to capture your audience and increase sales using everything from interactive visualizations to realistic product drawings.
            <br />
            Contact us today to embark on a journey of limitless possibilities!
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Benefits Of Outsourcing 3D Modeling Design
          </h2>
          <br />
          <p className="text-justify">
          Discover the Skills of Specialists: Tailor-Made Solutions for Your Unique Needs;
          </p>
          <ul>
            <li>
              <strong>
              Expertise At Your Fingertips: 
              </strong>
            </li>
            <p>
            When you choose to delegate your 3D modeling design tasks, you tap into a pool of talented professionals with specific know-how. Our team of skilled artists and designers is well-versed in the latest techniques and trends, ensuring that your project is in capable hands. Enjoy the advantage of specialized skills!
            </p>
            <li>
              <strong>Total Adaptability:</strong>
            </li>
            <p>
            Outsourcing grants you the freedom to adjust your 3D modeling design resources as per your project's demands. Whether you need extra manpower for a sizable project or specialized know-how for a particular task, outsourcing empowers you to be agile and attain your objectives. 
            <br />
            Embrace the flexibility of complete adaptability!
            </p>

            <li>
              <strong>Flawless Excellence:</strong>
            </li>
            <p>
            When you entrust your 3D modeling design to us, you can anticipate nothing short of impeccable quality. Our team is dedicated to delivering exceptional results that not only meet but surpass your expectations. From intricate details to lifelike textures, we make sure every aspect of your project is meticulously crafted. Savor the satisfaction of flawless excellence!
            </p>

            <li>
              <strong>Premium Treatment: </strong>
            </li>
            <p>
            Outsourcing your 3D modeling design opens the door to premium services that enhance your project to new levels. From personalized consultations to dedicated project managers, we go the extra mile to offer you a smooth and delightful experience. Discover the distinction of premium services!
            <br />
            By opting for outsourcing for your 3D modeling design needs, you unlock a sphere of relevant expertise, complete adaptability, flawless excellence, and premium treatment. 
            <br />
            Embrace the pivotal role of 3D modeling in shaping our future!
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Choose Us For 3D Modeling And Experience The Difference
          </h2>
          <br />
          <p className="text-justify">
          Step into a domain of boundless creativity through our unparalleled 3D experience;
          </p>
          <ul>
            <li>
              <strong>Our Exceptional 3d Experience</strong>
            </li>
            <p>
            Our team of skilled artists and designers are true masters of their art, crafting breathtakingly lifelike 3D models that will breathe life into your unique vision. Brace yourself for astonishment!
            </p>
            <li>
              <strong>Your Requirements Are Our Top Priority </strong>
            </li>
            <p>
            We firmly believe in placing our client's needs at the forefront of all our endeavors. We invest time and effort in comprehending your distinctive demands, ensuring that every facet of your 3D modeling project is customized to align perfectly with your vision. Your contentment is our ultimate objective.
            </p>
            <li>
              <strong>All The Experts You Require Under One Roof</strong>
            </li>
            <p>
            Bid farewell to the complexity of managing multiple specialists for your 3D modeling project. Our team comprises skilled professionals with expertise spanning various domains, ranging from character modeling to environment design. With us, you'll have access to all the experts you need, streamlining the entire process for maximum efficiency.
            <br />
            By selecting us, you're choosing a partner wholly devoted to delivering exceptional outcomes, giving precedence to your requirements, and affording you a team of specialists. Allow us to breathe life into your 3D modeling endeavors, surpassing your expectations. 
            <br />
            Reach out to us today to commence your journey!
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default ThreeDModeling