import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function VideoEditing() {
    const data ={
        title: `Video \n Editing`,
        description:"Are you weary of commonplace video edits? The squad at The Authentic Edit Solution is primed to skyrocket your videos to unparalleled levels. Through our boundless video editing service, the horizons stretch endlessly. We'll forge a close partnership with you to breathe life into your vision, ensuring each frame resonates flawlessly. Collaborate seamlessly with our committed crew and delve into boundless revisions to attain flawlessness.",
        upperStyle:true,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712602/lotties%20Json%20Files/VideoEditing_tfffcs.json",
        head:{
          title:"Video Editing | NYC Pro Web Designers | NY 10036",
          description:"Elevate your video quality with NYC's leading web designers specializing in video editing",
          canonical:`<link href="https://nycprowebdesigners.com/video-editing" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. How do professional services for enhancing videos contribute to improving their quality?",
        answer: `<p>The expertise of professional video enhancement services brings improvements to visual appeal, adjustments in color and lighting, heightened audio quality, incorporation of special effects, and crafting a seamless narrative flow for your videos</p>`,
      },
      {
        question: "2. Which software is commonly employed by video enhancement services?",
        answer: `<p>Prominent software choices for video enhancement services encompass Adobe Premiere Pro, Final Cut Pro, and DaVinci Resolve. These tools provide an array of capabilities for editing, effects, and rectifying colors.</p>`,
      },
      {
        question: "3. Is it feasible to furnish specific directives and preferences for video enhancement services?",
        answer:
          "<p>Possible! Video enhancement services highly value your insights and collaborate with you closely to integrate particular directives, preferences, and desired styles into the final rendition of your video.</p>",
      },
      {
        question: "4. What's the typical timeframe for video enhancement procedures?",
        answer:
          "<p>The duration needed for video enhancement varies, contingent upon project intricacy and specific needs. Factors like footage length, desired modifications, and supplementary effects play into the overall schedule.</p>",
      },
      {
        question: "5. Can video enhancement services aid in incorporating music or voiceovers into videos?",
        answer:
          "<p>Certainly, video enhancement services excel in seamlessly integrating music or voiceovers into your videos. They assist in selecting fitting audio tracks, synchronizing them with visuals, and ensuring a harmonious amalgamation that amplifies the video's overall impact.</p>",
      },
    ];
  
  return (
    <ServicesPage data={data}>
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Expert Video Editing Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Enhancing your videos to transform them into awe-inspiring works of art is made effortless with professional video enhancement services. These specialized solutions are meticulously crafted to offer you the skills and resources required for converting your unrefined footage into a refined and enthralling end product that's bound to leave a mark. 
            <br />
            Why settle for ordinary videos when you can have content of professional caliber that genuinely distinguishes itself? 
            <br />
            Choose to invest in professional video enhancement services today and experience the transformation firsthand.
          </p>
      
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Boost Your Video Editing Experience With Our Comprehensive Packages
          </h2>
         
          <ul>
            <li>
              <strong>Diverse Footage Collection: </strong>
            </li>
            <p className="text-justify text-lg">
            Accelerate your video content using our expansive library of premium stock footage. Pick from a broad array of genres and themes, finding the perfect clips to seamlessly blend with your content.
            </p>

            <li>
              <strong>
              Precise Caption Proofreading: 
              </strong>
            </li>

            <p className="text-justify text-lg">
            Our adept team meticulously reviews and polishes your captions, ensuring they're flawless and enrich the viewer's journey.
            </p>
            <li>
              <strong>
              Tailored Design Templates: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Propel your videos to new heights with our expertly crafted templates. Select from an array of styles and layouts, all customizable to align with your brand and vision, setting your videos apart effortlessly.
            </p>

            <li>
              <strong>
              Assets Per Order: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our video editing packages accommodate up to 9 assets per order. Whether it's imagery, audio, or footage, we flawlessly weave them into your videos, forging a coherent and captivating outcome.
            </p>

            <li>
              <strong>
              Effortless Instant Submission: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Embrace the ease of instant submission. Once content with your edits, a simple click of "submit" sets everything in motion. No delays, no complications.
            </p>

            <li>
              <strong>
              Limitless Brand Accounts:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Effortlessly oversee multiple brands or clients with our unrestricted brand accounts feature. Seamlessly switch between accounts, keeping all your projects impeccably organized within a single hub.
            </p>

            <li>
              <strong>
              Supportive Customer Assistance: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            From queries to guidance, we're committed to offering approachable and dependable assistance, ensuring your editing journey is smooth sailing and enjoyable.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Delegate Your Assets To Us:
          </h2>
          <br />
          <p className="text-justify">Wave goodbye to the complexities of managing your video editing. Entrust our seasoned team to handle it all on your behalf. Just share your raw footage, and watch as our expertise unfolds its magic.
          </p>
          <ul>
            <li>
              <strong>
              Initial Drafting:
              </strong>
            </li>
            <p>
            Recognizing the need for swiftness in video editing, we commit to presenting your initial draft within a mere 1-2 business days. Experience a quick glimpse of your edited video without delay!
            </p>
            <li>
              <strong>Achieve Perfection With Endless Revisions:</strong>
            </li>
            <p>
            Your contentment holds paramount importance to us. Embracing limitless revisions, we ensure your video aligns precisely with your creative vision. Polish every facet until it radiates perfection. Your imagination meets our proficiency, unlocking boundless potential.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Mastery In Every Edit:
          </h2>
          <br />
          <p className="text-justify">
          Our team of experienced editors brings a wealth of knowledge to each project, ensuring your videos attain polished excellence. Count on us to handle all facets of the editing process with utmost precision and proficiency.
          </p>
          <ul>
            <li>
              <strong>Innovative Idea Repository:</strong>
            </li>
            <p>
            Seeking fresh, inventive concepts to set your videos apart? Your search ends here. Our expansive idea repository is a vault of inspiration, aiding you in materializing distinctive and enthralling ideas.

            </p>
            <li>
              <strong>Customized For Trends:</strong>
            </li>

            <p>
            Remain updated with the latest trends through our customized editing services. We grasp the significance of keeping your videos pertinent and compelling, and our team consistently stays abreast of the industry's hottest trends.
            </p>
            <li>
              <strong>Effortless Ordering Platform:</strong>
            </li>
            <p>
            Bid farewell to convoluted and time-intensive ordering procedures. Our streamlined platform simplifies your video editing requests, guaranteeing an effortless and efficient journey from inception to completion.
            </p>

            <li>
              <strong>Transparent Pricing:</strong>
            </li>
            <p>
            Transparency is our cornerstone in matters of pricing. Rest assured, there are no concealed fees or surprises with us. Our pricing structure is clear-cut and easy to comprehend, ensuring absolute lucidity.
            </p>

            <li>
              <strong>Outcome-Focused Methodology:</strong>
            </li>
            <p>
            At the core of our editing process lies an outcome-focused methodology. We are resolute in furnishing videos that not only fulfill but surpass your expectations. We aim to assist you in realizing your vision and crafting impactful videos that etch an enduring imprint.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Flare Up Your Business With Captivating Video Content
          </h2>
          <p className="text-justify">
          Our skilled editors will metamorphose your raw footage into refined masterpieces, ensuring your message shines through brilliantly.
          </p>
          <ul>
            <li>
              <strong>Infinite Potential: </strong>
            </li>
            <p>
            We believe in granting you full authority over your video edits. With boundless editing and revisions, we'll collaborate closely with you to breathe life into your vision and ensure every detail is impeccably executed.

            </p>
            <li>
              <strong>Swift Delivery, Uncompromising Quality: </strong>
            </li>

            <p>
            We grasp the significance of timely video delivery without compromising quality. Our team is unwaveringly committed to swift turnaround times while upholding the utmost craftsmanship.
            </p>
            <li>
              <strong>Uncover Strategic Insights: </strong>
            </li>
            <p>
            Our seasoned editors offer not only technical prowess but also strategic insights to propel your videos to new heights. We'll join forces with you to ensure your message resonates with your audience and accomplishes your objectives.
            </p>

            <li>
              <strong>Your Personalized Editing Dream Team: </strong>
            </li>
            <p>
            By choosing us, you gain a devoted cadre of editors who are fervent about translating your vision into reality. We'll go the extra mile to ensure your videos transcend your expectations and facilitate the realization of your desired outcomes.
            </p>

            <li>
              <strong>Access a Wealth of Knowledge: </strong>
            </li>
            <p>
            Complimentary Resources to Amplify Your Video Editing Skills. We believe in empowering our clients with knowledge. Hence, we provide gratis resources, tips, and tutorials to assist you in honing your video editing skills and crafting even more impactful content.
            </p>
            <li>
              <strong>Primed to Conquer Social Media:  </strong>
            </li>
            <p>
            Videos Optimized and Tailored for Optimal Impact. In today's digital era, social media wields immense influence in reaching your audience. Our videos are meticulously optimized and tailored explicitly for social media, guaranteeing maximal impact and resonance with your target audience.
            </p>
          </ul>
     
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default VideoEditing