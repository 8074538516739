import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function DomainHosting() {
    const data ={
        title: `Domain Hosting`,
        description:"Do you need a dependable and secure hosting solution for your website? Look nowhere else! Our name hosting services provide a seamless user experience, making sure that users can always access your website. We offer everything you need to advance your online presence, including the best security measures, lightning-fast loading times, and round-the-clock technical assistance. Choose our domain hosting services to maximize the potential of your website and avoid being held back by a poor hosting provider.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712596/lotties%20Json%20Files/Domian_hosting_wrhygp.json",
        head:{
          title:"Domain Hosting Services | NYC Pro Web Designers | NY 10036",
          description:"Secure your online presence with our Domain Hosting Services. We provide the space your website needs to thrive",
          canonical:`<link href="https://nycprowebdesigners.com/domain-hosting-services" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What is hosting for domains?",
        answer: `<p>The service that enables you to store the files for your website and make it reachable via the internet using your distinctive domain name is known as domain hosting.</p>`,
      },
      {
        question: "2. How do I pick the best domain hosting company?",
        answer: `<p>Consider elements like dependability, security precautions, customer service, scalability possibilities, and price plans that suit your demands and budget when selecting a domain hosting provider.</p>`,
      },
      {
        question: "3. Can I move my current domain to a different hosting company?",
        answer:
          "<p>Yes, the majority of domain hosting companies let you move an existing domain to their system. They'll help you through it and make the transfer go smoothly.</p>",
      },
      {
        question: "4. What happens if a lot of people visit my website?",
        answer:
          "<p>You have the choice to increase your hosting plan with domain hosting if you anticipate considerable traffic. This guarantees that even during busy periods, your website is accessible and operates efficiently.</p>",
      },
      {
        question: "5. Is hosting for domains secure?",
        answer:
          "<p>To safeguard your website and data from dangers, reputable domain hosting providers use strong security measures including firewalls, SSL certificates, and routine backups. Selecting a service that places a high priority on security is crucial.</p>",
      },
    ];
  return (
    <ServicesPage data={data} ><div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
    >
      <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
      Let Our Domain Hosting Services Uncover Your Website's Potential!
      </h2>
      <br />
      <p className="text-justify text-lg">
      Are you prepared to advance your website? Our top web hosting services are created to give outstanding performance and dependability, guaranteeing that your website is constantly accessible and operating without a hitch. We streamline the creation and management of your online presence for you with cutting-edge features like limitless bandwidth, SSD storage, and one-click installations. 
      </p>
      <ul>
        <li>
          <strong>
          With DNH Domain Hosting Services, you can succeed online.
          </strong>
        </li>
        <p>
        We recognize the value of having a strong online presence, and our hosting solutions are made to match your specific requirements. We make it simple for you to administer your domain and website with lightning-fast servers, strong security measures, and an intuitive control panel. Our dependable hosting services will be your springboard to internet success whether you're a small business owner or an aspiring blogger. Choose DNH Domain Hosting Services right away to take the first step.
        </p>
        <li>
          <strong>Join the Ranks of the Biggest Web Hosting Companies: </strong>
        </li>
        <p>
        Size matters when it comes to web hosting. Obtain the advantages of unrivaled scalability, dependability, and resources by joining the ranks of the biggest web hosting providers. Our team of hosting services has been optimized to support sites of all dimensions, ranging from tiny blogs to heavily used e-commerce sites. With the help of our cutting-edge data centers, blazing-fast servers, and round-the-clock monitoring, we make sure that your website is always operational. Never compromise for anything less than the finest - choose our web hosting services and take advantage of the power associated with being part of one of the biggest web hosting companies!
        </p>
      </ul>
    </motion.div>
    <br />

    <motion.div
      initial={{ opacity: 0, x: "100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
    >
      <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
      The Features Of Domain Hosting:
      </h2>
      <br />
     
      <ul>
        <li>
          <strong>
          Securing Website Accessibility and Safety:
          </strong>
        </li>
        <p>
        Protecting your website is a major priority in today's digital world. Our hosting services provide a solid and reliable infrastructure that can handle all of your website's requirements. You can count on our hosting to handle high traffic without compromising on performance or security. With us, your website stays online, safe from potential threats, and available to your audience. 
        </p>
        <li>
          <strong>Personalized Email Addresses: </strong>
        </li>
        <p>
        Maintaining professionalism in your business communication is crucial. We recommend creating custom email addresses that match your domain name. This not only builds trust with clients, partners, and stakeholders but also ensures your messages reach the right inboxes and avoid spam filters. 
        </p>

        <li>
          <strong>Easy-to-Use Control Panel: </strong>
        </li>
        <p>
        Our control panel makes it simple to manage your website's settings, email accounts, and domains. Everyone can use it easily, even those with little technological expertise. You may modify website design and content, manage email accounts, and modify domain settings with only a few clicks. 
        </p>

        <li>
          <strong>Round-the-clock tech assistance: </strong>
        </li>
        <p>
        Any issues you have when hosting a domain can be resolved by contacting our support team at any time. To assist you with any challenges you may encounter, our passionate experts are readily available to offer their assistance. 
        </p>
      </ul>
    </motion.div>

    <br />
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
    >
      <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
      Why Choose Us as Your Domain Hosting Provider?
      </h2>
      <br />
      <ul>
        <li>
          <strong>
          User-Friendly Dashboard:
          </strong>
        </li>
        <p>
        Our easy-to-use control panel simplifies domain management, email setup, and website handling. You don't need tech expertise – it's designed for everyone.
        </p>
        <li>
          <strong>Affordable Pricing:</strong>
        </li>
        <p>
        Our hosting plans are cost-effective, ensuring quality service without straining your budget. We cater to various financial needs, ensuring you get great value.
        </p>

        <li>
          <strong>Instant Site Setup:</strong>
        </li>
        <p>
        Save time and hassle with our one-click installations for popular systems like WordPress, Magento, and Shopify. Your website can be live in no time, effortlessly.
        </p>

        <li>
          <strong>Reliable Data Backups:</strong>
        </li>
        <p>
        Data safety matters. With our regular backup service, your website data is secure, and ready for swift restoration when necessary.
        </p>

        <li>
          <strong>Smooth Integrations:</strong>
        </li>
        <p>
        If you use other tools or software for your business, our hosting seamlessly connects with various applications, streamlining your workflow and boosting productivity.
        <br />
        These are just a few reasons why we excel as your domain hosting partner. Our focus is on user satisfaction, affordability, ease, and dependable services.
        </p>
      </ul>
    </motion.div>
    <br />
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1.5 }}
      className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
    >
      <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
      Advantages of Domain Hosting:
      </h2>
      <br />
      <ul>
        <li>
          <strong>Improved Website Performance:</strong>
        </li>
        <p>
        Through domain hosting, your website enjoys dedicated server resources, resulting in faster loading times and an overall better user experience. This also boosts your search engine rankings.
        </p>
        <li>
          <strong>Personalized Email Addresses:</strong>
        </li>
        <p>
        Domain hosting allows you to craft custom email addresses using your unique domain name. This adds professionalism to your communication and bolsters your brand image.
        </p>
        <li>
          <strong>Enhanced Security:</strong>
        </li>
        <p>
        By selecting a dependable domain hosting provider, you gain access to strong security measures, including firewalls, malware scans, SSL certificates, and regular backups. 
        </p>

        <li>
          <strong>Scalability and Flexibility:</strong>
        </li>
        <p>
        Domain hosting offers scalability, enabling you to effortlessly upgrade your hosting plan as your website expands. Whether dealing with sudden traffic surges or requiring more resources, you can quickly adapt to meet your evolving needs.
        </p>

        <li>
          <strong>Expert Technical Support:</strong>
        </li>
        <p>
        Whether you need assistance with server configurations, website setup, or troubleshooting, their expert team is at your service.

        </p>
        <li>
          <strong>Search Engine Optimization (SEO) Benefits:</strong>
        </li>
        <p>
        You can implement SEO strategies, such as creating keyword-rich URLs and optimizing page load times, to enhance your website's visibility and attract more organic traffic.
        </p>
        <li>
          <strong>Reliable Uptime:</strong>
        </li>
        <p>
        A reliable domain hosting provider ensures maximum uptime for your website, making it accessible to visitors around the clock. This minimizes downtime and potential revenue loss.
        </p>
        <li>
          <strong>Seamless Integration with Other Services:</strong>
        </li>
        <p>
        Domain hosting seamlessly integrates with other services like email marketing platforms, e-commerce solutions, and content management systems. 
        </p>
        <li>
          <strong>Cost-Effective alternative: </strong>
        </li>
        <p>
        Whichever best suits your needs and budget, you may select from a variety of hosting plans to make sure you get the greatest return on what you spend.
        </p>
      </ul>
    </motion.div>


    <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
      <div className=" sm:w-[60%] h-full  ">
        <Accordion faqs={faqs} />

        {/* <Accordion /> */}
      </div>
      <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
        <motion.h2
          initial={{ x: "100%" }}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 2, type: "spring" }}
          className="first-letter:text-6xl first-letter:text-[#E62B4A]"
        >
          Frequently
        </motion.h2>
        <motion.h2
          initial={{ x: "100%" }}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 2, delay: 0.2 }}
          className="first-letter:text-6xl first-letter:text-[#E62B4A]"
        >
          Asked
        </motion.h2>
        <motion.h2
          initial={{ x: "100%" }}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 2, type: "spring" }}
          className="first-letter:text-6xl first-letter:text-[#E62B4A]"
        >
          Questions
        </motion.h2>
      </div>
    </div>
  </div></ServicesPage>
  )
}

export default DomainHosting