import "./App.css";
import Router from "./Config/Router";
// tawk to chat bot
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  return (
    <>
      <Router />
      <TawkMessengerReact
        propertyId="64d114e594cf5d49dc68fab7"
        widgetId="1h78bj8o3"
      />
    </>
  );
}
export default App;
