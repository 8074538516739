import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";

function Seo() {
  const data ={
    title: `Search Engine Optimization`,
    description:"Want to get tons of online traffic to your website? You're in luck though since we're kind of like the internet's superheroes!  As the top SEO agency, we're here to assist your website in getting a ton of traffic. Consider how your website would fare as a store: the more customers you get, the better. We act like the magic that makes it easier for customers to find your store online. When individuals conduct online searches, we'll make your website appear. You should contact us right away if you desire your online presence to be renowned and have plenty of visits. We'll put together our spell to turn your website become the internet's talk of the town.",
    upperStyle:false,
    font:true,
    api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712600/lotties%20Json%20Files/Seo_tnaqwm.json",
    head:{
      title:" SEO Services | NYC Pro Web Designers | NY 10036",
      description:"NYC Pro Web Designers in NY 10036 is your trusted partner for SEO Services. Let us optimize your digital success",
      canonical:`<link href="https://nycprowebdesigners.com/seo-services" rel="canonical">`
    }
    
}

const faqs =[
  {
      question: '1. What Are SEO Marketing Services?',
      answer:`<p>It involves;
      <ul>
          <li>
          Optimizing strategies
          </li>
          <li>
          Keyword research
          </li>
          <li>
          Analysis
          </li>
          <li>
          Quality Content
          </li>
          <li>
          Link Building
          </li>
        </ul>
      </p>`
  },
  {
      question:"2. How Does Local SEO Differ from Regular SEO?",
      answer:`<p>
      It targets a geographically specified audience to optimize your web for local search engines.

      <p>
      `
  },
  {
    question: '3. Why is a Website Audit Important for SEO?',
    answer:`<p>It serves as a diagnostic tool for the website that uncovers areas of improvement i.e.;
    <ul>
    <li>
    Identifying issues.
    </li>
    <li>
    Content quality.
    </li>
    <li>
    Keyword Optimization.
    </li>
    <li>
    User Experience.
    </li>
    <li>
    Backlink Analysis.
    </li>
  </ul>
  <p>
  Resolving these issues enhances your site’s visibility and optimization.
  </p>
    
    </p>`
},
{
  question: '4. What Sets Apart a Customized SEO Strategy?',
  answer:`<p>This strategy maximizes the traffic on your web, increases your visibility, and aligns with your goals and the specific niche in which you want to excel. 
  </p>`
},
{
question: `5. How Does Ongoing Monitoring Benefit SEO?`,
answer:`<p>This allows the continuous check if your website’s keywords are ranking and the engagement is up to the mark or not. This also helps to update information regarding the changing algorithms of the search engines and helps you optimize your web to the fullest. </p>`
}

]

  return (
    <ServicesPage data={data}>
     <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Up Raise Your Online Visibility With Our Expert SEO Services:
          </h2>
          <br />
          <p className="text-justify text-lg">
          Think of us as the specialists who guide you in finding the hidden gem on your website. To guarantee that your website appears when users conduct online searches, we employ specialized techniques.
            <br />
            For your website, our executive SEO services act as a superhero squad. They support the growth of your website and attract thousands of customers who are interested in what you have to offer.
            <br />
            Imagine doing this to grow your web presence to the size of a mountain! We're here to raise the profile of your website and get more people to notice it.
            <br />
            Consequently, to put it simply, we act as internet wizards that utilize their magic to make your website fantastic and well-known.
          </p>

          <ul>
            <li>
              <strong>Our Proficient SEO Marketing Services</strong>
            </li>
            <p className="text-justify text-lg">
            By firing at relevant keywords, enhancing content, and executing strategic link-building, our team drives organic audiences that permute. We make sure to bring your brand prominently in search results. 
            </p>

            <li>
              <strong>Attain Growth With </strong>
            </li>

            <p className="text-justify text-lg">
            Increasing your digital growth should not be like hitting the jackpot. Our remarkable SEO marketing services offer a worthwhile pathway to enrich your appearance and bring a targeted audience to your brand. 
            </p>
            <li>
              <strong>Amplify Domestic Visibility</strong>
            </li>
            <p className="text-justify text-lg">
            This is for brands targeting domestic clients, our domestic SEO marketing services are a good pick.  As local search engines have different algorithms, keeping in mind the modulation our specialists help you optimize your website to bring in local search engine search results. 
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Reveal Our Validated And Efficient SEO Process:
          </h2>
          <br />
          <p className="text-justify">
          Every successful brand has a backstory. The approach of SEO works on accurate research of keywords, link building, standard performance, and on-page optimization. We actively keep an eye on the algorithm to make you get high success and growth. 
          </p>
          <ul>
            <li>
              <strong>In-Depth Website Audit Services-Strengthen Your Website:</strong>
            </li>
            <p className="text-justify text-lg">
            Every height needs a strong base likewise SEO growth needs a strong foundation as well. Our In-depth website audit services maintain quality content, and a good experience for the user, and evaluate the technical aspects of your site.
            <br />
            We generate individual development plans (IDP) for the improvement of the areas where there are issues that affect your ranking and optimization. 
            </p>

            <li>
              <strong>
              Building A Phenomenal SEO Roadmap: 
              </strong>
            </li>

            <p className="text-justify text-lg">
            We have an armed team of specialists who can customize SEO strategies according to the niche of the website you own. The formulation of this customization includes;
            </p>
            <li>
            Keyword Research
            </li>
            <li>
            Website Analysis
            </li>
            <li>
            Technical optimization
            </li>
            <li>
            Strategy of Content
            </li>
            <li>
            Outreaching
            </li>
            <li>Regular monitoring </li>
            <li>Local SEO strategies</li>
            <p>
            Let’s take your unique web to heights together!
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Choose Us?
          </h2>
          <ul>
            <li>
              <strong>
              We Deliver Thousands of Online Visitors to You
              </strong>
            </li>
            <p>
            Consider your website to be a shop. The larger the clientele you have, the more advantageous it is for the business you run. We use magic to make it simple for clients to find your online store when they perform a search. 
            </p>
            <li>
              <strong>We Promote Your Website </strong>
            </li>
            <p>
            Do you want people to talk about your website on the internet? Phone us now! We'll make your website incredibly well-known using our unique abilities.
            </p>
            <li>
              <strong>We are SEO whizzes</strong>
            </li>
            <p>
            We work like internet wizards, utilizing our magic to create an amazing and well-known website for you. To increase the visibility of your website, our staff does tasks including selecting the most appropriate keywords, enhancing your content, and creating solid online relationships.
            </p>
            <li>
              <strong>Individualized Approaches</strong>
            </li>
            <p>
            We reject the idea that one solution fits everyone. Our team of professionals creates SEO plans specifically for your website. We do keyword research, examine your website, optimize the technical aspects, produce incredible content, and more. Additionally, we monitor internet activity to guarantee your success.
            </p>

            <li>
              <strong>Stable Base for Growth</strong>
            </li>
            <p>
            SEO development needs a strong foundation, just like a tall tower needs one. Our comprehensive website audit services ensure that your website has high-quality content and a simple user interface, and addresses any technological problems. We even come up with unique plans to fix any issues.
            </p>

            <li>
              <strong>Reach Your Objectives</strong>
            </li>
            <p>
            We're not here to promise things we can't keep. We're here to produce outstanding outcomes. Your objectives are also our objectives, and we will work assiduously, tenaciously, and creatively to make your website succeed.
            </p>

            <li>
              <strong>Long-Term Success</strong>
            </li>
            <p>
            Our partnership with you is about ongoing progress, not just success. To ensure that your website keeps getting better and is successful, we analyze and make improvements.
            <br />
            So why pick us? Because we're here to make your website shine and prosper online, we're more than just an SEO agency.
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
      </ServicesPage>
  )
}

export default Seo