import React,{useState,useEffect} from "react";

import { motion, } from "framer-motion";
import FooterLogo from "../Asset/Images/nycprowebdesigners.com.svg";
import Loader from "../Components/Loader";
import Triangle from "../Asset/Animation/triangle.json"
import { Link } from "react-router-dom";
import SimpleButton from "../Components/SimpleButton";

function Footer() {
  const [animation ,setAnimation] = useState("")
  const  apiGetAnimationJson = async ()=>{
    console.timeStamp("start")
      const response = await fetch("https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728320/lotties%20Json%20Files/nyc%20Home%20page%20lotties/celibrate_b995ry.json")
      const jsonData  = await response.json()
      setAnimation(jsonData)
  }
  useEffect(() => {
    apiGetAnimationJson()
  }, []);

  return (
    <div className=" w-full  h-[1000px] flex  overflow-hidden relative box-border">
      {/* Circle Animation */}
      <motion.div
        // initial={{x:0}}
        whileInView={{ left: "-50px", top: -10, right: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -top-10 right-0 hidden md:block"
      />
      <motion.div
        initial={{ x: 0 }}
        whileInView={{ right: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="w-36 h-36 rounded-full blur-[50px] bg-ligh absolute -top-5  hidden md:block "
      />

      <motion.div
        initial={{ right: 0 }}
        whileInView={{ left: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 2 }}
        className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -bottom-10  z-10 hidden md:block "
      />

{/* Triangle animation */}
<motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{once:true}}
      transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
      
      className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{once:true}}
      transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
      
      className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

{/* black ball to scale 5.2 */}
      
      {/* Large device 1280 */}
      <motion.div
        initial={{
          bottom: "10%",
          width: "400px",
          height: "400px",
          left: "10%",
          scale: 1,
        }}
        whileInView={{ scale: 5.2, right: "50%", left: "39%", bottom: "-9%" }}
        viewport={{once:true}}
        transition={{ duration: 1.8 }}
        className=" bg-black -z-10 absolute  rounded-full   xl:!bottom-[-35%]  xxl:!bottom-[-25%] hidden xl:block  "
      >
      </motion.div>
      {/* small laptop 1024 */}
      <motion.div
        initial={{
         
          width: "300px",
          height: "300px",
          left: "10%",
          scale: 1,
        }}
        whileInView={{ scale: 4.7, right: "20%", left: "40%",}}
        viewport={{once:true}}
        transition={{ duration: 1.8 }}
        className=" bg-black -z-10 absolute  rounded-full  xl:hidden !bottom-[-20%] mm:!bottom-[-17%] lm:!bottom-[-14%]  md:!bottom-[-6%] lg:!bottom-0  "
      >
      </motion.div>


      <div className="container  flex gap-2 mx-auto justify-center items-center p-4 z-20 flex-col-reverse lg:flex-row  ">
        
        {/* left side div */}
        <div className=" gap-y-14 w-[100%] lg:w-[50%] mb-8  self-end px-2">
        {/* footer Nyc logo */}
      <Link to={"/"} >
          <motion.img 
          width={"100%"}
          height={"100%"}
          loading="eager"
          title="Footer Logo"
          initial={{scale:0,opacity:0}} 
          whileInView={{scale:1,opacity:1}}
          viewport={{ once : true }}
          transition={{duration:2,delay:1.3}}
          
         className="overflow-hidden lm:w-[50%] lg:w-[60%] "  
          src={FooterLogo} alt="nycprowebdesigners" />

</Link>
      


<motion.div className="text-right"

initial={{scale:0,opacity:0}} 
          whileInView={{scale:1,opacity:1}}
          viewport={{ once : true }}
          transition={{duration:2,delay:1.3}}

>

              <SimpleButton
                title="Contact Now"
                bg="bg-[#E14242]"
                color="text-white"
                hover="hover:text-[#E14242] hover:bg-white"
              />
</motion.div>

        
          {/* footer contact and social information */}
          <div className="flex gap-10 flex-col   md:flex-row self-end  mt-10 text-center justify-center md:justify-normal sm:text-left ">
            <motion.div 
            initial={{opacity:0,x:"-10vw"}}
            whileInView={{opacity:1,x:0}}
            viewport={{once:true}}
            transition={{duration:2,delay:1.6}}
            className="text-white  text-[17px] lm:text-[20px] break-words">
              <h2 className="text-[25px] font-semibold tracking-wider">Contact</h2>
              <p>
            
                <b>Address: </b> 350W 42nd Street New York, Ny, USA.
              </p>
              <a href="tel:929-294-7272">
           
                <b>Phone: </b>+1 (929) 833-1499
              </a>
              <br />
              <a href="mailto: info@nycprowebdesigners.com">
        
                <b>Email: </b>info@nycprowebdesigners.com
              </a>
            </motion.div>

            <motion.div 
            initial={{opacity:0,x:"-10vw"}}
            whileInView={{opacity:1,x:0}}
            viewport={{once:true}}
            transition={{duration:2,delay:1.3}}
            
            className="text-white text-[17px] lm:text-[20px]">
              <h2 className="text-white text-[25px] font-semibold tracking-wider ">Social</h2>
              <Link  to={"https://www.facebook.com/NYCProWebDesigners"} target="_blank"  >Facebook</Link> <br />
              <Link to="https://www.instagram.com/nycprowebdesigners_/"  target="_blank" >Instagram</Link> <br />
              <Link target="_blank" to="www.linkedin.com/company/nycprowebdesigners/">Linkedin</Link> <br />
              <Link target="_blank" to="https://twitter.com/nyc_developer" >Twitter</Link> <br />
            </motion.div>

            <motion.div 
            initial={{opacity:0,x:"-10vw"}}
            whileInView={{opacity:1,x:0}}
            viewport={{once:true}}
            transition={{duration:2,delay:1.3}}
            
            className="text-white text-[17px] lm:text-[20px] w-[300px]  ">
              <h2 className="text-white text-[25px] font-semibold tracking-wider ">Services</h2>
              <Link  to="/game-development-services"  >Game Development</Link> <br />
              <Link to="/3d-animation"  >2D / 3D Animation</Link> <br />
              <Link  to="/crm-software-services">CRM SOFTWARE</Link> <br />
              <Link  to="/ai-mobile-app" >AI MOBILE APPS</Link> <br />
            </motion.div>
          </div>
        </div>
        {/*Lamp Image */}
         <motion.div 

            initial={{scale:0,opacity:0}}
            whileInView={{scale:1,opacity:1}}
            viewport={{once:true}}
            transition={{duration:2,delay:1.3}}

            className=" flex w-[70%] md:w-[60%] lg:w-[50%] relative   lg:self-end  ">
              
            <img width={"100%"}
          height={"100%"} loading="eager" title="NYCPROWEBDESIGNERS.COM FOOTER LOGO" src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726679/ncy%20Home%20Images/Lamp_oqmhxx.webp" alt="Nyc footer" className="w-[100%] " />

            <motion.div
            initial={{opacity:0,visibility:"hidden"}}
            whileInView={{opacity:1,visibility:"visible"}}
            viewport={{once:true}}
            transition={{duration:2,delay:1.3}}
            className=" absolute top-[16%] -left-[10%] h-full -z-20 "> 
                <Loader width={"100%"} height={"100%"} data={animation} />
            </motion.div>

          </motion.div>
       

      </div>
    </div>
  );
}

export default Footer;
