import React from "react";
import { motion, } from "framer-motion";
import Person1 from "../Asset/Images/person1.webp";
import Person2 from "../Asset/Images/person2.webp";
import Person3 from "../Asset/Images/person3.webp";
import Loader from "../Components/Loader";
import LoadingAnimation from "../Asset/Animation/loading.json";
import ScrollDownAnimation from "../Asset/Animation/scroll_down.json";
import CustomButton from "../Components/CustomButton";
import Triangle from "../Asset/Animation/triangle.json";

import Header from "../Components/Header";

function HeroSection() {

  return (
    <>

    <div className=" bg-white  flex flex-col overflow-hidden  min-h-screen w-full   relative ">
      <Header />

      {/* Circle Animation */}
      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -top-10 right-0 hidden md:block "
        initial={{ x: -800, y: 500, visibility: "hidden" }}
        animate={{ x: 0, y: 0, visibility: "visible" }}
        transition={{
          visibility: "show",
          duration: "2",
          delay: "1",
        }}
      />
      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -top-5  -left-[50px] hidden md:block "
        initial={{ x: 800, y: 500, visibility: "hidden" }}
        animate={{ x: 0, y: 0, visibility: "visible" }}
        transition={{
          duration: "2",
          delay: "1",
        }}
      />

      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute bottom-0 -left-[50px] hidden md:block "
        initial={{ x: 800, y: -400, visibility: "hidden" }}
        animate={{ x: 0, y: 0, visibility: "visible" }}
        transition={{
          duration: "2",
          delay: "1",
        }}
      />

      {/* Triangle Animation */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 5, easings: "easeInOut", delay: 1.5 }}
        className="rotate-90 bottom-0 right-0 w-[250px] h-[250px]  absolute hidden md:block"
      >
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 5, easings: "easeInOut", delay: 1.5 }}
        className="rotate-90 top-0 left-0 w-[250px] h-[250px] absolute hidden md:block"
      >
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

      {/* person 2 */}
      <motion.img
      title="IT SOLUTIONS"
      loading="eager"
        className="self-start ml-3 lg:self-end  mr-10  w-[20%] md:w-[11%] mt-[60px]  "
        initial={{ x: "-46vw", y: "30vh", scale: 0.1, visibility: "hidden" }}
        animate={{
          x: 0,
          y: 0,
          visibility: "visible",
          scale: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        }}
        transition={{
          duration: "2",
          delay: "1",
        }}
        src={Person2}
        alt="IT Solution"
        width={200}
        height={"100%"}
      />

      <div className="flex container lg:max-w-[90%]  justify-center items-center mx-auto  w-full h-fit ">
        {/* person 1 male */}
        <motion.img
        title="It's Yours"
loading="eager"
          initial={{ x: "30vw", y: "-10vw", visibility: "hidden", scale: 0.1 }}
          animate={{
            x: 0,
            y: 0,
            visibility: "visible",
            scale: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          }}
          transition={{
            duration: "2",
            delay: "1",
          }}
          className="hidden xl:block w-[19%]"
          src={Person1}
          alt="It's Yours"
          width={"100%"}
          height={"100%"}
        />

        <div className=" w-fit container gap-y-2 lg:ml-[14%]  flex flex-col  xl:m-0 p-0  lg:justify-normal xl:items-stretch justify-center font-heading  ">
          <div className="flex lg:items-center gap-y-3 md:gap-0  leading-[90px] w-fit md:gap-y-2 flex-col lg:flex-row ">
            <motion.h1
              className=" m-0 font-semibold 
            text-4xl lm:text-5xl sm:text-6xl lg:text-[57px] lg:leading-[58px]  xl:text-7xl xxl:text-[4.8rem]
            "
              initial={{ x: "-100vw" }}
              animate={{ x: 0 }}
              transition={{
                duration: "1.3",
                delay: "1",
              }}
            >
              IT'S YOUR
            </motion.h1>

            <motion.div
              initial={{ scale: "3", visibility: "hidden" }}
              animate={{ scale: "1", visibility: "visible" }}
              transition={{
                duration: "1.5",
                delay: "0.2",
              }}
              className="hidden lg:block   transition-all duration-[1.5s] z-10 w-[120px] h-[105px]"
            >
              <Loader width={"100%"} height={"100%"} data={LoadingAnimation} />
            </motion.div>

            <motion.h2
              initial={{ x: "100vw" }}
              animate={{ x: 0 }}
              transition={{
                duration: "1.3",
                delay: "1",
              }}
              className="m-0 font-semibold   text-white
              text-4xl  lm:text-5xl  sm:text-6xl  lg:text-[57px] xl:text-7xl xxl:text-[4.8rem]
              "
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
              }}
            >
              ONE-STOP
            </motion.h2>
          </div>

          <motion.h2
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{
              duration: "1.3",
              delay: "1",
            }}
            className=" m-0 font-semibold 
             w-fit
         xsm:text-[1.4rem]  mm:text-[1.675rem] mm:leading-[1.675rem] lm:text-[2rem] sm:text-[3.15rem]   lg:text-6xl   xl:text-7xl xxl:text-[4.8rem]
            "
          >
            SOFTWARE SOLUTIONS
          </motion.h2>

          <motion.div
            className=" text-justify lg:text-center w-full text-[16px] text-[#848484] lg:w-[85%]  "
            initial={{ y: 800 }}
            animate={{ y: 0 }}
            transition={{
              duration: "2",
              delay: "1",
            }}
          >
            Empower efficiency, boost growth, and unleash your business
            potential with our dynamic software solution providing services. Our
            solutions are crafted to enhance your operations, productivity, and
            competition in this fast-paced evolving market where every step
            makes a change.
          </motion.div>
        </div>
      </div>

      <div className="flex container mx-auto  mt-5 ">
        <div className="gap-y-4 flex flex-col  self-start mx-auto items-center">
          <motion.a
            href="#section1"
            className="flex flex-col gap-y-8 items-center transform-none"
            initial={{ y: 800 }}
            animate={{ y: 0 }}
            transition={{
              duration: "2",
              delay: "1",
            }}
          >
            <CustomButton title={"Get Started"} />
          </motion.a>

          <motion.a
            href="#section1"
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{
              duration: "2",
              delay: "1",
            }}
            className="w-[100px] h-[90px]"
          >
            <Loader width={"100%"} height={"100%"} data={ScrollDownAnimation} />
          </motion.a>
        </div>
      </div>

      <motion.img
      title="software solution"
      loading="eager"
        initial={{ x: "-60vh", y: "-42vh", scale: 0.1, visibility: "hidden" }}
        animate={{
          x: 0,
          y: 0,
          visibility: "visible",
          scale: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        }}
        transition={{
          duration: "2",
          delay: "1",
        }}
        src={Person3}
        alt="software solution"
        width={200}
          height={"100%"}
        className="  w-[20%] md:w-[11%] hidden md:block absolute bottom-[7%] right-20"
      />
    </div>
    </>

  );
}

export default HeroSection;
