import React from "react";
import PortfolioContainer from "../Components/PortfolioContainer";

function OurPortfolio() {
  return (
    <div className=" container mx-auto h-full w-full overflow-auto">
      <div className="  xsm:text-3xl mt-4    lm:text-4xl  sm:text-5xl  md:text-6xl  lg:text-7xl xl:text-8xl font-bold font-heading flex uppercase justify-center  items-center  gap-3">
        <h2 className="">Our</h2>
        <h2
          className="text-white "
          style={{
            WebkitTextStrokeColor: "black",
            WebkitTextStrokeWidth: "1px",
          }}
        >
          Portfolio
        </h2>
      </div>
      <PortfolioContainer />
    </div>
  );
}

export default OurPortfolio;
