import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function YoutubeVideoProduction() {
    const data ={
        title: `youtube video\n Production`,
        description:"We recognize the immense influence of YouTube as a platform for expressing your message, boosting your brand, and establishing connections with your audience. Our top-tier video creation services are built to assist you in tapping into YouTube's full potential and taking your online presence to newfound heights.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712603/lotties%20Json%20Files/youtubeVideoProduction_payikn.json",
        head:{
          title:" YouTube Video Production | NYC Pro Web Designers | NY 10036",
          description:" NYC's top web designers enhance your YouTube presence with professional video production, engaging audiences.",
          canonical:`<link href="https://nycprowebdesigners.com/youtube-video-production" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What sorts of recordings can you deliver for YouTube?",
        answer: `<p>We specialize in a wide run of YouTube recordings, counting item audits, instructional exercises, vlogs, limited-time recordings, and more.</p>`,
      },
      {
        question: "2. Can you offer assistance with scripting and storyboarding?",
        answer: `<p>Completely! Our group of experienced experts can help you create locks in scripts and storyboards that adjust along with your brand and objectives.
        </p>`,
      },
      {
        question: "3. Do you give video-altering administrations for YouTube recordings?",
        answer:
          "<p>Yes, we offer comprehensive video altering administrations to improve the quality of your YouTube recordings, counting color redress, sound upgrades, moves, and more.</p>",
      },
      {
        question: "4. Can you optimize my YouTube videos for search motors?",
        answer:
          "<p>Yes, we can optimize your recordings with significant watchwords, locks-in titles, and compelling portrayals to progress their permeability and reach on YouTube and look motors.</p>",
      },
      {
        question: "5. Do you offer YouTube channel administration administrations?",
        answer:
          "<p>Whereas our fundamental center is on video generation, we will give direction and suggestions for overseeing and developing your YouTube channel successfully.</p>",
      },
    ];
  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Top-Notch YouTube Video Production Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          To reach their target audience and capture their attention, firms now place a huge emphasis on using YouTube videos. It's similar to when you view your preferred videos or cartoons online. Businesses also do it, but they present you with interesting information about their goods or concepts.
            <br />
            On YouTube, businesses operate in this manner. They produce videos to showcase their work and extol its virtues. Therefore, keep in mind that the individuals in those pleasant, upbeat videos want to be companions and teach you something wonderful.

            <br />
            On YouTube, businesses operate in this manner. They produce videos to showcase their work and extol its virtues. Therefore, keep in mind that the individuals in those pleasant, upbeat videos want to be companions and teach you something wonderful.
          </p>

          <ul>
            <li>
              <strong>Amplify Brand Exposure: </strong>
            </li>
            <p className="text-justify text-lg">
            Within YouTube's sphere, you have the opportunity to establish a channel imbued with your brand's essence, allowing for the dissemination of both informative and captivating videos linked to your industry.
            </p>

            <li>
              <strong>Reach A Global Viewership: </strong>
            </li>

            <p className="text-justify text-lg">
            The widespread grasp of YouTube transcends geographical boundaries, providing you with a conduit to connect with audiences hailing from all corners of the globe. 
            </p>
            <li>
              <strong>Steer Website Traffic: </strong>
            </li>
            <p className="text-justify text-lg">
            YouTube videos hold the potential to serve as a potent catalyst for channeling traffic to your website. By adroitly incorporating links and persuasive calls-to-action within your video descriptions, the pathway to direct viewers to your website becomes tangible, thereby augmenting the prospects of conversion.
            </p>

            <li>
              <strong>Raise Audience Engagement: </strong>
            </li>
            <p className="text-justify text-lg">
            Videos possess a distinctive knack for entrancing and engaging spectators. By meticulously crafting videos that are both of superior quality and enlightening, the foundation for forging deeper bonds with your audience is laid, cultivating sentiments of trust and allegiance.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Tapping Into Business Potential: YouTube Ads/Informative Channels
          </h2>
          <br />
          <p className="text-justify">
          Businesses can make the most out of YouTube for their marketing goals in different ways.
            <br />
            Here are two effective approaches:
          </p>
          <ul>
            <li>
              <strong>YouTube Ad Campaigns: </strong>
            </li>
            <p className="text-justify text-lg">
            You can watch videos on YouTube, which also contains something called "ads." Businesses adore using these advertisements to communicate with the precise audience they desire. Similar to a television commercial, but with more customization on YouTube.
            <br />
            This is how it goes:
            </p>

            <li>
              <strong>
              Customized Ads: &nbsp;
              </strong>
              This implies that firms can control who sees their advertisements. They take into consideration things like your age, your viewing preferences, and your residence. After that, they display their advertisements to potential customers. So, if you enjoy playing video games, you might see advertisements for new ones.
            </li>

           
            <li>
              <strong>
              Cost-effective: &nbsp;
              </strong>
              YouTube advertisements are an effective way for companies to spend their money. They don't squander their time by showing advertisements to indifferent viewers. They concentrate on the appropriate market, ensuring that every dollar spent moves them one step closer to promoting their goods or concepts.
            </li>
           

            <li>
              <strong>
              Informative Brand Channels: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Crafting a dedicated YouTube channel that focuses on educating audiences about your industry can be a potent marketing strategy like;
            </p>

            <li>
              <strong>
              Increase Brand Recognition: &nbsp;
              </strong>
              When you repeatedly view advertisements for a certain brand or business, you start to remember them. Businesses do this to enhance their brand recognition so that you will be more likely to think of them if you ever need what they provide.
            </li>

            <li>
              <strong>
              Enhance Conversions: &nbsp;
              </strong>
              Getting customers to take the actions that the company desires, like making a purchase or subscribing to a newsletter, is referred to as "conversions." YouTube advertisements may be made to persuade viewers to do these things, which makes them incredibly beneficial for businesses.
            </li>
            
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Crafting Compelling And Conversion-Focused YouTube Videos
          </h2>
          <br />
          <p className="text-justify">
          We've honed our process for producing YouTube videos that truly captivate and drive results. 
          <br />
          Discover our approach below:
          </p>
          <ul>
            <li>
              <strong>
              Strategy And Planning: 
              </strong>
            </li>
            <p>
            We kick off by delving into your target audience, business objectives, and standout features. This lays the foundation for a tailored video strategy that aligns seamlessly with your brand.
            </p>
            <li>
              <strong>Creative Concept Crafting: </strong>
            </li>
            <p>
            Our team of experienced creatives brainstorms innovative video ideas designed to seize attention and make a lasting impact. Every concept mirrors your brand's identity and effectively conveys your message.
            </p>
            <li>
              <strong>Scriptwriting And Storytelling Blueprint: </strong>
            </li>
            <p>
            Our adept scriptwriters compose compelling narratives that weave a captivating story while highlighting the prime benefits of your offerings. Next, we sketch out detailed storyboards that provide a visual preview of the video's flow and aesthetics.
            </p>
            <li>
              <strong>Professional Filming And Expert Editing: 
              </strong>
            </li>
            <p>
            We harness cutting-edge tools and techniques to capture top-tier footage that presents your brand in the best possible light. Our proficient editors then work their magic, integrating captivating visuals, smooth transitions, and engaging effects.
            </p>

            <li>
              <strong>Branding And Optimization: 
              </strong>
            </li>
            <p>
            By infusing your brand elements—logos, colors, taglines—we ensure a consistent, easily recognizable presence throughout the video. To enhance visibility and reach on YouTube, we also fine-tune the video's title, description, and tags.
            </p>

            <li>
              <strong>Compelling Call-To-Actions: 
              </strong>
            </li>
            <p>
            Thoughtfully positioned within the video, our persuasive call-to-actions nudge viewers towards desired actions, whether it's subscribing, exploring your website, or making a purchase.
            </p>

            <li>
              <strong>Analytics And Refinement: 
              </strong>
            </li>
            <p>
            We closely track your YouTube videos' performance using advanced analytics tools. This data empowers us to spot areas for enhancement and fine-tune future video content to heighten engagement and drive more conversions.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Strength Of Video Marketing: YouTube Video Crafting Solutions
          </h2>
          <br />
          <p className="text-justify">
          Explore our array of services below:
          </p>
          <ul>
            <li>
              <strong>Interactive Webcasts: </strong>
            </li>
            <p>
            Engage your audience with lively and interactive webcasts that deliver your message in real time, forming meaningful connections and igniting participation.
            </p>
            <li>
              <strong>Compelling Infomercials: </strong>
            </li>

            <p>
            Grab your viewers' attention with persuasive infomercials that spotlight the distinctive attributes and advantages of your offerings, creating a memorable impact.
            </p>
            <li>
              <strong>Tv Ads: </strong>
            </li>
            <p>
            Make a splash on the television screen with professionally created TV commercials that seize attention, narrate a story, and establish strong brand recognition.
            </p>

            <li>
              <strong>Live Streaming Excellence: </strong>
            </li>
            <p>
            Expand your reach by live streaming your occasions, conferences, or product discovering. We ensure seamless live-streaming encounters that keep viewers engaged.
            </p>
            <li>
              <strong>Authentic On-Location Shoots:  </strong>
            </li>
            <p>
            Capture the essence of your brand or narrative through on-location shoots that bring forth authenticity and a personal touch to your videos.
            </p>
            <li>
              <strong>Business Web Streaming:</strong>
            </li>
            <p>
            Establish a direct connection with your audience via web streaming that conveys your message straight to their screens, whether it's for training, webinars, or virtual events.
            </p>

            <li>
              <strong>Skillful Post-Production: </strong>
            </li>
            <p>
            Our adept editors work their magic during the post-production phase, incorporating captivating visuals, smooth transitions, and engaging effects to breathe life into your video.
            </p>

            <li>
              <strong>Expert Video Editing:</strong>
            </li>
            <p>
            Our skilled editors will transform your raw footage into refined and impactful videos that harmonize with your brand and captivate your audience.
            </p>

            <li>
              <strong>Tailored Marketing Visuals: </strong>
            </li>
            <p>
            Accelerate your marketing initiatives with tailored visuals that adeptly communicate your brand's unique value proposition and steer conversions.
            </p>

            <li>
              <strong>YouTube Crafting Mastery:</strong>
            </li>
            <p>
            Harness the potential of YouTube with our all-encompassing video crafting services, thoughtfully designed to assist you in developing riveting content that stands out on the platform.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Raise Your Brand Using Our Video Advent Solutions
          </h2>
          <p className="text-justify">
          Ultra-modern to go away a first-rate mark with your advertising videos? let our video creation solutions be your guiding mild. 
          <br />
          Find out the array of modern-day offerings we provide below:
          </p>

          <ul>
            <li>
              <strong>Crafting Advertising And Marketing Videos:</strong>
            </li>
            <p>
            Seize your target market's awareness and forge a lasting imprint with our skillfully designed advertising videos, which showcase your brand's different tale and cost proposition.
            </p>
            <li>
              <strong>Capturing Real Environments:  </strong>
            </li>

            <p>
            Breathe lifestyles into your imaginative and prescient with our on-place video creation services, capturing the essence of today's emblem inside actual-world environments that deeply resonate with your viewers.
            </p>
            <li>
              <strong>Designing Tv Advertisements: </strong>
            </li>
            <p>
            Make a vibrant impact on the small screen with our television commercial design offerings, fabricating gripping advertisements that command interest, narrate a tale, and etch a long-lasting reminiscence.
            </p>

            <li>
              <strong>Polishing Video Editing: </strong>
            </li>
            <p>
            Our proficient team of contemporary editors will metamorphose your uncooked pictures into subtle masterpieces, making sure seamless transitions, captivating visuals, and engrossing effects grip your viewers.
            </p>
            <li>
              <strong>Growing Persuasive Infomercials:  </strong>
            </li>
            <p>
            Damage far from the crowd with our infomercial crafting services, producing compelling and convincing motion pictures that showcase the splendid developments and advantages of ultra-modern services.
            </p>
            <li>
              <strong>Broadcasting Visuals: </strong>
            </li>
            <p>
            Amplify your reach with our video broadcasting services, ensuring your message traverses effects across diverse structures and devices.
            </p>

            <li>
              <strong>Interactive Webcasts:</strong>
            </li>
            <p>
            Engage your audience in actual time through our webcast answers, handing over lively and interactive content material that fosters meaningful connections and stimulates engagement.
            </p>

            <li>
              <strong>Accessible Manufacturing Gear:</strong>
            </li>
            <p>
            Gain access to video production devices via our offerings, empowering you to fabricate professional-grade videos that forge an enduring effect.
            </p>
            <p>
            Are you ready to propel your video advent to unparalleled heights? Reach out to us today to converse about how our services can help boost your emblem's fame and captivate your target audience.
            </p>

          </ul>

        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default YoutubeVideoProduction