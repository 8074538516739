import React, { useState } from "react";
import ServiceBox from "../Components/ServiceBox";
import { motion } from "framer-motion";
import Triangle from "../Asset/Animation/triangle.json";
import Loader from "../Components/Loader";

import "../App.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCreative,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/virtual";
import "swiper/css/effect-cube";

//<======================import card logo===================================>
import LogoDesign from "../Asset/icons/Logo-Design.webp";
import TwoD from "../Asset/icons/2d-3d.webp";
import SocialMedia from "../Asset/icons/Social-Media.webp";
import WebDevelopment from "../Asset/icons/Web-dvelopment-custom.webp";
import MobileApps from "../Asset/icons/mobile-app.webp";
import Seo from "../Asset/icons/SEO.webp";
import ThreeDModeling from "../Asset/icons/3d-model.webp";
import AiMobileApps from "../Asset/icons/Ai-mobile-app.webp";
import BookMarketing from "../Asset/icons/Book-Markting.webp";
import CgiArt from "../Asset/icons/CGI.webp";
import CorporateVideo from "../Asset/icons/Coprate-video.webp";
import Crm from "../Asset/icons/CRM.webp";
import DigitalMarketing from "../Asset/icons/Digtal-Marketing.webp";
import DomainHosting from "../Asset/icons/Domian-hosting.webp";
import EducationalVideo from "../Asset/icons/Educational-video.webp";
import Erp from "../Asset/icons/ERP.webp";
import ExplanerVideo from "../Asset/icons/Explaner-video.webp";
import GameDevelopment from "../Asset/icons/Game-development.webp";
import Magento from "../Asset/icons/magento.webp";
import MedicalVideo from "../Asset/icons/Medical-Video.webp";
import MotionGraphic from "../Asset/icons/Motion-Graphic.webp";
import NftArt from "../Asset/icons/NFT-Art.webp";
import Pos from "../Asset/icons/POS.webp";
import ProductionVideo from "../Asset/icons/Production-video.webp";
import promotionalVideo from "../Asset/icons/promotional-video.webp";
import Saas from "../Asset/icons/SAAS.webp";
import Shopify from "../Asset/icons/Shopify.webp";
import VideoAds from "../Asset/icons/Video-ads.webp";
import VideoAnimation from "../Asset/icons/Video-animation.webp";
import Videoediting from "../Asset/icons/Video-editing.webp";
import VideoProduction from "../Asset/icons/Video-production.webp";
import WhiteBoard from "../Asset/icons/White-Board.webp";
import WordPress from "../Asset/icons/Wordpress.webp";
import YoutubeVideo from "../Asset/icons/Youtube-Video.webp";
import Header from "../Components/Header";
// <====================================================>

import { Helmet } from "react-helmet-async";

function Slider({ cardInfo }) {
  const [activeSlider, setActiveSlider] = useState(0);
  return (
    <Swiper
      effect="creative"
      creativeEffect={{
        prev: {
          shadow: false,
          translate: [0, 0, -400],
          // translate: [-400, 0, 0],

          opacity: 0,
        },
        next: {
          translate: ["100%", 0, 0],
          opacity:0

        },
      }}
      className={`w-[100%] h-full !py-8 cursor-pointer overflow-scroll`}
      modules={[
        Navigation,
        Pagination,
        Scrollbar,  
        A11y,
        EffectCreative,
        Autoplay
      ]}
      slidesPerView={1}
      controller={true}
      navigation={true}
      autoplay= {{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      initialSlide={activeSlider}
      onActiveIndexChange={(e) => setActiveSlider(() => e.activeIndex)}
    >
      {cardInfo.map((data, index) => {
        return (
          <SwiperSlide key={index}>
            <motion.div className="container m-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-4 ">
              {data.map((item, i) => {  
                return  <ServiceBox key={item.id} info={item} />
              })}
            </motion.div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

function OurServices() {
  const cardInfo = [
    [
      {
        id: 0,
        title: "Game Development",
        description:
          "Generating mesmerizing games that gratify players with front line technology. Bringing your vision to real life. Concepts to an actual gameplay.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: GameDevelopment,
        navigateTo: "game-development-services",
      },
      {
        id: 1,
        title: "2D / 3D Animation",
        description:
          "Unleash the power of visuals with our compelling 2D/3D animation services. Our animations narrate story of your brand leading to greater engagement.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: TwoD,
        navigateTo: "3d-animation",
      },
      {
        id: 2,
        title: "crm softwares",
        description:
          "Consolidate interactions, develop customer satisfaction and boost productivity through our CRM software solutions. Adhere unique business needs.",
        img: "",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: Crm,
        navigateTo: "crm-software-services",
      },
      {
        id: 3,
        title: "Ai mobile apps",
        description:
          "Lend a hand to future with our AI mobile apps. With driving engagement, we leverage AI to create applications that are personalized for user’s experiences.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: AiMobileApps,
        navigateTo: "ai-mobile-app",
      },
      {
        id: 4,
        title: "Mobile Apps",
        description:
          "Be it android or iOS, we create apps that rich in features and fulfil your targeted audience and boosts your growth and enrich your engagement.",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: MobileApps,
        navigateTo: "mobile-app-development-services",
      },
      {
        id: 5,
        title: "SAAS",
        description:
          "Encircle the advancement of SAAS solutions customized according to the needs of your business. We offer collaboration, tools and smooth operations.",
        img: "",
        bg: "bg-seo",
        hover: "hover:bg-seo-hover",
        svgImg: Saas,
        navigateTo: "saas-services",
      },
    ],
    [
      {
        id: 6,
        title: "erp softwares",
        description:
          "Get your operations optimized with our comprehensive ERP Software. Streamline operations, data and communication we provide real time insights.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: Erp,
        navigateTo: "erp-software-services",
      },
      {
        id: 7,
        title: "NFT ART",
        description:
          "Delve into the world of digital art with our NFTs. Opening new alleys for creativity and values by tokenizing the creations of artists. Empowering ownership.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: NftArt,
        navigateTo: "nft-art",
      },
      {
        id: 8,
        title: "pos softwares",
        description:
          "Smartly manage sales, tracking of inventory and elevate customer experiences with user friendly outcome. Streamline operations with our POS software.",
        img: "",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: Pos,
        navigateTo: "pos-software-services",
      },
      {
        id: 9,
        title: "Web app",
        description:
          "Legitimize your business with strongly compelling web app. Smooth and handy across devices which offers engaging running that appeals users.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: CgiArt,
        navigateTo: "web-app-development-services",
      },
      {
        id: 10,
        title: "Custom Website",
        description:
          "Get in front with custom website. Our team of experts generate digital visibility which is quite unique and it converts visitors into customers.",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: WebDevelopment,
        navigateTo: "custom-website-development-services",
      },
      {
        id: 11,
        title: "SEO",
        description:
          "Elevate your digital presence and reach with our SEO services. Our experts optimize your brand’s websites that rank higher on search engines.",
        img: "",
        bg: "bg-seo",
        hover: "hover:bg-seo-hover",
        svgImg: Seo,
        navigateTo: "seo-services",
      },
    ],
    [
      {
        id: 12,
        title: "WORDPRESS",
        description:
          "Mobilize the power of WordPress for your brand’s website. We generate user friendly platform that sync with your objectives and that are easy to manage.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: WordPress,
        navigateTo: "wordpress-development-services",
      },
      {
        id: 13,
        title: "SHOPIFY",
        description:
          "Lift off your ecommerce journey with no hustle on shopify. Our team designs and develops appealing digital store ensuring smooth shopping experiences.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: Shopify,
        navigateTo: "shopify-development-services",
      },
      {
        id: 14,
        title: "MAGENTO",
        description:
          "We provide feature rich ecommerce solution that caters complex operations that too with user friendly interference. Streamline business with Magento.",
        img: "",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: Magento,
        navigateTo: "magento-services",
      },
      {
        id: 15,
        title: "DOMAIN HOSTING",
        description:
          "Decisive domain hosting pays pivotal role in digital visibility. We provide secure solutions that makes sure your web is accessible every time.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: DomainHosting,
        navigateTo: "domain-hosting-services",
      },
      {
        id: 16,
        title: "Social Media",
        description:
          "Interact and immerse with your traffic by our strategic social media management. Our team runs campaigns that uplifts awareness and interactions.",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: SocialMedia,
        navigateTo: "social-media",
      },
      {
        id: 17,
        title: "CG / CGI ART",
        description:
          "Enhance your visuals with our fascinating graphics and imagery art. We have artists who brings out stunning visuals for media from your concepts.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: CgiArt,
        navigateTo: "cgi-art",
      },
    ],
    [
      {
        id: 18,
        title: "video animation",
        description:
          "We provide captivating video animations that increases the engagement and entertains your target audience. We fetch visually attractive messages.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: VideoAnimation,
        navigateTo: "video-animation-services",
      },
      {
        id: 19,
        title: "motion graphic",
        description:
          "Our dynamic motion graphics put in depth and vitality, elevating the visual attraction of your videos and uplifts your brand’s content.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: MotionGraphic,
        navigateTo: "motion-graphic",
      },
      {
        id: 20,
        title: "youtube videos",
        description:
          "Generate content that rules on the big video platforms, we boost connections with your audience through informative and appealing YouTube videos.",
        img: "",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: YoutubeVideo,
        navigateTo: "youtube-video-production",
      },
      {
        id: 21,
        title: "video post",
        description:
          "Refine your videos using post production techniques to create a polished and seamless viewing experience, for your audience.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: VideoProduction,
        navigateTo: "video-post-production",
      },
      {
        id: 22,
        title: "medical videos",
        description:
          "Effectively communicate information through visually clear and informative medical videos that educate and empower.",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: MedicalVideo,
        navigateTo: "medical-videos",
      },
      {
        id: 23,
        title: "Video Editing",
        description:
          "Create a narrative by editing footage incorporating visuals, audio and effects to captivate your viewers with impactful storytelling.",
        img: "",
        bg: "bg-seo",
        hover: "hover:bg-seo-hover",
        svgImg: Videoediting,
        navigateTo: "video-editing",
      },
    ],
    [
      {
        id: 24,
        title: "explainer videos",
        description:
          "Make complicated ideas more accessible with engaging explainer videos that break down concepts into visuals and narration.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: ExplanerVideo,
        navigateTo: "explainer-videos",
      },
      {
        id: 25,
        title: "corporate videos",
        description:
          "Present your company’s values, culture and accomplishments through quality videos that leave a lasting impression.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: CorporateVideo,
        navigateTo: "corporate-video",
      },
      {
        id: 26,
        title: "product videos",
        description:
          "Highlight the features and benefits of your products using visuals that engage potential customers and drive sales.",
        img: "",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: ProductionVideo,
        navigateTo: "product-videos",
      },
      {
        id: 27,
        title: "video ads",
        description:
          "Grab attention. Boost conversions with captivating video ads that effectively convey your brand message within a timeframe.",
        img: "",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: VideoAds,
        navigateTo: "video-ads",
      },
      {
        id: 28,
        title: "white board",
        description:
          "Bring concepts to life through whiteboard animations that visually guide viewers making learning or understanding effortless.",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: WhiteBoard,
        navigateTo: "white-board-animation",
      },
      {
        id: 29,
        title: "Educational Videos",
        description:
          "Empower learners, with videos that combine captivating visuals, audio elements and effective teaching methods to enhance engagement.",
        img: "",
        bg: "bg-seo",
        hover: "hover:bg-seo-hover",
        svgImg: EducationalVideo,
        navigateTo: "educational-videos",
      },
    ],

    [
      {
        id: 30,
        title: "Logo Design",
        description:
          "Build a brand image by creating a logo design that reflects the core values and vision of your company. Make your brand stand out I the crowd.",
        img: "",
        bg: "bg-logo",
        hover: "hover:bg-logo-hover",
        svgImg: LogoDesign,
        navigateTo: "logo-designing",
      },
      {
        id: 31,
        title: "3D Modeling",
        description:
          "Bring your ideas to life with 3D models adding a touch, to your products or designs. We have an armed team of experts for 3D modeling.",
        img: "",
        bg: "bg-animation",
        hover: "hover:bg-animation-hover",
        svgImg: ThreeDModeling,
        navigateTo: "3d-modeling",
      },
      {
        id: 32,
        title: "Promotional Videos",
        description:
          "Inform your target audience with captivating videos that promote events, products or services effectively with our promotional videos.",
        bg: "bg-social",
        hover: "hover:bg-social-hover",
        svgImg: promotionalVideo,
        navigateTo: "promotional-videos",
      },
      {
        id: 33,
        title: "Digital Marketing",
        description:
          "Enhance your visibility by utilizing our digital marketing strategies that generate increased traffic, engagement and conversions.",
        bg: "bg-web",
        hover: "hover:bg-web-hover",
        svgImg: DigitalMarketing,
        navigateTo: "digital-marketing-services",
      },
      {
        id: 34,
        title: "Book Marketing",
        description:
          "Expand your readership by advertising your book through videos that capture the essence of its story. Avail our Book Marketing services now!",
        img: "",
        bg: "bg-mobile",
        hover: "hover:bg-mobile-hover",
        svgImg: BookMarketing,
        navigateTo: "book-marketing-services",
      },
    ],
  ];

  return (
    <div className="relative">
      <Helmet>
        <title>Our Service | NYC Pro Web Designers | NY 10036</title>
        <meta
          name="description"
          content="Explore Our Services | NYC Pro Web Designers: Elevate your online presence with our expert web solutions"
        />
        <link
          href="https://nycprowebdesigners.com/services"
          rel="canonical"
        ></link>
      </Helmet>

      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -top-10 right-0 hidden md:block "
        initial={{ x: -800, y: 500, visibility: "hidden" }}
        animate={{ x: 0, y: 0, visibility: "visible" }}
        transition={{
          visibility: "show",
          duration: "2",
          delay: "1",
        }}
      />

      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -top-5  -left-[50px] hidden md:block "
        initial={{ x: 800, y: 500, visibility: "hidden" }}
        animate={{ x: 0, y: 0, visibility: "visible" }}
        transition={{
          duration: "2",
          delay: "1",
        }}
      />

      <Header />

      <div
        className=" h-full flex flex-col  top-[100px]   justify-center items-center overflow-hidden  relative   gap-y-6 "
        style={{ textRendering: "optimizeSpeed" }}
      >
        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute bottom-0 -left-[70px] hidden md:block"
          initial={{ x: 800, y: -400, visibility: "hidden" }}
          animate={{ x: 0, y: 0, visibility: "visible" }}
          transition={{
            duration: "2",
            delay: "1",
          }}
        />

        {/* TRiangle Animation */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, easings: "easeInOut", delay: 1 }}
          className="rotate-90 bottom-0 right-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-0 left-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.h1
          initial={{ x: "-100vh", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 2 }}
          className="text-5xl mm:text-6xl   md:text-8xl font-bold"
        >
          Our Services
        </motion.h1>
        <motion.p
          initial={{ x: "100vh", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 2 }}
          className=" mm:text-[19px]  md:text-2xl text-center text-gray"
        >
         We're a software solution agency, and our expertise lies in crafting one-of-a-kind solutions <br /> designed to address your unique needs and challenges.
        </motion.p>

        <Slider cardInfo={cardInfo} />
      </div>
    </div>
  );
}

export default OurServices;
