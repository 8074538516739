import React from "react";
import { BrowserRouter, Route, Routes,} from "react-router-dom";

import Home from "../Screens/Home";
import ContactPage from "../Screens/ContactPage";

// import  Pages
import OurServices from "../Screens/OurServices";
import WebDevelopment from "../Screens/ServicesPage/WebDevelopment";
import MobileApp from "../Screens/ServicesPage/MobileApp";
import CGIArt from "../Screens/ServicesPage/CGIArt";
import GameDevelopment from "../Screens/ServicesPage/GameDevelopment";
import NFTArt from "../Screens/ServicesPage/NFTArt";
import SaasSoftwares from "../Screens/ServicesPage/SaasSoftwares";
import SocialMedia from "../Screens/ServicesPage/SocialMedia";
import VideoEditing from "../Screens/ServicesPage/VideoEditing";
import LogoDesigning from "../Screens/ServicesPage/LogoDesigning";
import ERPSoftwares from "../Screens/ServicesPage/ERPSoftwares";
import Seo from "../Screens/ServicesPage/Seo";
import ThreedAnimation from "../Screens/ServicesPage/ThreedAnimation";
import Magento from "../Screens/ServicesPage/Magento";
import ExplainerVideos from "../Screens/ServicesPage/ExplainerVideos";
import ProductVideos from "../Screens/ServicesPage/ProductVideos";
import POSSoftwares from "../Screens/ServicesPage/POSoftwares";
import Shopify from "../Screens/ServicesPage/Shopify";
import VideoAnimation from "../Screens/ServicesPage/VideoAnimation";
import WordPressDevelopment from "../Screens/ServicesPage/WordPressDevelopment";
import YoutubeVideoProduction from "../Screens/ServicesPage/YoutubeVideoProduction";
import WhiteBoardAnimation from "../Screens/ServicesPage/WhiteBoardAnimation";
import ThreeDModeling from "../Screens/ServicesPage/ThreeDModeling";
import VideoPostProduction from "../Screens/ServicesPage/VideoPostProduction";
import PromotionalVideos from "../Screens/ServicesPage/PromotionalVideos";
import VideoAds from "../Screens/ServicesPage/VideoAds";
import MotionGraphic from "../Screens/ServicesPage/MotionGraphic";
import MedicalVideos from "../Screens/ServicesPage/MedicalVideos";
import EducationalVideos from "../Screens/ServicesPage/EducationalVideos";
import DomainHosting from "../Screens/ServicesPage/DomainHosting";
import CRMSoftwares from "../Screens/ServicesPage/CRMSoftwares";
import CorporateVideo from "../Screens/ServicesPage/CorporateVideo";
import BookMarketing from "../Screens/ServicesPage/BookMarketing";
import AiMobileApps from "../Screens/ServicesPage/AIMobileApps";
import AboutUs from "../Screens/AboutUs";
import DigitalMarketing from "../Screens/ServicesPage/Digitalmarketing";
import WebApp from "../Screens/ServicesPage/WebApp"

import NotFound from "../Screens/NotFound"
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import TermsAndConditions from "../Screens/Terms&Condition";
import ThankYou from "../Screens/ThankYou";


function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutUs />} />
        


        {/* secvice inner pages */}
        <Route path="/game-development-services" element={<GameDevelopment />} />
        <Route path="/nft-art" element={<NFTArt />} />
        <Route path="/saas-services" element={<SaasSoftwares />} />
        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/video-editing" element={<VideoEditing />} />
        <Route path="/logo-designing" element={<LogoDesigning />} />
        <Route path="/erp-software-services" element={<ERPSoftwares />} />
        <Route path="/seo-services" element={<Seo />} />
        <Route path="/3d-animation" element={<ThreedAnimation />} />
        <Route path="/custom-website-development-services" element={<WebDevelopment />} />
        <Route path="/mobile-app-development-services" element={<MobileApp />} />
        <Route path="/cgi-art" element={<CGIArt />} />
        <Route path="/magento-services" element={<Magento />} />
        <Route path="/explainer-videos" element={<ExplainerVideos />} />
        <Route path="/product-videos" element={<ProductVideos />} />
        <Route path="/pos-software-services" element={<POSSoftwares />} />
        <Route path="/shopify-development-services" element={<Shopify />} />
        <Route path="/video-animation-services" element={<VideoAnimation />} />
        <Route path="/wordpress-development-services" element={<WordPressDevelopment />} />
        <Route path="/youtube-video-production" element={<YoutubeVideoProduction />} />
        <Route path="/white-board-animation" element={<WhiteBoardAnimation />} />
        <Route path="/3d-modeling" element={<ThreeDModeling />} />
        <Route path="/video-post-production" element={<VideoPostProduction />} />
        <Route path="/promotional-videos" element={<PromotionalVideos />} />
        <Route path="/video-ads" element={<VideoAds />} />
        <Route path="/motion-graphic" element={<MotionGraphic />} />
        <Route path="/medical-videos" element={<MedicalVideos />} />
        <Route path="/educational-videos" element={<EducationalVideos />} />
        <Route path="/domain-hosting-services" element={<DomainHosting />} />
        <Route path="/crm-software-services" element={<CRMSoftwares />} />
        <Route path="/corporate-video" element={<CorporateVideo />} />
        <Route path="/book-marketing-services" element={<BookMarketing />} />
        <Route path="/ai-mobile-app" element={<AiMobileApps />} />
        <Route path="/digital-marketing-services" element={<DigitalMarketing />} />
        <Route path="/web-app-development-services" element={<WebApp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/thank-you" element={<ThankYou />} />





        
        <Route
          path="*"
          element={
             <NotFound />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
