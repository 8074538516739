import React,{useState,useEffect} from "react";
import {motion} from "framer-motion";
import Triangle from "../Asset/Animation/triangle.json";
import Loader from "../Components/Loader";
import Forms from "../Components/Forms";
import Header from "../Components/Header";
import { Helmet } from "react-helmet-async";
function ContactPage() {
  const [animation ,setAnimation] = useState("")
  const  apiGetAnimationJson = async ()=>{
    console.timeStamp("start")
      const response = await fetch("https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728320/lotties%20Json%20Files/nyc%20Home%20page%20lotties/contactAnimation_htheqt.json")
      const jsonData  = await response.json()
      setAnimation(jsonData)
  }

  useEffect(() => {
    apiGetAnimationJson()
  }, [])


  return (
    <>
    <Header />
    <div className="min-h-screen w-full flex justify-center items-center overflow-hidden relative p-4 lm:p-5">
    <Helmet>
        <title>Contact Us | NYC Pro Web Designers | NY 10036</title>
        <meta
          name="description"
          content="Get in Touch | NYC Pro Web Designers in NY 10036 - Your connection to exceptional web solutions in the heart of NYC"
        />
        <link href="https://nycprowebdesigners.com/contact" rel="canonical"></link>

      </Helmet>
        <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -bottom-5 right-0 hidden md:block "
        initial={{ y: "50vh", visibility: "hidden",opacity:0 }}
        animate={{ y: 0, visibility: "visible",opacity:1 }}
        transition={{
          visibility: "show",
          duration: "2",
        }}
      />

      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -top-5  -left-[50px] hidden md:block "
        initial={{ x: "-50vw", visibility: "hidden",opacity:0 }}
        animate={{ x: 0, visibility: "visible",opacity:1 }}
        transition={{
          duration: "2",
        }}
      />
      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -bottom-5
         left-0 hidden md:block"
        initial={{ x: "-50vw", visibility: "hidden",opacity:0 }}
        animate={{ x: 0, visibility: "visible",opacity:1 }}
        transition={{
          duration: "2",
        }}
      />
        {/* Triangle lotties animation */}
      <motion.div 
       initial={{ opacity: 0 }}
       animate={{ opacity: 1 }}
       transition={{ duration: 5, easings: "easeInOut", delay: 1 }}

      className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

      <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
      
      className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block">
        <Loader width={"100%"} height={"100%"} data={Triangle} />
      </motion.div>

        {/*  */}
      <div className="container  gap-x-16 flex p-0 h-full justify-center items-center  flex-col sm:flex-row-reverse     ">
        
        
        <motion.div       
        className="sm:w-[50%] h-full flex justify-center">
           {
            animation?
            <>
              <motion.div
            initial={{ scale: 0, opacity: 0,height:"50vh" }}
            animate={{ scale: 1, opacity: 1,height:"100%" }}
            transition={{ duration: 1 }}
            >
          <Loader width={"100%"} height={"100%"} data={animation} />
          </motion.div>
          </>
          :<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          }
        </motion.div>
        <div className="sm:w-[50%] w-full h-full flex flex-col justify-center gap-y-3  ">
        
          <motion.h2
          initial={{x:"-50vw" }}
          animate={{x:0}}
          transition={{ duration: 1 }}
  
            className="text-[#e14242] mb-4  font-bold  xsm:text-[40px] mm:text-[50px] mm:leading-[50px] md:text-[54px] lg:text-[72px] lg:leading-[72px]  xl:text-[85px] xl:leading-[85px] uppercase text-left "
          >
            Contact us
          </motion.h2>
          <motion.div
          initial={{y:"50vh" }}
          animate={{y:0}}
          transition={{ duration: 1 }}
          >
            <Forms />
          </motion.div>
        </div>
      </div>
    </div>
    </>

  );
}

export default ContactPage;
