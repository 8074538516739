import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import WhatWeDo from "./WhatWeDo";
import Testimonial from "./Testimonial";
import QuestionSection from "./QuestionSection";
import Footer from "./Footer";
import ServicesSection from "./ServicesSection";
import OurPortfolio from "./OurPortfolio";
import { Helmet } from 'react-helmet-async';


function Home() {
  // let tit = document.getElementsByTagName("title")[0].textContent = "GO"
  // console.log(tit)
  // useEffect(()=>[
  // let how = document.getElementsByTagName("title")
  // ])

  return (
    <>
<Helmet prioritizeSeoTags={true}>
<title>Software Development Solutions | NYC Pro Web Designers</title>
<meta   name="description"   content="Expert NYC Pro Web Designers provide top-tier Software Development Solutions.Elevate your digital presence today"
    />
<link href="https://nycprowebdesigners.com/" rel="canonical"></link> 
 <link href="https://nycprowebdesigners.com/" rel="home"></link>
</Helmet>

      <HeroSection />
      <WhatWeDo />
      <ServicesSection />
      <OurPortfolio />
      <Testimonial />
      <QuestionSection />
      <Footer />
    </>
  );
}

export default Home;
