import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";
function Magento() {
    const data ={
        title: `Magento`,
        description:"Looking to uplift your online store's performance? Your search ends here! Our Magento development services are expertly crafted to enrich your e-commerce platform and drive its success. Our team excels in crafting personalized Magento solutions that perfectly align with your distinct business needs.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/magento_r1hi9c.json",
        head:{
          title:"Magento Services | NYC Pro Web Designers | NY 10036",
          description:"Enhance your online store with our Magento Services. We specialize in making your  e-commerce site even better",
          canonical:`<link href="https://nycprowebdesigners.com/magento-services" rel="canonical">`
        }
    }

    const faqs =[
      {
          question: '1. Reasons to Prefer Magento Over WooCommerce',
          answer:`<p>Magento, a favored choice over WooCommerce, stands out with its strong scalability, advanced customization, and potent features tailored for sizable enterprises.</p>`
      },
      {
          question:"2. How will it be expanding Functionality in Our Marketplace Solutions",
          answer:`<p>
          We continually enhance our marketplace solutions, ensuring a smooth, user-friendly experience for buyers and sellers alike.
          <p>
          `
      },
      {
        question: '3. What would be the involvement in Large-Scale Enterprise Project Creation',
        answer:`<p>With extensive expertise in crafting large-scale enterprise projects, we ensure bespoke solutions align with your business needs.</p>`
    },
    {
      question: '4. What is the Engagement in Magento Mobile Application Crafting',
      answer:`<p>Our involvement extends to shaping Magento mobile apps, enabling businesses to engage customers on mobile devices, and offering convenient shopping.
      </p>`
    }
    
  ]
  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Expert Magento Development Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Are you prepared to explore the fascinating Magento programming world? It's like creating an awesome online virtual store! 
            <br />
            Imagine having a fantastic toolbox full of tips and resources to build the best online stores ever.

            <br />
            Consider Magento specialists to be the experts who use these tools to create an awesome-looking and user-friendly online store. They may add color to your website, elegantly arrange your products, and even guarantee that your consumers have an incredibly simple time shopping.
            <br />
            Magento developers construct distinctive online businesses using unique codes and designs, much like a chef produces a wonderful pizza using a secret formula. They see to it that everything goes without a hitch so that your customers can shop in a happy mood.
          </p>

          <ul>
            <li>
              <strong>Services for Magento Development: </strong>
            </li>
            <p className="text-justify text-lg">
            Our seasoned developers are specialists in Magento development, offering a comprehensive array of services that optimize your virtual store. From customizing themes to developing modules, we have your needs covered.
            </p>

            <li>
              <strong>Services for Magento E-commerce Development: </strong>
            </li>

            <p className="text-justify text-lg">
            We grasp the significance of a smooth and user-friendly e-commerce journey. Our focus in Magento e-commerce development revolves around creating an attractive and intuitive interface that spurs conversions and amplifies sales.
            </p>
            <li>
              <strong>Unparallel shopping experience: </strong>
            </li>
            <p className="text-justify text-lg">
            Stand out from competitors with our tailor-made Magento development answers. Every facet of your online store is uniquely shaped to mirror your brand identity and offer an unparalleled shopping experience for your patrons.
            </p>

            <li>
              <strong>Adept Magento Development Agency: </strong>
            </li>
            <p className="text-justify text-lg">
            As a leading agency in Magento development, our track record speaks for itself. Marrying technical prowess with a profound comprehension of e-commerce trends, we guarantee your online store shines brightly in the digital domain.
            </p>

            <li>
              <strong>Custom Modules: </strong>
            </li>
            <p className="text-justify text-lg">
            Through our personalized Magento development services, we surpass standard features and functionalities. Crafting custom modules, extensions, and integrations, we address your precise business requirements and deliver a seamless virtual shopping experience.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Comprehensive Life Cycle Strategy
          </h2>
          <br />
          <p className="text-justify">
          We grasp the significance of a well-organized Magento development life cycle. 
            <br />
            Below, we outline our approach:
          </p>
          <ul>
            <li>
              <strong>Collecting Information & Analyzing Data: </strong>
            </li>
            <p className="text-justify text-lg">
            Our journey commences by comprehending your business objectives, target audience, and specific needs. Our experts perform in-depth research and analysis to accumulate all the necessary details for a successful Magento development venture.
            </p>

            <li>
              <strong>
              Planning and Outlining Magento Software: 
              </strong>
            </li>

            <p className="text-justify text-lg">
            Once we possess a clear grasp of your requisites, we advance to strategizing the development process. We draft a meticulous roadmap, encompassing schedules, milestones, and deliverables. Additionally, our team constructs preliminary layouts to visualize the user interface and arrangement of your Magento software.
            </p>
            <li>
              <strong>
              Crafting a Prototype and Design for Magento Software: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Having concluded the planning stage, we progress to formulating a prototype of your Magento software. This offers you a sneak peek of the final product and the opportunity to provide input for any needed refinements. Our designers work closely with you to ensure the visual allure and user-friendliness of your Magento software.
            </p>

            <li>
              <strong>
              Developing and Executing Magento Software: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Once the prototype receives approval, our developers initiate the actual development phase. Leveraging their expertise in Magento development, they breathe life into your software. We adhere to industry best practices and coding standards, guaranteeing a robust and expandable Magento solution.
            </p>

            <li>
              <strong>
              Testing and Receiving Feedback on Magento Software: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Ensuring quality is pivotal in our Magento development life cycle. Our dedicated testing unit subjects the software to thorough testing to uncover and resolve any glitches or concerns. Your input is welcomed during testing to ensure the outcome aligns with your expectations.
            </p>

            <li>
              <strong>
              Launching and Sustaining Magento Software: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Post exhaustive testing and your endorsement, we proceed to deploy your Magento software to the live environment. Our team offers ongoing upkeep and assistance to uphold the up-to-date and optimal functionality of your Magento software.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Ideal Solution For Your E-Commerce Requirements
          </h2>
          <br />
          <p className="text-justify">
          We specialize in crafting e-commerce solutions; we strongly believe that Magento stands as the ultimate option for developing your online store. 
          <br />
          Here's why:
          </p>
          <ul>
            <li>
              <strong>
              Construct an Expandable Shop: 
              </strong>
            </li>
            <p>
            With Magento, you can establish an expandable digital store that grows as your business flourishes. Whether starting small or eyeing growth, Magento offers the flexibility to adapt to your evolving needs.

            </p>
            <li>
              <strong>Develop a Sturdy Framework: </strong>
            </li>
            <p>
            Magento provides a robust framework that guarantees stability and efficiency for your online store. It adeptly handles heavy visitor traffic and intricate operations, ensuring a smooth shopping journey for your customers.
            </p>
            <li>
              <strong>Manage Multiple Storefronts: </strong>
            </li>
            <p>
            If you handle multiple brands or aim to capture various markets, Magento lets you oversee multiple storefronts via a single admin panel. This streamlines your processes and allows effective catering to diverse audiences.
            </p>
            <li>
              <strong>Utilize Adaptable APIs:</strong>
            </li>
            <p>
            Magento presents a wide array of flexible APIs, permitting seamless integration of your online store with various external systems and services. This lifts your store's capabilities and offers an uninterrupted experience for your customers.
            </p>

            <li>
              <strong>Reach Omnichannel Audiences: </strong>
            </li>
            <p>
            In today's digital world, establishing an omnichannel presence is pivotal. Magento empowers you to effortlessly vend across numerous channels such as the web, mobile, and social media. This widens your reach and maximizes sales potential.
            </p>


            <li>
              <strong>Harness Pre-built Extensions:</strong>
            </li>
            <p>
            With a vast marketplace of pre-built extensions, Magento offers boundless options for tailoring. You can amplify your store's functionality, introduce novel features, and customize it to align with your specific business requirements.
            </p>

            <li>
              <strong>Open-Source Platform: </strong>
            </li>
            <p>
            Magento operates as an open-source platform, translating to flexibility, autonomy, and cost-effectiveness. You possess full control over your digital store and can tap into the support of a lively community of developers and users.
            </p>

            <li>
              <strong>Swift Loading: </strong>
            </li>
            <p>
            Speed is paramount in the online sphere. Magento is designed for swift loading, ensuring rapid display of your digital store and delivering an uninterrupted user experience. This aids in curtailing bounce rates and boosting customer contentment.
            </p>

            <li>
              <strong>Diverse Payment Options:</strong>
            </li>
            <p>
            Magento accommodates a broad spectrum of payment methods, allowing your customers to choose their favored payment avenues. This flexibility lifts the checkout experience and amplifies customer satisfaction.
            <br />
            Select Magento for your online store development today!
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Choose Us?
          </h2>
          <br />
          <p className="text-justify">
          We have mastery in e-commerce development, we firmly believe that Magento stands as the ideal choice for crafting your online store. 
          <br />
          Here's why:
          </p>
          <ul>
            <li>
              <strong>Versatile Tool: </strong>
            </li>
            <p>
            It functions as a potent versatile tool that empowers you to effortlessly establish and manage your online store. Covering everything from handling products to processing orders, Magento equips you with all the necessary utilities for running a successful e-commerce venture.

            </p>
            <li>
              <strong>Heighten Website Performance: </strong>
            </li>
            <p>
            With Magento, you can ensure optimal performance for your online store. The platform is expertly designed to maximize website performance, leading to swifter loading times and a seamless shopping experience that your customers will relish.
            </p>
            <li>
              <strong>Efficient Index Management:</strong>
            </li>
            <p>
            Magento offers advanced indexing capabilities, allowing you to efficiently oversee and update your store's data. 
            </p>

            <li>
              <strong>Potent Search Capabilities: </strong>
            </li>
            <p>
            In the swiftly moving online landscape of today, having robust search functionalities is pivotal. Magento provides potent search capabilities, enabling your customers to swiftly and effortlessly discover products. 
            </p>

            <li>
              <strong>Image Enhancement:  </strong>
            </li>
            <p>
            Visual allure holds paramount importance for any online store. Magento incorporates built-in image enhancement features, guaranteeing that your product images are showcased in optimal quality without compromising on swift loading times.
            </p>


            <li>
              <strong>Streamlined Inventory Control:  </strong>
            </li>
            <p>
            Managing inventory can prove intricate, but not with Magento. The platform presents comprehensive inventory management tools, permitting you to monitor stock levels, set up alerts for low inventory, and efficiently handle multiple warehouses.
            </p>
            <li>
              <strong>Versatile Shipping Options:  </strong>
            </li>
            <p>
            Furnishing adaptable shipping choices holds paramount importance for customer satisfaction. Magento supports a variety of shipping methods, allowing you to offer diverse shipping options based on customer preferences, location, and order specifics.
            </p>

            <li>
              <strong>Design And Themes:</strong>
            </li>
            <p>
            Set yourself apart from competitors with appealing, visually striking designs and themes that mirror your brand's identity and captivate your patrons.
            </p>
            <li>
              <strong>Security Assurance: </strong>
            </li>
            <p>
            Safeguard your online store and customer data with robust security measures. Our Magento experts implement industry-leading security practices, shielding your business from potential threats.
            </p>
            <li>
              <strong>Intuitive UX Design And Checkout: </strong>
            </li>
            <p>
            Deliver an intuitive and user-friendly encounter with our UX design and checkout optimization services. Simplify the purchase process and curtail cart abandonment rates.
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default Magento