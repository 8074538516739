import React from "react";
import {
  motion,
} from "framer-motion";
// import OopsPage from "../Asset/Images/OopsPage.webp";
import SimpleButton from "../Components/SimpleButton";
// import HalfCircle from "../Asset/Images/halfcircle.webp";


// import social icons
import Fb from "../Asset/icons/fb.webp"
import Insta from "../Asset/icons/insta.webp"
import Email from "../Asset/icons/email.webp"
import Twitter from "../Asset/icons/twitter.webp"



// import { Button } from 'antd'

function ServicesPage() {
  return (
    <div className=" min-h-screen w-full flex justify-center items-center relative p-4 lm:p-5 overflow-hidden">
      <motion.img
      title="Page Not Found"
      loading="eager"
        initial={{ y: "-10vh",opacity:0 }}
        animate={{ y: 0,opacity:1 }}
        transition={{ duration: 1 }}
        src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726679/ncy%20Home%20Images/halfcircle_ul2eo9.webp"
        alt="Page Could Not Found"
        className="absolute top-0 w-[50%] right-[30%]  md:w-[30%] md:right-[50%]"
      />

      {/* main contant div */}
      <div
        className={`container  gap-x-4 flex p-0 h-full justify-center items-center  flex-col sm:flex-row`}
      >
        {/* image*/}
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          className="sm:w-[50%] h-full"
        >
          <img  loading="eager" title="NYCPROWEBDESIGNERS NOTFOUND" src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726679/ncy%20Home%20Images/OopsPage_h2cvok.webp" alt="PAGE NOT FOUND" />
        </motion.div>

        {/* Text information Div */}
        <div
          className={`sm:w-[50%]  h-full flex flex-col justify-center gap-y-3  items-center  mt-3 `}
        >
          <motion.h2
            initial={{ x: "50vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            // exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 1 }}
            className={`text-[#deb769] font-bold whitespace-pre-line text-[40px] leading-[40px] md:text-[47px]   lg:text-[70px] lg:leading-[70px] xl:text-[100px] xl:leading-[100px] `}
          >
            ERROR
          </motion.h2>
          <motion.h2
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
            className={`text-[#e14242] font-bold whitespace-pre-line text-[70px] leading-[70px]    md:text-[80px] md:leading-[80px] lg:text-[130px] lg:leading-[130px]  xl:text-[160px] xl:leading-[160px]    uppercase text-left  `}
          >
            404
          </motion.h2>
          <motion.p
            initial={{ y: 150, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-center font-semibold sm:text-lg md:text-xl "
          >
            OOPS..SORRY WE COULDN’T FIND YOUR PAGE
          </motion.p>

          <motion.div
            initial={{ y: 240, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <SimpleButton
              title={"Back Home"}
              bg={"bg-[#e14242]"}
              color={"text-white"}
              hover={"hover:bg-white hover:text-black"}
              to={"/"}
            />
          </motion.div>

        {/* Social Icons */}
          <div className="flex justify-between gap-x-5 mt-3 h-[8px]" >

            <motion.a
            initial={{y:100 ,opacity:0}}
            animate={{y:0,opacity:1}}
            transition={
              {delay:0.3, duration:1}
            }
             href="https://www.instagram.com/nycprowebdesigners1/" target="_blank">
            <img loading="eager" title="instagram" src={Insta} alt="insta"  />
            </motion.a>

            <motion.a 
            initial={{y:100 ,opacity:0}}
            animate={{y:0,opacity:1}}
            transition={
              {delay:0.5, duration:1.2}
            }
            href="https://www.facebook.com/people/NYC-Web-Pro-Designers/100091137777717/" target="_blank">

            <img loading="eager" title="facebook" src={Fb} alt="facebook" />
            </motion.a>

            <motion.a
              initial={{y:100 ,opacity:0}}
              animate={{y:0,opacity:1}}
              transition={
                {delay:0.7, duration:1.3}
              }
            
            href="mailto:nycprowebdesigner.com" target="_blank" >

            <img loading="eager" title="email" src={Email} alt="Email" />
            </motion.a>
            <motion.a 
              initial={{y:100 ,opacity:0}}
              animate={{y:0,opacity:1}}
              transition={
                {delay:0.9, duration:1.4}
              }
            href="https://twitter.com/i/flow/login?redirect_after_login=%2Fnyc_developer" target="_blank" >
            <img loading="eager" title="twitter" src={Twitter} alt="twitter" />
            </motion.a>
          </div>


        </div>
      </div>
    </div>
  );
}

export default ServicesPage;
