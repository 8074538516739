import React from 'react'

export default function TermsAndConditions() {
  return (
    <div className='container mx-auto font-heading my-3 ' >
        <h2 className='text-center text-5xl'>
        TERMS & CONDITIONS
        </h2>
        <h2 className='text-2xl  font-semibold  '>
        REVISION POLICY
        </h2>
        <br />
        <p className='text-justify'>
        We provide revision depending upon the package you selected. Customers can ask us for unlimited free revisions and we will revise their design without any additional charges provided that the design and concept remains the same. Revision Turnaround Time would be 48-72 hours.
        </p>

        <h2 className='text-2xl  font-semibold  '>
        TURN AROUND TIME
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners has 48-72 hours turnaround time for most design services. This is not the complete time from start to finish for a project, only the turnaround time for a single iteration of a design stage. Depending on the kind of service the time required for revisions could be a minimum of 48 hours to 72 hours, this time means 2 working days, For example:
        </p>
        <br />
        <p className='text-justify'>
        Logo Orders placed or Revisions Requested on a Monday will be delivered by Wednesday. <br />
     Logo Orders placed or Revisions Requested on a Friday will be delivered by end of day Tuesday.
        </p>


        <h2 className='text-2xl  font-semibold  '>
        REFUND POLICY / MONEY BACK GUARANTEE
        </h2>
        <br />
        <p className='text-justify'>
        In any event, any deposited funds for a project shall not be subject to refund after delivery if the initial design concepts are approved, or a change is requested unless NYCProWebDesigners cancels or terminates your Contract for a reason other than your breach or non-performance.
        </p>
        <p className='text-justify'>
        All refund requests will be fulfilled as per the following arrangement:
 You make a request when the initial concepts for a logo are offered. However once you approve or request changes in the initial designs, the refund offer becomes void and refund request will not be entertained.
        </p>
        <br />

        <p className='text-justify'>
        If request for refund is made before the delivery of initial design concepts, then you would be eligible for Full Refund (less 10% service & processing fee).
        </p>
        <p className='text-justify'>
        If request for refund is made within 48 hours, you would be eligible for 66% refund (less 10% service & processing fee).
        </p>
        <p className='text-justify'>
        If request for refund is made between 48- 120 hours of the initial design delivery, you would be eligible 33% refund (less 10% service & processing fee).
        </p>
        <p className='text-justify'>
        No refund request will be entertained after 120 hours of your initial design delivery, however since we believe in 100% customer satisfaction you`re encouraged to contact us in case of any concern.
        </p>
        <p className='text-justify'>
        No refund request will be entertained if you have not taken any action on your order for 30 days after placing your order. However, if you want to reactivate your design order, you will be charged a certain fee depending on your project.
        </p>

        <p className='text-justify'>
        No refund requests will be entertained after the final files have been delivered.
        </p>

        <p className='text-justify'>
        For website packages no refund will be entertained once website development has been completed or once the website has been deployed live.
        </p>

        <p className='text-justify'>
        For video animation packages no refund request will be entertained after the designing of the storyboard.
        </p>
        <p className='text-justify'>
        All refund requests should be communicated to the support department. NYCProWebDesigners, based on the violation of your user agreement reserves the right to approve/disapprove your request on an individual case to case basis.
        </p>
        <p className='text-justify'>
        For NYCProWebDesigners / Custom packages, refund will be applicable the same as it is on the single packages.
        </p>


        <p className='text-justify'>
        For example, if you order logo and web design service and approve the logo, you can claim refund for the website service at the time of initial design only.
        </p>

        <p className='text-justify'>
        A refund request will need to have a valid reason which must be qualified against the design brief and customer feedback for revisions. Unless a concept has not been designed according to the brief, a refund will not be given however further revisions will be provided until complete satisfaction.
        </p>


        <p className='text-justify'>
        Money back guarantee is based on that the order is placed in good faith. Where a customer has placed design orders with more than one design agency for the same job with the intention to claim refund, we do not consider it a good faith. In such a case we reserve the right to decline a refund request.
        </p>

        <p className='text-justify'>
        All design jobs require customer feedback before finalizing the design therefore it is only fair that the customer gets involved and provides feedback in order to get the desired results.
        </p>

        <p className='text-justify'>
        100% unique design guarantee entitles you to a re-draw if our designed logo is to be found considerably similar to another logo design that may already exist. Any resemblance to an existing design will be merely a coincidence and NYCProWebDesigners will not accept any responsibility or claim of any compensation in such a case. It is the client's responsibility to get their art work copyrighted.
        </p>
        <p className='text-justify'>
        Note: NYCProWebDesigners hold all the rights to reject any project or cancel the contract whenever it deems necessary. After the refund, you will not have any rights to use the designs for any purpose; they will be the sole property of NYCProWebDesigners. The company will be the rightful owner of the desi
        </p>
        <p className='text-justify'>
        Services not related to design services are not eligible for refunds under any circumstances. They are not covered under our 100% money back guarantee. If such a service is acquired as part of a Package Deal of any kind, they cannot be used to request a refund on the entire package. Examples of such services are as follows:
        </p>
        <br />
         <ul>
            <li>
            • Website & Emails Hosting Services.
            </li>
            <li>
            • Website SSL Certificates
            </li>
            <li>
            • Website Domain Purchase
            </li>
            <li>
            • Website Maintenance Plans.
            </li>
            <li>
            • Website SEO Plans.
            </li>
            <li>
            • Social Media Marketing
            </li>
            <li>
            •Google Adwords Campaigns
            </li>
         </ul>
         <br />

         <p className='text-justify'>
         For services that are not covered under the 100% refund policy in case of dissatisfaction the NYCProWebDesigners management after review the project may choose to offer a partial refund to the client.
        </p>


        <h2 className='text-2xl  font-semibold  '>
        HOW TO CLAIM YOUR REFUND
        </h2>

        <p className='text-justify'>
        To assure your refund request is approved, please make sure you meet the following requirements
        </p>
        <p className='text-justify'>
        • Claim your refund specifying your concern by contacting us via any of the following:
o info@nycprowebdesigners.com
        </p>
        <p className='text-justify'>
        • We will try to resolve your concern by virtue of our revision policy immediately or else will email you a refund request approval from our refund department. After the refund, your design rights would be obtained by nycprowebdesigners and you would not be able to display any version of the design sent by company. Let us also specify that:
        </p>
        <p className='text-justify'>
        • Since the design rights would now be transferred to the company, you agree that you will have no right (direct or indirect) to use any response or other content, work product or media, nor will you have any ownership interest in or to the same.
        </p>
        <p className='text-justify'>
        • Working in collaboration with the Government Copyright Agencies nycprowebdesigners would share Copyright Acquisition information for the refunded designs that would restrict the re-use of the designs as original designs in the future. If you have any questions or concerns about our Refund Policy, please contact us by clicking here <a href="mailto:info@nycprowebdesigners.com"> info@nycprowebdesigners.com
            </a>
        </p>

        <h2 className='text-2xl  font-semibold  '>
        MY ACCOUNT
        </h2>
        <br />
        <p className='text-justify'>
        The My Account area is a convenient way to communicate. It is your sole responsibility to check the account area to address any queries, concerns, or additional instructions required by the designer. Not checking or using the Account Area frequently shall not provide you adequate grounds for a refund. However, if you are uncertain how to use the area, you may contact the customer support team at any time for assistance.
        </p>

        <h2 className='text-2xl  font-semibold  '>
        COMMUNICATION POLICY
        </h2>
        <br />
        <p className='text-justify'>
        Emails Sent back and forth through official emails that is "Info@nycprowebdesigners.com" are to be only considered forms of valid communication.
        We are not responsible for any damages, issues or delays caused due to contacts made through means of communication not provided by us.
        We take full responsibility of all the information provided through our official domains.
        </p>


        <h2 className='text-2xl  font-semibold  '>
        QUALITY ASSURANCE POLICY
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners will do its best to meet your requirements and our designers do their best to fulfill your expectations. We believe in providing best designs and each of our designs is well researched and well crafted.
        </p>


        <h2 className='text-2xl  font-semibold  '>
        100% SATISFACTION GUARANTEE
        </h2>
        <br />
        <p className='text-justify'>
         Our unlimited revisions policy is to make sure that you are 100% satisfied.
 We aim at exceeding your expectations and strive to accomplish it.
 We do not stop our revisions until you are completely satisfied with your design (number of revisions will be according to your package).
        </p>


        <h2 className='text-2xl  font-semibold  '>
        100% SATISFACTION GUARANTEE
        </h2>
        <br />
        <p className='text-justify'>
        The complete order will be sent to the mentioned account on the date stated on Order Confirmation as well as a confirmation email will also be sent.
 The turnaround time will be according to the package, the minimum time required is 2 business days.
 Websites will only be made live once all dues have been cleared. Under no condition a website will be made live if there are any kind of dues outstanding.
 In case of an urgent or rush order, contact our customer support team.
        </p>


        <h2 className='text-2xl  font-semibold  '>
        DESIGN OWNERSHIP & RIGHTS
        </h2>
        <br />
        <p className='text-justify'>
        All finalized Designs provided by NYCProWebDesigners are done so on a written request of finalization by the client and upon finalization the client receives all the final core files of the logo and owns 100% of all rights of ownership to the said logo..
        </p>

        <p className='text-justify'>
         Client shall own the final the Final Design provided by NYCProWebDesigners in all its entirety.
 All ownership rights of the said finalised design will belong to the client 100%.
 The Client is free to use the finalised design as they see fit.
 Client shall not, however, own any materials, media or other content generated during any revision cycles leading up to the Final Product.
        </p>
       


        <h2 className='text-2xl  font-semibold  '>
        CONTENT CREATION & POPULATION
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners will not be responsible for creating or populating content (e.g. text/Images) into the website, unless purchased exclusively otherwise. In which case:
        </p>

        <ul>
            <li>
            • NYCProWebDesigners will populate the website with content created for the client
            </li>

            <li>
            • The content/text for the website will be created specifically for the customer's website.
            </li>

            <li>
            • NYCProWebDesigners will populate the website with images.
            </li>

            <li>
            • NYCProWebDesigners will acquire stock imagery for client from stock photo websites.
            </li>

            <li>
            • Images will be purchased upon approval from the client.
            </li>

            <li>
            • NYCProWebDesigners will not be responsible in any way shape or form, for any similarities between images used on the site that may or may not resemble other websites.
            </li>

            <li>
            • NYCProWebDesigners will populate the website with products and product details.
            </li>


            <li>
            • Product images and product details will be provided by the client.
            </li>
        </ul>


        <h2 className='text-2xl  font-semibold  '>
        N.D.A. & REPUTATION MANGEMENT POLICY
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners, does not share the customer information with regarding their project or contact details with anyone. The client's person and project information is kept confidential at all points.
        </p>

        <br />
        <p className='text-justify'>
        Once the project is completed successfully. Both parties NYCProWebDesigners and The Client agree not to intentionally defame or otherwise disparage the other with respect to matters arising after project completion.
        </p>
        <br />

        <p className='text-justify'>
        Once the project is completed successfully. Each Party agrees to refrain from (and NYCProWebDesigners shall take reasonable steps to cause its officers and directors to refrain from), either directly or indirectly, hereafter making any defamatory comments of any type or nature whatsoever to anyone about the Client (and in the case of the Company, its employees, officers, directors, agents, consultants, affiliates, investors or business partners).
        </p>
        <br />

        <h2 className='text-2xl  font-semibold  '>
        RECORD MAINTENANCE
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners keeps the records of finalized designs so that in case of any misplaced order, you will be provided the exact file.
        </p>

        <h2 className='text-2xl  font-semibold  '>
        CUSTOMER SUPPORT
        </h2>
        <br />
        <p className='text-justify'>
        Our Customer Support Team is present in the office Monday - Friday 9 AM to 5 PM PST to answer all of your concerns and queries over the phone. All other queries after business hours need to be emailed through the Team Works Account section by the customer and will be picked up the next working day.
        </p>

        <h2 className='text-2xl  font-semibold  '>
        TECHNICAL SUPPORT POLICY
        </h2>
        <br />
        <p className='text-justify'>
        NYCProWebDesigners is primarily a design service and design consultancy provider. We will design and develop the services that the client needs. On occasions our packages and services purchased will include technical aspects to help the client with their websites and emails. NYCProWebDesigners will provide the client with the basic information regarding those services and the settings required to make those services works. NYCProWebDesigners will not be responsible for providing detailed trainings or installations of said services on the Customer's end. Such services may include but not limited to:
        </p>
        <br />
        <ul>
            <li>
            • Website Deployment
            </li>

            <li>
            • NYCProWebDesigners will deploy the website for the customer on
            </li>

            <li>
            • A Hosting server provided by us.
            </li>

            <li>
            • Hosting Service provided by NYCProWebDesigners is a free service provided to the client as an added feature without any cost. It is acquired from a Third Party Service provider
            </li>

            <li>
            • A Hosting server provided by the customer (purchased from a Third Party)
            </li>

            <li>
            • In the event the website is hosted on third party webserver purchased by the customer, NYCProWebDesigners will not be held responsible for any kind of server or hosting related issues.
            </li>

        </ul>
        <p className='text-justify'>
        There is no Service Level Agreement (SLA) between client and NYCProWebDesigners in terms of technical assistance or service outage, unless purchased separately as an ongoing maintenance service.
        </p>

        <ul>
<li>
• Website Content Management System (CMS: Content Management System)
</li>
<li>
• NYCProWebDesigners will provide the customer with a user manual to use the Website CMS (Content Management System).
</li>
<li>
• NYCProWebDesigners will not be responsible for training the client in the said Website CMS (Content Management System).
</li>
<li>
• Emails Setup
</li>
<li>
• NYCProWebDesigners will be providing the customer with the necessary settings and information to install the emails on their end.
</li>
<li>
• NYCProWebDesigners will not be responsible for installing said emails onto the customer end.
</li>
<li>
• NYCProWebDesigners will not be responsible for providing technical support or assistance in troubleshooting errors on email usage as all our emails are on open servers and accessible by all service providers.
</li>
  </ul>



  <p className='text-justify'>
  NYCProWebDesigners will not be held responsible in any way for customer's failure to execute, install or learn the usage of above said services on their end, as the responsibilities for those lie on the customer's end.
        </p>







        
        </div>
  )
}
