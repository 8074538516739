import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function PromotionalVideos() {
    const data ={
        title: `Promotional\n Videos`,
        description:"Your premier destination for cutting-edge promotional video production services. With a proven track record of excellence and a passion for technology, we are your trusted partner in crafting compelling visual narratives that captivate audiences and drive results. Whether you're a startup looking to make a splash or an established brand seeking to reinvent your image, our experienced team is here to transform your ideas into stunning promotional videos that leave a lasting impact. ",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712600/lotties%20Json%20Files/promotionalVideo_ng6a90.json",
        head:{
          title:"Promotional Video | NYC Pro Web Designers | NY 10036",
          description:"NYC's top web designers create compelling promotional videos to boost your brand effectively in NY 10036",
          canonical:`<link href="https://nycprowebdesigners.com/promotional-videos" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. What ingredients go into making a promotional video that really grabs people's attention?",
        answer: `<p>Crafting an attention-grabbing promotional video boils down to having a crystal-clear message, visuals that leave a lasting impression, and a compelling call to action. Plus, it should speak directly to your audience's heart and soul, reflecting your brand's identity in a way that sticks.</p>`,
      },
      {
        question: "2. Is there a sweet spot for the length of a social media promo video, or is it all about going with the flow?",
        answer: `<p>When it comes to social media, the magic video length can vary by platform. But as a general rule, keeping it under 60 seconds often works like a charm. However, don't forget that the story you're telling and the context should be your guiding stars when it comes to duration.</p>`,
      },
      {
        question: "3. Are promotional videos exclusively for big businesses, or can the little guys benefit too?",
        answer:
          "<p>Good news! Promotional videos are the Swiss army knives of marketing. They're not just for the corporate giants. Whether you're a cozy neighborhood store or a global player, if you want to dazzle with your products, services, or brand tale and connect with your audience online, promotional videos are your friends.</p>",
      },
      {
        question: "4. Do I have to break the bank on fancy equipment to create a standout promo video?",
        answer:
          "<p>While it's true that fancy equipment can sprinkle some stardust on your video's quality, it's not a must. Many smartphones pack a punch in the camera department, and there are plenty of user-friendly, budget-friendly video editing tools out there. So, you can create cinematic magic without maxing out your credit card.</p>",
      },
      {
        question: "5. How can I tell if my promo video is hitting the bullseye with my audience?",
        answer:"<p>To gauge the success of your promo video, you'll want to keep an eye on the scoreboard. Metrics like views, engagement (those likes, comments, and shares), click-through rates (CTR), and conversions are your MVPs. They'll reveal whether your video is a touchdown or needs a halftime pep talk, helping you fine-tune your strategy for future videos.</p>",
      },
    ];
  return (
  <ServicesPage data={data} >
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Expertise 
          </h2>
          <br />
          <p className="text-justify text-lg">
          We pride ourselves on our extensive expertise in promotional video production. Our talented team of creative professionals, videographers, editors, and scriptwriters collaborate seamlessly to bring your vision to life. From concept development to post-production, we cover every step of the video production process with meticulous attention to detail. 
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Tailored Solutions for Every Need 
          </h2>
          <br />
          <p className="text-justify">
          We understand that each project is unique, and that's why we offer a comprehensive range of promotional video production services that cater to various industries and objectives: 
          </p>
          <ul>
            <li>
              <strong>Brand Promotion:  &nbsp;</strong>
              Elevate your brand's visibility and recognition with engaging promotional videos that showcase your values, products, and services in a captivating and memorable way.
            </li>
            <li>
              <strong>Product Launch: &nbsp;</strong>
              Generate buzz and excitement around your latest offerings by unveiling them through dynamic and visually appealing product launch videos.
            </li>

            <li>
              <strong>Event Coverage: &nbsp;</strong>
              Capture the essence of your corporate events, conferences, or trade shows with high-quality video coverage that encapsulates the energy and key moments.
            </li>

            <li>
              <strong>Customer Testimonials: &nbsp;</strong>
              Build trust and credibility by featuring authentic customer testimonials that highlight real experiences and success stories.
            </li>

            <li>
              <strong>Explainer Videos:  &nbsp;</strong>
              Simplify complex concepts and processes with concise and informative explainer videos that resonate with your target audience.
            </li>

            <li>
              <strong>Social Media Campaigns:&nbsp;</strong>
              Make a splash on social media platforms with attention-grabbing short videos optimized for maximum engagement and shareability.
            </li>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Process 
          </h2>
          <br />
          <ul>
            <li>
              <strong>Consultation:  &nbsp;</strong>
              We kick off every project with a thorough consultation to understand your objectives, target audience, and unique selling points. This ensures that we align our creative approach with your goals.
            </li>
            <li>
              <strong>Concept Development: &nbsp;</strong>
              Our creative team brainstorms ideas and concepts that effectively convey your message and resonate with your audience.
            </li>

            <li>
              <strong>Scriptwriting: &nbsp;</strong>
              Crafting a compelling script is the foundation of any successful promotional video. Our skilled scriptwriters develop narratives that captivate, inform, and inspire.
            </li>

            <li>
              <strong>Storyboarding:  &nbsp;</strong>
              We visualize the script through detailed storyboards, outlining the visual elements and flow of the video.
            </li>

            <li>
              <strong>Production:  &nbsp;</strong>
              Our experienced videographers capture footage using state-of-the-art equipment, ensuring high-quality visuals and seamless execution.
            </li>

            <li>
              <strong>Editing and Post-Production: &nbsp;</strong>
              Our skilled editors bring the video to life by seamlessly weaving together footage, graphics, animations, and music to create a polished final product.
            </li>
            <li>
              <strong>Review and Revisions:  &nbsp;</strong>
              We value your input throughout the process, and revisions are welcomed to ensure the video aligns perfectly with your vision.
            </li>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Choose Us? 
          </h2>
          <br />
          <ul>
            <li>
              <strong>Expertise: &nbsp;</strong>
              With years of experience in the software solutions industry, we possess the technical know-how to understand and effectively convey your technology-related messages.
            </li>
            <li>
              <strong>Creativity: &nbsp;</strong>
              Our team of creative minds thrives on pushing boundaries and thinking outside the box to create videos that are both innovative and impactful.
            </li>

            <li>
              <strong>Quality: &nbsp;</strong>
              We are committed to delivering top-notch quality in every aspect of our service – from visuals to sound design – ensuring your promotional video stands out. 
            </li>

            <li>
              <strong>Client-Centric Approach: &nbsp;</strong>
              Your satisfaction is our priority. We collaborate closely with you, keeping you informed and involved at every stage of the process.
            </li>

            <li>
              <strong>Timely Delivery:   &nbsp;</strong>
              We understand the importance of deadlines and work diligently to deliver your promotional video on time and within budget. Elevate your brand and engage your audience with compelling promotional videos that showcase your excellence and commitment to technology. Contact us today to embark on a visual journey that sets you apart in the competitive landscape. Let's bring your ideas to life through the power of captivating storytelling and cutting-edge video production. 
            </li>
          </ul>
  
        </motion.div>
        <br />

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
  </ServicesPage>
  )
}

export default PromotionalVideos