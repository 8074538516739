import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function EducationalVideos() {

    const data ={
        title: `Educational\n Videos`,
        description:"Step on a journey into the world of educational videos, where we specialize in crafting amazing content that ignites curiosity and enriches the learning adventure. Explore the magic of our educational videos, meticulously designed to captivate students and transform learning into an enjoyable experience.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712597/lotties%20Json%20Files/education_phf3jl.json",
        head:{
          title:"Educational Videos | NYC Pro Web Designers | NY 10036",
          description:"Elevate your brand with informative educational videos skillfully crafted by NYC's leading web designers, promoting effective learning.",
          canonical:`<link href="https://nycprowebdesigners.com/educational-videos" rel="canonical">`
        } 
    }
    const faqs = [
      {
        question: "1. What Are the Uses of Educational Videos?",
        answer: `<p>Educational videos serve the purpose of enhancing learning by presenting educational content visually and engagingly. They are employed to explain complex ideas, demonstrate practical skills, provide step-by-step guides, deliver lectures, and encourage interactive learning.
        </p>`,
      },
      {
        question: "2. Can Educational Videos Be Tailored to Your Requirements",
        answer: `<p>We recognize that every educational institution or individual has unique needs. We offer the flexibility to customize educational videos to align with your precise requirements. Whether it's for online courses, training initiatives, promotional material, or any other educational objective, we can create videos that match your goals and represent your identity.</p>`,
      },
      {
        question: "3. What Types of Educational Videos Can We Develop?",
        answer:
          `<p>Our expertise extends to crafting a diverse array of educational videos, including, but not limited to:</p>
          <ul
          <li>Educational explainer videos</li>
          <li>Tutorial videos</li>
          <li>Lecture presentations</li>
          <li>Training videos</li>
          <li>Demonstrative guides</li>
          <li>Animated educational content</li>
          <li>Interactive learning videos</li>
          <li>Immersive virtual reality (VR) and augmented reality (AR) educational experiences</li>
          </ul>
          `,
      },
      {
        question: "4. How Long Does the Educational Video Production Process Take?",
        answer:
          "<p>The duration of the educational video production process varies based on project complexity, video length, and other factors. We collaborate closely with our clients to set timelines and ensure prompt delivery. Rest assured, we prioritize efficiency while upholding quality standards, striving to complete projects within reasonable timeframes.</p>",
      },
      {
        question: "5. What Are the Advantages of Educational Videos in the Field of Education?",
        answer:
          `<p>Educational videos offer a multitude of benefits in the education sector, including:</p>
          <ul>
          <li>
            <strong>
            Enhanced engagement:  &nbsp; 
            </strong>
            Videos capture attention and stimulate interest, leading to increased engagement and knowledge retention.
          </li>
         
          <li>
          <strong>
          AVisual learning:  &nbsp; 
          </strong>
          Visual content aids comprehension and knowledge retention, making intricate topics more accessible.
          </li>
          <li>
          <strong>
          Flexibility and accessibility:  &nbsp; 
          </strong>
          Educational videos can be accessed at any time and place, enabling learners to study at their convenience.
          </li>
          <li>
          <strong>
          Improved comprehension:  &nbsp; 
          </strong>
          Videos combine visuals, audio, and text, catering to diverse learning styles and promoting better understanding.
          </li>
          <li>
          <strong>
          Heightened interactivity: &nbsp; 
          </strong>
          Interactive elements within videos foster active learning, encouraging learners to participate and apply their knowledge.
          </li>
          <li>
          <strong>
          Scalability:  &nbsp; 
          </strong>
          Educational videos can reach a wide audience, making them a cost-effective and efficient means of disseminating information.
          </li>
          
        </ul>
          `,
      },
    ];
  return (
    <ServicesPage data={data} >
         <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Sparking Learning Curiosity with Educational Video Productionion
          </h2>
          <br />
          <p className="text-justify text-lg">
          Count on us as your trusted educational video production companion, dedicated to crafting high-quality videos that effectively convey your educational message. Immerse yourself in our comprehensive range of services, thoughtfully customized to cater to your unique requirements, resulting in educational videos that both engage and educate. Delve into the intricate process of our educational video production, where we breathe life into your ideas, fashioning impactful videos that deeply resonate with your audience.
            <br />
            Join us on an inspiring voyage of knowledge and exploration as we meticulously create compelling educational videos that serve as beacons of inspiration for learners, molding the future of education.   Experience the transformative influence of video in education, as we craft informative and engaging educational videos that empower learners and nurture a passion for learning.
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Three Ways To Achieve Engagement
          </h2>
          <ul>
            <li>
              <strong>
              Audience Engagement: 
              </strong>
            </li>
            <p>
            Our team of specialists understands the art of crafting content that seizes the audience's attention and keeps them engaged. Be it through compelling narratives, striking visuals, or interactive elements, we'll aid you in grabbing attention and maintaining viewer interest.
            </p>
            <li>
              <strong>Unique Brand Presentation:</strong>
            </li>
            <p>
            We firmly believe that each brand possesses a unique narrative waiting to be shared. Our creative team will collaborate closely with you to cultivate a distinct style that mirrors your brand's identity and principles. From attention-grabbing visuals to inventive design elements, we will ensure that your content sets you apart in the crowd.
            </p>

            <li>
              <strong>Content Mastery: </strong>
            </li>
            <p>
            Drawing from our profound knowledge and expertise in content creation, we will guide you in shaping a message that resonates with your target audience. Our content experts excel in conveying complex concepts clearly and concisely, guaranteeing that your message is comprehensible and unforgettable.
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Modules For Educational Videos:
          </h2>
          <br />
          <p className="text-justify">
          With our three primary areas of focus, we're fully committed to delivering outstanding educational content.
          </p>
          <ul>
            <li>
              <strong>
              Crafting Learning Adventures
              </strong>
            </li>
            <p>
            Our team of learning design maestros comprehends the significance of capturing learners' attention and inquisitiveness. Through our innovative approach, we fashion educational videos that ignite curiosity, promote active learning, and nurture students' growth. By seamlessly integrating interactive elements, attractive visuals, and real-world instances, we ensure that the learning experience is not only enjoyable but also transformational.
            </p>
            <li>
              <strong>Tailored Content Crafting: </strong>
            </li>
            <p>
            We firmly believe that content development forms the bedrock of effective educational videos. Our content development squad collaborates closely with subject matter experts to construct tailor-made lessons that align with curriculum objectives and cater to learners' needs. 
            </p>

            <li>
              <strong>Genuine Proficiency: </strong>
            </li>
            <p>
            The true strength of our team lies in our profound understanding of educational content development intricacies. With years of hands-on experience in the field, we possess the knowledge and skills required to create videos that empower education and kindle the flames of imagination. We stay abreast of the latest research and teaching methodologies to ensure that our educational videos are effective in imparting knowledge and fostering critical thinking. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Benefits Of Opting for Us
          </h2>
          <ul>
            <li>
              <strong>Broaden Access, Enhance Learning:</strong>
            </li>
            <p>
            Through the magic of educational video creation, we can widen the reach of education and enrich the learning journey.
            </p>
            <li>
              <strong>Simplifying Complexity:</strong>
            </li>

            <p>
            Unleashing the Power of Educational Videos to Clarify Complex Ideas. Discover how educational video production simplifies intricate concepts, making learning engaging and easy.
            </p>
            <li>
              <strong>Share, Inspire, Educate: </strong>
            </li>
            <p>
            The Impact of Easily Distributable Educational Content is unmatchable. Explore the perks of crafting effortlessly shareable educational videos that reach a broader audience and ignite a passion for learning.
            </p>
            <li>
              <strong>Concise, Capture, Communicate</strong>
            </li>
            <p>
            Enhancing Comprehension with Educational Video Crafting. Learn how educational video production effectively distills lessons or course content, promoting better understanding.
            </p>
            <li>
              <strong>Engage, Remember, Thrive:</strong>
            </li>
            <p>
            Maximizing Learning Potential through Educational Videos. Explore how educational video production boosts engagement, aids retention, and fosters student success
            </p>
            <li>
              <strong>Flexible Learning: </strong>
            </li>
            <p>
            Embracing the Freedom of Educational Videos. Discover the adaptability of educational videos, enabling learners to access knowledge whenever and wherever they desire.
            </p>
            <li>
              <strong>Efficient Training: </strong>
            </li>
            <p>
            Cutting Costs with Educational Video Production. Learn how educational video creation trims training expenses while upholding quality and effectiveness.
            </p>
            <li>
              <strong>Attract, Captivate, Inspire:  </strong>
            </li>
            <p>
            Elevating Recruitment with Educational Videos. Uncover how educational video production can captivate potential recruits and uplift your recruitment efforts. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-seo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          What Makes Us Unique:
          </h2>
          <br />
          <p className='text-justify'>
          Our Unique Approach to Video Production Involves;
          </p>
          <ul>
            <li>
              <strong>Crafting Educational Excellence</strong>
            </li>
            <p>
            From coming up with ideas to putting the final touches, we painstakingly create each video to ensure it meets the highest standards of quality and educational value. Our special approach aims to produce videos that not only inform but also ignite curiosity in learners of all ages.
            </p>
            <li>
              <strong>Experience Matters: </strong>
            </li>

            <p>
            We understand the intricacies of conveying complex information in a visually appealing manner, transforming learning into an enjoyable adventure. Our skilled professionals merge their technical skills with a profound understanding of educational principles to make videos that captivate and educate. 
            </p>
            <li>
              <strong>Meeting Deadlines with Ease:  </strong>
            </li>
            <p>
            With our careful scheduling and devoted team, you can be confident that your educational videos will be finished within the agreed-upon timeframe, empowering you to reach your educational objectives.
            </p>
            <li>
              <strong>Quality is Our Top Priority: </strong>
            </li>
            <p>
            Our rigorous quality assessments ensure that every facet of the video, from visuals to sound, adheres to the highest standards. We pay meticulous attention to detail, guaranteeing that the content is accurate, engaging, and visually enticing.
            </p>
            <li>
              <strong>Streamlined Process: </strong>
            </li>
            <p>
            Our team will guide you through each phase, from the initial concept to the final delivery, guaranteeing transparent communication and collaboration along the way. With our streamlined process, you can concentrate on your educational aspirations while we manage the intricacies of video production.
            </p>
            <li>
              <strong>A Team of Experts: </strong>
            </li>
            <p>
            We've assembled a diverse group of professionals who specialize in various aspects of video production. From crafting scripts and animations to capturing footage and editing, our team's broad experience and creative talents infuse every project.
            </p>
            <li>
              <strong>No Hidden Costs: </strong>
            </li>
            <p>
            Our objective is to offer exceptional value for your investment, delivering high-quality educational videos without any surprises. With our uncomplicated pricing structure, you can confidently plan and carry out your educational initiatives with peace of mind.
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default EducationalVideos