import React,{useState} from 'react'
import fiveStarAnimation from "../Asset/Animation/fivestarts.json"
import Loader from './Loader'
import {motion} from "framer-motion"

function TestimonialCard() {

  const [hi,setHi]  = useState(false)

  return (
    <>


    <motion.div className='flex items-center gap-x-4  w-fit ml-9   '
    whileInView={()=>{
      setHi(true);
      return {}
    }}

    >
        <motion.img 
          loading='eager'
          title='testimonial'
        initial={{x:"-50vw",y:"-50vh",visibility:"hidden",opacity:0}}
        animate={hi && {x:0,y:0,visibility:"visible",opacity:1}}
        viewport={{ once : true }}
        exit={{ opacity: 0 }}
        transition={{duration:2,delay:"3"}}
        
        className='rounded-full w-[70px] h-[70px]  sm:w-[100px] sm:h-[100px] ' width={"100%"}
        height={"100%"} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuVs4a4Va2m7JRyNWdQ_Y0X67xydsdmUMUOA&usqp=CAU" alt="Client" />
        <div className=''>
            <motion.h2 className='text-xl sm:text-2xl font-bold'
            
            initial={{x:"-50vw",y:"-100vh",visibility:"hidden",opacity:0}}
            animate={hi && {x:0,y:0,visibility:"visible",opacity:1}}
        transition={{duration:2,delay:"3"}}
            
            >Sarah M</motion.h2>
            <motion.p className=' text-[16px] sm:text-lg italic '
        
        initial={{y:"-100vh",visibility:"hidden",opacity:0}}
        animate={hi && {y:0,visibility:"visible",opacity:1}}
        transition={{duration:2,delay:"3"}}            
        >Director</motion.p>
            
            <motion.div        
        initial={{opacity:0}}
        whileInView={{opacity:1}}
        transition={{duration:2,delay:"3"}}            
        className='w-[90px] sm:w-[130px] h-fit '
      >
        <Loader width={"100%"} height={"100%"} data={fiveStarAnimation} />
      </motion.div>
      </div>
    </motion.div>
    <motion.p
     initial={{y:100,opacity:0,visibility:"hidden"}}
     whileInView={{y:0,opacity:1,visibility:"visible"}}
     viewport={{once:true}}
     transition={{duration:2,delay:"3"}}
    className='overflow-hidden text-[12px] sm:text-sm ml-4'
    >
    The level of personalization offered by the team by utilizing <br /> their AI  mobile app is unbelievable. As a result, my everyday  <br /> tasks seem effortless because it anticipates my   <br /> wants and preferences. Truly excellent technology! 
    </motion.p>
    </>
  )
}

export default TestimonialCard