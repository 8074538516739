import React,{useState,useEffect} from "react";
// import Phone from "../Asset/Images/phone.webp";
import Forms from "../Components/Forms";
import { motion } from "framer-motion";
import Loader from "../Components/Loader";
import Triangle from "../Asset/Animation/triangle.json";
// import BallAnimation from "../Asset/Animation/questionSection.json";
// import CircleAnimation from "../Asset/Animation/circle.json";

function QuestionSection() {
  const [animation ,setAnimation] = useState("")
  const [ballAnimation ,setBallAnimation] = useState("")

  const  apiGetAnimationJson = async ()=>{
    console.timeStamp("start")
      const response = await fetch("https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728322/lotties%20Json%20Files/nyc%20Home%20page%20lotties/circle_a2ynji.json")
      const jsonData  = await response.json()
      setAnimation(jsonData)
  }

  const  apiGetAnimationJsonSmallBall = async ()=>{
    console.timeStamp("start")
      const response = await fetch("https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728320/lotties%20Json%20Files/nyc%20Home%20page%20lotties/questionSection_hcjy7h.json")
      const jsonData  = await response.json()
      setBallAnimation(jsonData)
  }
  useEffect(() => {
    apiGetAnimationJson()
    apiGetAnimationJsonSmallBall()
  }, []);

  return (
    <>
      {/* <div className="relative overflow-hidden"> */}

      <div className="mx-auto   min-h-screen relative overflow-hidden p-2 ">

        {/* Circle Animation */}
        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -top-10 -right-4 hidden md:block "
          initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
          whileInView={{ y: 0, visibility: "visible", opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            visibility: "show",
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -z-20 -top-5  -left-12 hidden md:block "
          initial={{ y: "70vh", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -bottom-5 -right-3 hidden md:block"
          initial={{ x: "-100vw", y: "-100vh", opacity: 0 }}
          whileInView={{ x: 0, y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: "2",
          }}
        />
        {/* Triangle Animatoin */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className=" top-0 md:right-[25%] lg:right-[40%] xl:right-[50%] w-[12%] object-cover h-[12%] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={animation} />
        </motion.div>

       

        {/* top heading */}
        <motion.div
          className="mb-4 font-bold container xl:max-w-[90%]  xsm:text-4xl  mm:text-5xl   md:text-6xl lg:text-7xl   xl:text-8xl font-heading  uppercase text-left "
        >
          <motion.h2
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            viewport={{ once: true }}
            transition={{ duration: 8 }}
            className="overflow-hidden "
          >
            Have
          </motion.h2>
          <div className="flex gap-x-4 md:gap-x-11 flex-col md:flex-row">
            <motion.h2
              initial={{ width: 0 }}
              whileInView={{ width: "fit-content" }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
              className="overflow-hidden  "
            >
              Questions?
            </motion.h2>
            <motion.h2
              initial={{ opacity: 0, x: "-100%" }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
              className="text-white  md:block  self-end"
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
              }}
            >
              Lets Talk
            </motion.h2>
          </div>
        </motion.div>

        <div className="container  mx-auto gap-x-10 flex p-0 h-full justify-center items-center  flex-col md:flex-row mt-9   ">
          <motion.div
            initial={{ scale: 2, opacity: 0, rotate: 90, x: "-10vw" }}
            whileInView={{ scale: 1, opacity: 1, rotate: 0, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="md:w-[50%] w-full h-full flex items-center relative"
          >
            <img loading="eager" title="Contact nycprowebdesigners.com" className="" width={"100%"} height={"100%"} src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726680/ncy%20Home%20Images/phone_mv6yhx.webp" alt="nyc contact" />

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
              className="top-[9%] left-[-2%] w-[30%]   absolute "
            >
              <Loader width={"100%"} height={"100%"} data={ballAnimation} />
            </motion.div>
          </motion.div>

          <div className="md:w-[50%] w-full h-full   ">
            <motion.div
              initial={{ y: "50vh" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <Forms />
            </motion.div>
          </div>

    </div>

      </div>
      {/* </div> */}
    </>
  );
}

export default QuestionSection;
