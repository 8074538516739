import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'


function ProductVideos() {
    const data ={
        title: `Product\n Videos`,
        description:"We specialize in fashioning attractive product videos that drive e-commerce success. Our team of skilled videographers and editors collaborates closely with you to breathe life into your products on screen. From spotlighting your product's advantages to weaving unique stories, our videos are meticulously designed to engage and persuade your target audience. Let us assist you in propelling your e-commerce venture to new heights with our adept product video crafting services!",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712599/lotties%20Json%20Files/productVideo_ethj2i.json",
        head:{
          title:"Product Videos | NYC Pro Web Designers | NY 10036",
          description:"NYC's leading web designers excel in crafting engaging product videos for your brand's success.",
          canonical:`<link href="https://nycprowebdesigners.com/product-videos" rel="canonical">`
        }        
    }
    const faqs = [
      {
        question: "1. How much time is typically required for video production?",
        answer: `<p>Video production timelines can vary widely depending on factors like video length and complexity. On average, it can take anywhere from a few days to several weeks, from pre-production planning to final editing.</p>`,
      },
      {
        question: "2. Is it necessary to enlist actors, scout locations, or arrange for props in video creation?",
        answer: `<p>The need for actors, locations, or props depends on your video's concept and goals. Sometimes, professional talent and specific settings are crucial, while other projects may not require them at all.</p>`,
      },
      {
        question: "3. What's the estimated delivery speed for my video project?",
        answer:
          "<p>The time it takes to get your video can vary based on project complexity and scope. Having an open conversation with your production team will help them understand your specific needs and deliver accordingly.</p>",
      },
      {
        question: "4. Can you outline the production process for crafting product videos?",
        answer:
          "<p>The production process for product videos typically involves planning, scripting, shooting, editing, and post-production. The specifics can vary, but collaboration with a professional production team ensures a smooth workflow.</p>",
      },
      {
        question: "5. What can I expect in terms of turnaround times for my project?",
        answer:
          "<p>Turnaround times are influenced by project complexity and the efficiency of your production team. Generally, product videos may be completed in a few weeks, including pre-production and post-production phases. Discuss your specific timeline with your chosen professionals to get a more precise estimate.</p>",
      },
    ];
  return (
    <ServicesPage data={data} >

<div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Mesmerizing Product Videos For E-commerce Triumph
          </h2>
          <br />
          <p className="text-justify text-lg">
          Having the capacity to capture an individual’s fascination and inspire an intense emotional reaction from them through visual storytelling is comparable to a superpower. It is comparable to the experience of immersing yourself fully in the plot and characters of your favorite film or television program.
            <br />
            Consider the following: We have a specialized group of creative professionals who are similar to magicians using cameras. They apply their talents to create amazing videos that showcase your company. When people see these videos, they experience distinct emotions, such as exhilaration or happiness, since they are so well-made.

            <br />
            We can make motion pictures for a variety of occasions, such as whenever a new product is debuting, whether you're offering amazing offers, or when you would like to emphasize the incredible qualities of your business. 
            <br />
            In other words, we produce incredible videos that excite and engage viewers while telling the story of your company. It's like producing a fantastic film about your business's existence!
          </p>
      
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Showcasing Your Products With Flair
          </h2>
          <p className="text-justify text-lg">
          In today's fiercely competitive online marketplace, setting yourself apart is paramount to drive sales and foster the growth of your e-commerce enterprise. This is where our spectacular e-commerce video crafting services come into play. Our forte lies in shaping visually splendid videos that not only showcase your products vigorously but also leave a lasting impression on your audience. From product demonstrations and tutorials to lifestyle sketches and customer endorsements, we tailor our videos to align with your unique requirements and objectives. With our deep-rooted understanding of e-commerce, we recognize the distinctive challenges and prospects associated with online product sales. 
          <br />
          Let us guide you in boosting your online sales through our appealing e-commerce video crafting services!
          <br />
          When it comes to product video crafting, selecting the right collaborator is paramount to spinning compelling visual narratives that resonate with your intended audience. We take pride in being the leading product video crafting company. Our seasoned professionals are driven by a passion for crafting visually stunning.
          </p>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Key Features:
          </h2>
          <ul>
            <li>
              <strong>
              Boost Sales: 
              </strong>
            </li>
            <p>
            Our product videos don't just look great; they're designed to supercharge your sales strategy. By showcasing your products in action, highlighting their unique perks, and forging an emotional bond with your audience, our videos effectively spark the desire to purchase and up your conversion game. With our compelling storytelling and top-notch visuals, we help you grab the attention of potential buyers and guide them towards that 'add to cart' button. 
            </p>
            <li>
              <strong>Foster Connections: </strong>
            </li>
            <p>
            Establishing a strong connection with your audience is the key to building trust and lasting loyalty. Our product videos go beyond mere product displays; they tell a story and foster a genuine connection with your viewers. Through authentic narratives, relatable stories, and appealing visuals, we help you create a heartfelt bond with your audience. Whether it's showcasing the craftsmanship behind your products or highlighting the positive impact they can have on people's lives, our videos evoke emotions and nurture meaningful connections.

            </p>

            <li>
              <strong>Inspire Purchase Intent: </strong>
            </li>
            <p>
            We understand the influence of visual storytelling in swaying purchase decisions. Our product videos are meticulously crafted to showcase your products at their finest, spotlight their key features, and demonstrate how they solve your customers' needs. By presenting your products persuasively and engagingly, we ignite a sense of urgency and desire, ultimately guiding buying decisions. Whether it's through product demos, customer endorsements, or lifestyle visuals, our videos are tailored to spark purchase intent and boost conversions. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Process Of Product Video Production:
          </h2>
          <ul>
            <li>
              <strong>Brainstorming: </strong>
            </li>
            <p>
            Before we hit record, our skilled team dives into the early stages to ensure your product video's success. We work closely with you to grasp your vision, objectives, and target audience. From brainstorming creative concepts and crafting an engaging script to creating visual plans and organizing production details, we meticulously laid the groundwork for a remarkable product video. Our dedication to precision and a strategic approach in this phase guarantees that every aspect of your video aligns perfectly with your brand identity. 
            </p>
            <li>
              <strong>Production: </strong>
            </li>

            <p>
            After the groundwork is laid, it's time to shine the spotlight on your product. Our seasoned production team employs cutting-edge equipment and methods to capture stunning visuals that highlight your product's core. Whether it's on-location shoots, studio setups, or incorporating alluring animations, we ensure that every frame reflects the quality and uniqueness of your product. 
            </p>
            <li>
              <strong>Crafting The Final Touch: </strong>
            </li>
            <p>
            The real magic unfolds in the post-production phase, where our proficient editors and animators work their magic to transform raw footage into a work of art. We carefully handpick the best shots, enhance visuals, incorporate alluring animations, and breathe life into your product through seamless editing techniques. Our team pays close attention to every detail, ensuring that the final product video perfectly aligns with your brand's style and goals. With the addition of amazing sound design, music, and voiceovers, we create an immersive audio-visual experience that captures and resonates with your audience. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Choose Your Video Style 
          </h2>
          <ul>
            <li>
              <strong>Genuine Storytelling: </strong>
            </li>
            <p>
            Our team specializes in capturing real-life stories, interviews, and behind-the-scenes moments that truly connect with your audience. Through a documentary-style approach, we craft compelling videos that stir emotions, foster trust, and leave a lasting impact. 
            </p>
            <li>
              <strong>Everyday Moments: </strong>
            </li>
            <p>
            We craft visually amazing videos that emphasize how seamlessly your product fits into people's lives. From spotlighting its features and advantages to capturing the joy and convenience it offers, our lifestyle videos build an emotional connection with your target audience. 
            </p>
            <li>
              <strong>Artful Curating: </strong>
            </li>
            <p>
            Enhance your product's image with our curated videos, where striking visuals, attractive music, and meticulous editing converge. Our team meticulously selects the finest shots, weaves seamless transitions, and adds an artistic touch to present your product in a visually unique manner. Through our curated videos, we orchestrate a visual masterpiece that engages your audience and leaves a lasting impression. 
            </p>
            <li>
              <strong>Vivid Animation: </strong>
            </li>
            <p>
            Our skilled animators bring your concepts to life with vibrant and engaging animations. Whether it's explainer videos or product demonstrations, our animated videos simplify complex ideas, entertain, and visually delight. 
            </p>
            <li>
              <strong>Professional Studio: </strong>
            </li>
            <p>
            Experience the pinnacle of professionalism and quality with our studio videos. Our cutting-edge studio boasts top-tier equipment and a dedicated team of experts who meticulously attend to every detail. From showcasing your products to crafting promotional videos, our studio videos radiate professionalism and sophistication. 
            </p>
          </ul>
     
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default ProductVideos