import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'



function VideoAds() {
    const data ={
        title: `Video\n Ads`,
        description:"Our services in commercial video production aim to captivate your viewers and leave an enduring impact. From conceiving ideas to writing scripts, filming, and editing, we handle every aspect of the production process with the utmost professionalism. Allow us to transform your brand's vision into visually stunning and emotionally engaging video ads! ",
        upperStyle:true,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712601/lotties%20Json%20Files/video_ads_qjkrht.json",
        head:{
          title:"Video Ads | NYC Pro Web Designers | NY 10036",
          description:"Enhance your advertising with professional video production by NYC's specialists, ensuring a powerful impact.",
          canonical:`<link href="https://nycprowebdesigners.com/video-ads" rel="canonical">`
        } 
    }
    const faqs = [
      {
        question: "1. What does a commercial video production entail, and what makes it a worthwhile investment?",
        answer: `<p>Commercial video production involves creating promotional videos for businesses or products. Investing in it can boost brand visibility and engagement.</p>`,
      },
      {
        question: "2. Can you outline the steps involved in producing a commercial video?",
        answer: `<p>The process includes pre-production planning, shooting, and post-production editing, with attention to scriptwriting, casting, and visual storytelling.</p>`,
      },
      {
        question: "3. What are some recommended strategies for a successful commercial video production?",
        answer:
          "<p>Prioritize clear messaging, captivating visuals, and understanding your target audience's preferences for effective commercial video production.</p>",
      },
      {
        question: "4. How can I make my commercial video production stand out from the competition?",
        answer:
          "<p>Distinguish your work by emphasizing unique storytelling, creative cinematography, and tailored solutions to clients' specific needs.</p>",
      },
      {
        question: "5. What are effective ways to set my commercial video production apart from others in the market?",
        answer:
          "<p>You can boost your commercial video production by offering personalized client experiences, innovative techniques, and a strong commitment to quality.</p>",
      },
    ];
    return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Engaging Audience With Compelling Commercial Video Production
          </h2>
          <br />
          <p className="text-justify text-lg">
          The team of skilled professionals provides comprehensive commercial video production services customized to meet your specific requirements. We understand the significance of conveying a message that resonates with your target audience. Leveraging our storytelling, cinematography, and editing expertise, we create impactful videos that yield results. 
            <br />
            The team of skilled professionals provides comprehensive commercial video production services customized to meet your specific requirements. We understand the significance of conveying a message that resonates with your target audience. Leveraging our storytelling, cinematography, and editing expertise, we create impactful videos that yield results. 
          </p>
      
          <ul>
            <li>
              <strong>
              Seize Attention And Drive Conversions
              </strong>
            </li>
            <p>
            Video ads have emerged as potent tools for grabbing attention and converting viewers into customers. Our video ads are meticulously crafted to engage your audience from the first frame to the last. Whether it's a compelling narrative, a product demonstration, or a promotional offer, we produce video ads that leave an enduring impression and motivate action. 
            </p>
            <li>
              <strong>Connect With Your Target Audience On The World's Largest Social Network</strong>
            </li>
            <p>
            Through our Facebook video ads and LinkedIn ads, you can reach your intended audience on the world's most extensive social network. We comprehend the unique requirements of Facebook and LinkedIn advertising and create videos optimized for maximum engagement and visibility.
            </p>

            <li>
              <strong>Grab Attention On The Most Popular Video Platform Worldwide</strong>
            </li>
            <p>
            YouTube video ads offer a compelling approach to seizing attention on the world's most renowned video platform. Our team specializes in crafting YouTube video ads that resonate with your target audience, whether through entertaining content, informative demonstrations, or gripping storytelling. Instagram video ads present a visually captivating means to connect with your audience on one of the most popular social media platforms.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Types Of Video Ads We Offer
          </h2>
          <ul>
            <li>
              <strong>
              1. Employee Training Videos: 
              </strong>
            </li>
            <p>
            Our employee training videos are designed to empower your team through captivating learning experiences. From getting new hires up to speed to enhancing existing skills, we craft dynamic videos that effectively convey your training goals.
            </p>
            <li>
              <strong>2. Marketing Videos for Business: </strong>
            </li>
            <p>
            Increase your brand with our captivating marketing videos for business. Through compelling visual storytelling, we highlight your products, services, and brand values in a way that resonates with your desired audience. Whether it's a promo video, your brand's story, or showcasing your products, our marketing videos for business will make a lasting impact and drive meaningful engagement.

            </p>

            <li>
              <strong>3. Internal Communication Videos: </strong>
            </li>
            <p>
            Strengthen connections and alignment within your company through our effective internal communication videos. Whether it's sharing important updates, announcing company initiatives, or promoting a positive company culture, our videos facilitate clear and engaging communication among your employees. 
            </p>

            <li>
              <strong>4. CEO Spotlights: </strong>
            </li>
            <p>
            Cultivate trust and confidence in your leadership with our CEO spotlight videos. We spotlight the vision, expertise, and values of your CEO compellingly and authentically. This kind of video helps establish a strong connection between your brand and your target audience by showcasing their achievements, leadership style, and strategic insights.
            </p>

            <li>
              <strong>5. Brand Identity Videos: </strong>
            </li>
            <p>
            Display your unique character and values with our captivating brand identity videos. We collaborate closely with you to grasp your brand essence and create videos that effectively convey your message. From visually striking imagery to compelling storytelling, our brand identity videos assist you in establishing a robust brand presence and forging a deeper connection with your audience.
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Distributing Methods;
          </h2>
          <ul>
            <li>
              <strong>
              YouTube: 
              </strong>
            </li>
            <p>
            Utilize the immense reach of YouTube to connect with a worldwide audience through your engaging videos. YouTube boasts billions of users and offers various targeting tools, making it an ideal platform to showcase your videos and engage your intended viewers. Allow us to assist you in optimizing your YouTube presence, ensuring that your videos gain maximum visibility and impact.
            </p>
            <li>
              <strong> Website Integration:  </strong>
            </li>
            <p>
            Effortlessly incorporate videos into your website to captivate your visitors and accelerate their engagement. Whether it's a product demonstration, customer testimonial, or brand narrative, our webpage integration feature ensures a smooth and immersive video-watching experience for your website visitors.
            </p>

            <li>
              <strong>Email Signature Video:</strong>
            </li>
            <p>
            Distinguish yourself and engage email recipients with our Email Signature Video feature. You can leave a lasting and memorable impression by incorporating video content into your email signature. Whether it's a personalized message, a brief introduction, or a promotional video, our Email Signature Video feature helps you enhance the appeal and engagement of your emails.
            </p>

            <li>
              <strong>Trade Show Displays: </strong>
            </li>
            <p>
            Draw attention and make a memorable mark at trade shows with our Trade Show Display solution. Display your videos on large screens and interactive exhibits, captivating the interest of attendees and generating excitement around your brand. From product demonstrations to virtual tours, it assists you in standing out from the crowd and making a lasting impact at trade shows.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Choose Us?
          </h2>
          <ul>
            <li>
              <strong>Brand Treatment:</strong>
            </li>
            <p>
            From logo design to color palettes and typography, we'll work closely with you to develop a visual identity that resonates with your target audience and leaves a lasting impression.
            </p>
            <li>
              <strong>Copywriting: </strong>
            </li>

            <p>
            Our talented copywriters specialize in crafting compelling and persuasive messages that resonate with your audience and drive action. Whether it's website copy, ad campaigns, or social media content, we'll help you communicate your brand's unique value proposition.
            </p>
            <li>
              <strong>Imaging: </strong>
            </li>
            <p>
            From photo editing and retouching to graphic design and visual effects, our team of skilled artists will ensure that your visuals are visually striking and aligned with your brand's aesthetic.
            </p>
            <li>
              <strong>Visual Scope:  </strong>
            </li>
            <p>
            We'll help you create captivating narratives through stunning visuals, whether it's through video production, animation, or interactive media. 
            </p>
            <li>
              <strong>Production Logistics:  </strong>
            </li>
            <p>
            Our production logistics experts will handle all the behind-the-scenes details to ensure a seamless execution of your projects. From location scouting and equipment rental to talent coordination and scheduling, we'll take care of the logistics so you can focus on bringing your creative vision to life.
            </p>
            <li>
              <strong>Inter-Media Content:</strong>
            </li>
            <p>
            Whether it's repurposing your video content for social media, creating interactive experiences for your website, or optimizing your content for mobile devices, we'll help you maximize your reach and engagement.
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default VideoAds