import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function CorporateVideo() {
    const data ={
        title: `Corporate\n Videos`,
        description:"We stand out as experts in this field in the current digital era where businesses rely on the power of films to convey their stories and highlight their services. Our specialty is turning your concepts into engrossing videos that leave an enduring impression on your viewers. Our goal is to make your company's story as alluring as your favorite kid's book, which entertains and inspires. ",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712596/lotties%20Json%20Files/coprate_st1faj.json",
        head:{
          title:"Corporate Video | NYC Pro Web Designers | NY 10036",
          description:" NYC's web design experts produce impactful corporate videos to elevate your brand and engage your audience effectively",
          canonical:`<link href="https://nycprowebdesigners.com/corporate-video" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What is considered a corporate video?",
        answer: `<p>The significance of a corporate video lies in its ability to effectively convey a company's message by showcasing its products, services, or organizational culture through engaging visual content. </p>`,
      },
      {
        question: "2. What makes it essential?",
        answer: `<p>Corporate videos matter because they effectively convey information, engage audiences, and boost brand recognition. They help businesses connect with their intended audience and achieve their objectives.</p>`,
      },
      {
        question: "3. What should be included in a corporate video?",
        answer:
          "<p>A corporate video should encompass essential details about the company, its offerings, an engaging story, attractive visuals, and a clear call to action to effectively get its message across.</p>",
      },
      {
        question: "4. Who typically owns the video content?",
        answer:
          "<p>Normally, the individual or organization that commissions the corporate video holds the rights to the video footage, unless there are specific terms in a contract with the production team stating otherwise.</p>",
      },
      {
        question: "5. How many opportunities for revisions are usually available?",
        answer:
          "<p>The number of revision rounds for a corporate video is usually determined by the agreement outlined in the contract with the video production team. This can vary, but it's generally established in advance to ensure a smooth production process.</p>",
      },
    ];
  return (
    <ServicesPage data={data} >
    <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Engaging Visual Narratives: Corporate Video Production
          </h2>
          <br />
          <p className="text-justify text-lg">
          Companies employ specific videos to communicate their tales and demonstrate their services in the computer and internet-driven world of today. These videos work like magic in that they make viewers more cognizant of and knowledgeable about the business.
            <br />
            We excel in producing these videos. We take your ideas and turn them into memorable videos that others will adore.
            <br />
            Think of your preferred children's book. It's our responsibility to make the story of your business as appealing and thrilling as that book. As they do with their favorite stories, we want people to identify with your business.
            <br />
            Consider us to be storytellers who use films to give life to your company. People should remember us and say things like, "Wow that firm is amazing!" That is what drives us.
          </p>
      
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Different Types Of Corporate Videos:
          </h2>
         
          <ul>
            <li>
              <strong>Corporate Brand Storytelling:</strong>
            </li>
            <p className="text-justify text-lg">
            This video captures your company's values, culture, and what makes you unique. It leaves a lasting impression and helps establish a strong brand identity.
            </p>

            <li>
              <strong>
              Company Overview Videos:
              </strong>
            </li>

            <p className="text-justify text-lg">
            Share your company's journey through compelling company profile videos. These videos highlight your achievements, milestones, and the people driving your success. 
            </p>
            <li>
              <strong>
              Event Openers:
              </strong>
            </li>
            <p className="text-justify text-lg">
            The openers set the mood, generate excitement, and captivate your audience from the outset, leaving a lasting impression and making your event memorable.
            </p>

            <li>
              <strong>
              Talent Attraction Clips:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Attract top talent by showcasing your company's culture, work environment, and career opportunities in dynamic recruitment videos. Engage potential candidates and provide insight into your team.
            </p>

            <li>
              <strong>
              Social Responsibility Showcases:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Showcase your initiatives, community involvement, and sustainability efforts, inspiring others to make a positive impact.
            </p>

            <li>
              <strong>
              Investor Relations Insights:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Present financial information, company updates, and prospects clearly and engagingly to keep investors informed and engaged.
            </p>

            <li>
              <strong>
              Internal Communication Tutorials:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Share crucial announcements, company updates, and training materials in a visually appealing and easy-to-understand format.
            </p>

            <li>
              <strong>
              Industrial Processes Display:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Emphasize your quality standards, safety measures, and innovative solutions to build trust with potential clients and partners.
            </p>

            <li>
              <strong>
              Step-By-Step Guides:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Help your audience understand how to effectively use your products or services, leading to increased customer satisfaction and reduced support inquiries.
            </p>

            <li>
              <strong>
            	Corporate Storytelling Documentaries:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Tell a compelling story about your company's journey, challenges, and successes through alluring corporate documentaries. Engage your audience with a documentary-style video that shares your unique narrative.
            </p>

            <li>
              <strong>
              Meet Our Team Videos:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Introduce your team and spotlight the individuals behind your company's achievements with engaging "Meet Our Team" videos. Showcase their talents, expertise, and collective passion, humanizing your brand and connecting with your audience.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Maximizing The Impact Of Your Corporate Video:
          </h2>
          <ul>
            <li>
              <strong>
              Sales Videos:
              </strong>
            </li>
            <p>
            These videos should emphasize the advantages of your offerings and create a sense of urgency to boost conversions, leaving a lasting impression on potential customers and ultimately increasing your sales.
            </p>
            <li>
              <strong>Training Videos:</strong>
            </li>
            <p>
            Improve employee training and development through engaging video content. Simplify complex concepts with easy-to-follow visuals, offer step-by-step guidance, and reinforce key learning points.
            </p>

            <li>
              <strong>Corporate Meetings:</strong>
            </li>
            <p>
            Incorporate videos to effectively communicate crucial updates and key messages, engaging your audience and creating a dynamic meeting experience that they will remember.
            </p>

            <li>
              <strong>Keynotes:</strong>
            </li>
            <p>
            These videos should support your key messages, add visual interest, and overall, amplify the impact of your presentation. Deepen audience engagement and ensure your keynote leaves a lasting impression.
            </p>

            <li>
              <strong>Year In Review:</strong>
            </li>
            <p>
            Celebrate your company's accomplishments and milestones with a compelling year-in-review video. Showcase major achievements, highlight key events, and share success stories. This video serves as a means to engage your stakeholders and instill confidence in your brand's trajectory.
            </p>

            <li>
              <strong>Product Launches:</strong>
            </li>
            <p>
            Generate excitement and anticipation for your new products or services with compelling product launch videos. These videos should highlight key features, benefits, and unique selling points, sparking interest and curiosity around your latest offerings.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Services:
          </h2>
          <ul>
            <li>
              <strong>Unleash Your Creativity:</strong>
            </li>
            <p>
            Let our creative team breathe life into your ideas, crafting amazing narratives and visuals that truly engage your audience. 
            </p>
            <li>
              <strong>Precision Scripting:</strong>
            </li>

            <p>
            Tell your story with impact through our expert scriptwriting services. Our team of skilled writers will create engaging scripts that effectively convey your message and resonate with your intended audience.
            </p>
            <li>
              <strong>Accelerate Visual Aesthetics:</strong>
            </li>
            <p>
            We ensure that every frame is visually stunning, cohesive, and aligned with your brand identity. Allow us to bring your vision to fruition.
            </p>

            <li>
              <strong>On-Site Digital Imaging Technician (DIT):</strong>
            </li>
            <p>
            Our technicians employ the latest tools and technology to monitor, manage, and optimize your footage in real-time. Achieve the best results, right on set.
            </p>

            <li>
              <strong>Technical Expertise:</strong>
            </li>
            <p>
            Leave the technical details to us. Our skilled technical directors oversee the production process, ensuring seamless execution and optimal performance. 
            </p>
            
            <li>
              <strong>Immersive Sound Design:</strong>
            </li>
            <p>
            Engage your audience with a rich auditory experience through our expert sound design services. We meticulously craft audio elements that enhance storytelling, create atmosphere, and evoke emotions. 
            </p>

            <li>
              <strong>Expert Gadget:</strong>
            </li>
            <p>
            We offer a huge range of the best expert-grade cameras, lighting, audio devices, and more. trust in our system to deliver excellent outcomes.
            </p>

            <li>
              <strong>Masterful Video Modifying:</strong>
            </li>
            <p>
            Our professional editors seamlessly combo visuals, sound, and effects to create a cohesive and fascinating final product. Allow us to craft a compelling narrative out of your footage. 
            </p>
            <li>
              <strong>Putting Branded Snap Shots:</strong>
            </li>
            <p>
            Make your content material stand out with our branded photos. We lay out visually attractive factors that reinforce your brand identification and add a professional touch to your videos. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Pick Out Us:
          </h2>
          <ul>
            <li>
              <strong>Enticing Content Creation:</strong>
            </li>
            <p>
            We recognize the impact of fascinating content material. Our crew of professional writers and innovative minds excel at generating content that resonates with your preferred audience. We go beyond mere phrases on a page, crafting content that forges emotional connections and leaves a lasting impression.
            </p>
            <li>
              <strong>Enhance Your Reach & Impact:</strong>
            </li>

            <p>
            When it comes to expanding your reach and impact, count on us. Our digital advertising and marketing experts are properly versed in ultra-modern techniques and methods to ensure your content reaches the proper target market at the right moment. From optimizing for serps to strolling effective social media campaigns, we'll assist you in broadening your online presence and bolstering your impact within the virtual sphere.
            </p>
            <li>
              <strong>A Streamlined, Trouble-Unfastened Procedure:</strong>
            </li>
            <p>
            With us, you could enjoy a strain-free journey. We offer a fully managed process, dealing with every aspect of your assignment from inception to finishing touch. Our dedicated mission managers will collaborate closely with you to understand your wishes, provide regular updates, and assure timely and budget-conscious undertaking shipping.
            <br />
            Collectively, let's raise your emblem to new heights!
            </p>
          </ul>
     
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
      
      </ServicesPage>
  )
}

export default CorporateVideo