import React, { useEffect, useState ,Fragment } from "react";
import { motion } from "framer-motion";
import Loader from "../Components/Loader";
import PhoneAnimation from "../Asset/Animation/OurServices/phone.json";
import Triangle from "../Asset/Animation/triangle.json";
import SimpleButton from "./SimpleButton";
import { Helmet } from "react-helmet-async";
import Header from "../Components/Header";
import Footer from "../Screens/Footer"
import Parse from "html-react-parser"


function ServicesPage({ data, children }) {
  const [animation, setAnimation] = useState("");
  const apiGetAnimationJson = async () => {
    const response = await fetch(data?.api);
    const jsonData = await response.json();
    setAnimation(jsonData);
  };

  useEffect(() => {
    apiGetAnimationJson();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <div className=" min-h-screen w-full  flex justify-center items-center relative p-4 lm:p-5 ">
        {
    data?.head ? 
        <Helmet>
        <title>{data?.head?.title}</title>
          <meta name="description" content={data?.head?.description}  /> 
         {Parse(data?.head?.canonical)}
        {children?<meta name="robots" content="index,follow"></meta> : <meta name="robots" content="noindex,nofollow"></meta>}
        </Helmet>
        :
        <Helmet>
      <meta name="robots" content="noindex,nofollow"></meta>
        </Helmet>
}


        {/* Circle Animation */}
        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute top-0 right-0 hidden md:block "
          initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
          animate={{ y: 0, visibility: "visible", opacity: 1 }}
          transition={{
            visibility: "show",
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -top-5  -left-[50px] hidden md:block "
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />
        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -bottom-5
         left-0 hidden md:block"
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />

        {/* trinagle Animation */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        {/* main contant div */}
        <div
          className={`container    gap-x-4 flex p-0 h-full justify-center items-center  flex-col sm:flex-row ${
            data.upperStyle ? "sm:flex-row-reverse" : ""
          } `}
        >
          {/* big Animatin Div */}

          <motion.div className="sm:w-[50%] h-full flex justify-center ">
            {animation ? (
              <>
                <motion.div
                  initial={{ scale: 0, opacity: 0, height: "50vh" }}
                  animate={{ scale: 1, opacity: 1, height: "100%" }}
                  transition={{ duration: 1 }}
                >
                  <Loader width={"100%"} height={"100%"} data={animation} />
                </motion.div>
              </>
            ) : (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </motion.div>

          {/* Text information Div */}
          <div
            className={`${
              data?.width ? "sm:w-[60%]" : "sm:w-[50%]"
            }  h-full flex flex-col justify-center gap-y-3   `}
          >
            {/* <motion.h2
          
          initial={{ y: "-50vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          
          className=" xsm:text-sm  mm:text-base sm:text-2xl lm:text-lg font-bold xl:text-3xl uppercase ">
           {data.text}
          </motion.h2> */}
            <motion.h1
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1 }}
              className={`text-[#e14242] font-bold whitespace-pre-line  ${
                data.font
                  ? "xsm:text-[38px] xsm:leading-[38px] mm:text-[50px] mm:leading-[50px] lg:text-[70px] lg:leading-[70px]  xl:text-[88px] xl:leading-[88px] text-[100px]"
                  : "mm:text-[50px]  mm:leading-[50px] sm:text-[70px] sm:leading-[70px]   md:text-[80px] md:leading-[80px] lg:text-[110px] lg:leading-[110px]  xl:text-[130px] xl:leading-[130px]"
              } text-[30px] leading-[30px]   uppercase text-left  `}
            >
              {data?.title}
            </motion.h1>

            {/* <AnimatePresence >  */}

            <motion.p
              initial={{ y: 150, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 1 }}
              className="text-justify  "
            >
              {data.description}
            </motion.p>
            {/* </AnimatePresence> */}

            {/* contact */}
            <motion.div
              initial={{ y: "50vw", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              //  exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 1 }}
              className="flex items-center "
            >
              <div className="w-[80px] h-[80px] mm:w-[100px] mm:h-[100px]  lm:w-[120px] lm:h-[120px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] ">
                <Loader width={"100%"} height={"100%"} data={PhoneAnimation} />
              </div>
              <motion.a
                href="tel:+1 (929) 833-1499"
                className=" text-[#e14242] font-bold text-xl  mm:text-2xl sm:text-[22px]  md:text-2xl lg:text-3xl "
              >
                +1 (929) 833-1499
              </motion.a>
            </motion.div>
            {/* button */}
            <motion.div
              initial={{ y: "50vw", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 1 }}
              className=" flex gap-x-6 items-center flex-col gap-y-3 xl:flex-row"
            >
              {/* contact now button */}
              <SimpleButton
                title="Contact Now"
                bg="bg-[#E14242]"
                color="text-white"
                hover="hover:text-[#E14242] hover:bg-white"
              />
              {/* Get Free Consultation button */}
              <SimpleButton
                title="Get Free Consultation"
                bg="bg-white"
                color="text-[#E14242]"
                hover="hover:text-white hover:bg-[#E14242]"
              />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="relative ">
        <motion.div
          className="w-36 h-64 rounded-full blur-[50px] bg-lightyellow absolute top-[5%] right-0 hidden md:block "
          initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
          animate={{ y: 0, visibility: "visible", opacity: 1 }}
          transition={{
            visibility: "show",
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-64 rounded-full blur-[50px] bg-lightred absolute top-[20%] -left-[50px] hidden md:block "
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-64 rounded-full blur-[50px] bg-lightblue absolute top-[40%] right-0 hidden md:block "
          initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
          animate={{ y: 0, visibility: "visible", opacity: 1 }}
          transition={{
            visibility: "show",
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-64 rounded-full blur-[50px] bg-lightyellow absolute top-[60%] -left-[50px] hidden md:block "
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -bottom-5
              left-0 hidden md:block"
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />

        {/* Triangle Animation */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-[30%] left-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>


        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-[60%] right-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-[80%] right-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        {children}
      </div>
      <Footer />
    </>
  );
}

export default ServicesPage;
