import React from "react";
import ServicesPage from "../../Components/ServicesPage";
import Accordion from "../../Components/Accordian";
import { motion } from "framer-motion";

function MobileApp() {
  const data = {
    title: `Mobile App Development`,
    description:
      `We welcome you to the monarchy of technology where innovation is the
      key. The application development services that we provide are the
      pathway to your growth and success. No matter if you’re a fresher or
      well-known venture, we work according to your exclusive needs and
      bring out app solutions that sync with your objective. The empowerment
      of our application development services boosts your growth to get on
      the front line of the competition. Be it desktop or mobile
      applications, we craft custom fit solutions that oscillate the
      targeted audience of your brand to boost your identity.`,
    upperStyle: false,
    font: true,
    api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/mobileApp_xzdr41.json",
    head:{
      title:" Mobile App Development Services| NYC Pro Web Designers | NY 10036",
      description:" Exceptional Mobile App Development Services by NYC Pro Web Designers. Bringing your app ideas to life.",
      canonical:`<link href="https://nycprowebdesigners.com/mobile-app-development-services" rel="canonical">`
    }
  };
  const faqs = [
    {
      question:
        "What makes NYC Expert Web Designers stand out in generating custom app solutions?",
      answer:
        "<p>We pride ourselves on transforming imaginative concepts into tangible app solutions. Our expertise lies in creating effective and efficient applications that align with your business goals, ensuring your ideas come to life in the most impactful way possible.</p>",
    },
    {
      question:
        "How does NYC Expert Web Designers ensure seamless app development from imagination to reality?",
      answer: `<p> We follow a comprehensive development process that involves thorough planning, innovative design, precise coding, and continuous communication. Our team is dedicated to turning your ideas into functional and user-friendly applications that resonate with your audience.</p>`,
    },
    {
      question:
        "Can you provide more information about your cross-platform mobile app development services?",
      answer:
        "<p>Our cross-platform solutions bridge the gap between different devices, offering consistent user experiences. We create apps that run smoothly on various platforms, ensuring your app's performance and usability remain top-notch, regardless of the device.</p>",
    },
    {
      question:
        "How does NYC Expert Web Designers approach desktop application development?",
      answer:
        "<p>Our desktop application development services cater to the unique needs of your business. We focus on user-friendly operations and design optimized applications that enhance your business processes. Our team crafts applications that are tailor-made to align with your brand's objectives.</p>",
    },
    {
      question: `What sets your UI/UX design and development services apart from the rest?`,
      answer:
        "<p>At NYC Pro UI/UX Designs we are committed to deliver user friendly experiences which is visually appealing as well as aesthetic. We work on pixel perfect interferences taking an idea from wireframes. </p>",
    },
  ];

  return (
    <ServicesPage data={data}>
    <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Generating Custom Mobile App Solution
          </h2>
          <br />
          <p className="text-justify text-lg">
          Suppose you might have a brilliant concept for an entertaining app. Either it's a gadget that renders your life smoother or a game you've always wanted to play. 
            <br />
            Um! Nevertheless, guess what? We can turn your imagination into reality! 
            <br />
            Let us introduce you to our incredible App Development Services;
            <br />
            So, what an app is? It can be a quick app you download to your phone or tablet. It could be a game similar to the enjoyable ones you like to play, or it could be something practical like a calculator or a map. 
          <br />
          But how does your idea move from your thoughts to your gadget? That's where we hoof it!
          <br />
          Our team of experts knows how to grasp your concept and transform it into a fully functional app. We ensure it's not only cool but also works seamlessly. We want your app to be effectual, in the sense that it does what it is supposed to do, and is handy, which means it doesn't drain your device's battery or memory. We are privileged to bring your ideas to life, almost like magic! So, if you have an amazing app idea, don't limit it to yourself – let us assist you in bringing it to reality. We look after all the technical aspects, so you can relish using your very own app.
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Transform Your Business With Pro Application Development Services:
          </h2>
          <br />
          <p className="text-justify text-lg">
          The empowerment of our application development services boosts your growth to get on the front line of the competition. Be it desktop or mobile applications, we craft custom-fit solutions that oscillate the targeted audience of your brand to enhance your identity. 
          </p>
          <ul>
            <li>
              <strong>
              Executing Mobile App Developmental Technologies 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Accommodate the Android world with our pro-app development services. We craft your vision to sleek Android applications that function in a bespoke to the objectives of your business. Optimized performance of your app by tailored involvement of our Android app development services to make sure that you are visible on the Google Play Store. 
            </p>
            <li>
              <strong>
              The Power Of Android App Development Unlocked
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Accommodate the Android world with our pro-app development services. We craft your vision to sleek Android applications that function in a bespoke to the objectives of your business. Optimized performance of your app by tailored involvement of our Android app development services to make sure that you are visible on the Google Play Store. 
            </p>

            <li>
              <strong>
              Absolute iOS App Development For Atypical User Experiences:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            The iOS ecosystem calls for transcendence which is the game of no time for our team of Apple app developers. We are always forefront of challenges. We work by dwelling on the complexities of iOS. Our team crafts apps that consolidate smoothly with Apple devices. We focus on the app performance that leads to ramped experiences for users and that it meets the high standards of Apple. 
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Bridging The Gap With Cross Platform Mobile App Development:
          </h2>
          <br />
          <p className="text-justify text-lg">
          Let us take you to the world of cross-platform development. We make your device’s boundaries outshine keeping in mind the apps’ needs. The cross-platform solutions correlate with the running of apps for better user experiences. Long story short we bring your imagination to life where platform doesn’t matter.
          </p>
          <br />
          <ul>
            <li>
              <strong>
              Designing Custom Mobile App Experiences Bespoke To You: 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Not all the solutions fit your business needs and are unique. Where everyone fails to work, we come up with our custom mobile app development services. We are your partners in crafting personalized apps that fulfill your unique requirements.  We conceptualize, develop, and design the app that smoothly incorporates your brand’s operations and systems with the targeted audience. 
            </p>
            <li>
              <strong>
              Boosting Your Brand With Our Desktop Application Development:
              </strong>
            </li>
            <p className="text-justify text-lg" >In the stream of industries desktop development applications play a vital role. We provide you with the best solutions for our desktop development services that lead to the unique demands of your business. We are experts in user-friendly operations. We generate optimizable applications that uplift your business. 
            </p>
            </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Crafting Phenomenal UI/UX Designs For Better Experiences: 
          </h2>
          <br />
          <p className="text-lg text-justify">
          We welcome you to the craft and operational junction. If you want to turn your app into a masterstroke then you dropped by at the best platform where we provide UI/UX designs and development services according to your ambition and needs. We ensure usability to give you a sleek and fun journey through your application. Wireframes to pixel-perfect intervention, we transform your app into satisfactory visuals.  
          </p>
          <ul>
            <li>
              <strong>
              Beyond The Bounds Development:  
              </strong>
            </li>
            <p className="text-justify text-lg" >
            We help you get optimized and we work on bus fixing as well. Let's be our partner and cherish the smooth experience of your live app.
            </p>
            <li>
              <strong>
              Comprehensive Support And App Maintenance.
              </strong>
            </li>
            <p className="text-justify text-lg" >
            If you think launching an app ends the hike, this is not it. We provide maintenance of your app and support services to lead you to the safest net in the rapidly growing digital system.
            </p>
                     
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          An Innovative Partner: Why Choose Us?
          </h2>
          <br />
          <p className="text-justify text-lg">
          We are known for redefining user experiences. We have experience in delivering top-notch solutions for your business.
          </p>
            <ul>
              <li> <strong>
              Holistic Expertise: 
                 </strong>
                 </li>
              <p>
              We provide holistic expertise that transforms vision into results. Our disciplined team encounters all aspects of the development of the application. Be it designs, coding, or user experiences, we have your back.
              </p>
              <li>
                <strong>

                Custom Made Solutions:
                </strong>
                </li>
             
              <p>
              Understanding the uniqueness of business, we provide custom-made solutions to the brands. We make sure that these solutions sync with your brand’s objective.
              </p>
              <li>
                <strong>
                Proven Track Record:
                </strong>
                </li>
              <p>
              We are known for delivering outclass services and solutions for your brand. We have crafted successful applications for innumerable users. 
              </p>
              <li>
                <strong>

                User-Centric Designs:
                </strong>
                
                </li>
              <p>
              User experience is our priority, Our designs speak volumes and we make designs that are user-friendly and visually attractive. 
              </p>
            </ul>
        </motion.div>
        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  );
}

export default MobileApp;
