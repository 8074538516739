import React from 'react'
import { motion } from 'framer-motion'
import Header from "../Components/Header"

export default function ThankYou() {
  return (
    <>
    
    <Header/>

    <div className='relative'>
        <motion.div
              className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute top-0 right-0 hidden md:block "
              initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
              animate={{ y: 0, visibility: "visible", opacity: 1 }}
              transition={{
                visibility: "show",
                duration: "2",
              }}
            />

            <motion.div
              className="w-36 h-36 rounded-full blur-[50px] bg-lightred absolute -top-5  -left-[50px] hidden md:block "
              initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
              animate={{ x: 0, visibility: "visible", opacity: 1 }}
              transition={{
                duration: "2",
              }}
            />
            <motion.div
              className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute bottom-0
            left-0 hidden md:block"
              initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
              animate={{ x: 0, visibility: "visible", opacity: 1 }}
              transition={{
                duration: "2",
              }}
            />


    <div className='container mx-auto  flex flex-col justify-center items-center h-screen font-heading  overflow-hidden ' >
   

      <motion.h2 initial={{opacity:0,x:"100vw"}}
      animate={{opacity:1,x:"0"}}
      transition={{duration:4}}
      className='text-3xl sm:4xl  md:text-5xl mt-10 lg:text-6xl  xl:text-7xl font-semibold text-center text-button'>

        Thank You
      </motion.h2>
      <br /><br />
      
      <motion.p initial={{opacity:0 ,y:100}}
      animate={{opacity:1,y:0}} 
      transition={{duration:2}}
      className='text-lg text-left sm:text-center '>
   We want to take a moment to express our sincere gratitude for choosing NYCProWebDesigners as your source for all things web design and development. Your presence on our website means a lot to us, and we're truly thankful for your support. Your engagement, feedback, and trust in our platform have played a crucial role in shaping NYCProWebDesigners into a valuable resource for web enthusiasts and professionals alike. We're committed to delivering top-notch content and services to meet your needs, and we're excited about the journey ahead with you as part of our community.
      </motion.p>
        
    </div>

    </div>

    </>

  )
}
