import React,{useState,useEffect} from 'react'
import Logo from "../Asset/Images/nycprowebdesingers logo.svg"
import { motion,AnimatePresence } from 'framer-motion'
import SideBar from './SideBar'
import { Link } from 'react-router-dom';



function Header() {
    const [menu,setMenu] = useState(false)
    const [bg,setBg] = useState(false)


const ScrollFuntion = () => {
    return window.scrollY > 80 ? setBg(true) : setBg(false)
 }
    useEffect(()=>{
        window.addEventListener("scroll",ScrollFuntion)
        return ()=> window.removeEventListener("scroll",ScrollFuntion)
    },[])
  return (
    <>
    <AnimatePresence>
        { menu && <SideBar />
        }
        </AnimatePresence>
    <div className={` w-full h-[100px] flex z-[99999] justify-center fixed ${bg? "bg-white h-[70px]" : "h-24 "}`} >
       
       
        <header className={`container mx-auto relative self-center flex items-center justify-center px-3  w-full   `}  style={{zIndex:99999}}  >
        <Link to={"/"}>
          <motion.img
          loading='eager'
          title='nycprowebdesigners.com'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 5, easings: "easeInOut", delay: 1.5 }}
            src={Logo}
            alt="Nyc Pro Web Designers"
            width={"100%"}
            height={"100%"}

            className="transition-all duration-400 w-[200px] mm:w-[250px] "
          />
        </Link>

          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1.5 }}
          className="inline-block cursor-pointer absolute right-0  z-[99999] self-start  mr-3 " onClick={()=>setMenu(!menu)}>

            <div className="w-[20px] sm:w-[29px] h-[3px] bg-[#333] mt-[6px] duration-[0.4s] object-cover" style={menu ? {transform: "translate(0, 7px) rotate(-45deg)" } : {} } ></div>
            <div className="w-[20px] sm:w-[29px] h-[3px] bg-[#333] mt-[6px] duration-[0.4s]" style={menu ? {opacity:0} : {}} ></div>
            <div className="w-[20px] sm:w-[29px] h-[3px] bg-[#333] mt-[6px] duration-[0.4s] object-cover" style={menu ? {transform: "translate(0, -11px) rotate(45deg)"} : {}  } ></div>
          </motion.div>

      </header>

    </div>
    </>


  )
}

export default Header