import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function VideoPostProduction() {
    const data ={
        title: `Video Post\n Production`,
        description:"With a team of expert editors and access to state-of-the-art editing software, we breathe life into your creative vision.  Leave the technical intricacies to us, freeing you to focus solely on crafting your narrative. Experience the unique excellence of our video post-production services.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712601/lotties%20Json%20Files/videoPostProduction_sw5xmv.json",
        head:{
          title:"Video Post Production | NYC Pro Web Designers | NY 10036",
          description:"NYC's top web designers refine your videos to perfection with expert post-production techniques",
          canonical:`<link href="https://nycprowebdesigners.com/video-post-production" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What is the typical duration of video post-production?",
        answer: `<p>The time needed for video post-production varies based on project intricacy. Editing, color grading, and incorporating special effects influence the overall timeframe.</p>`,
      },
      {
        question: "2. Which software is commonly utilized for video post-production?",
        answer: `<p>Frequently used software includes Adobe Premiere Pro, Final Cut Pro, and DaVinci Resolve. These tools offer diverse features for editing, effects, and color correction.</p>`,
      },
      {
        question: "3. Can alterations be made to videos during post-production?",
        answer:
          "<p>Certainly! Video post-production allows flexibility and revisions. Collaborate with the editor to modify footage, audio, transitions, and more for the desired result.</p>",
      },
      {
        question: "4. How can optimal quality be assured for the final video?",
        answer:
          "<p>For top-notch quality, provide high-resolution source files and clear guidance to the post-production team. Consistent communication and feedback ensure the desired outcome.</p>",
      },
      {
        question: "5. Is it possible to include special effects or animations in videos during post-production?",
        answer:
          "<p>Yes, video post-production facilitates enriching videos with special effects, animations, and motion graphics. These components breathe life into your video, engaging your audience effectively.</p>",
      },
    ];
  return (
   <ServicesPage data={data}>
    <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Lights, Camera, Action! Experience Excellence With Our Video Post Production Service
          </h2>
          <br />
          <p className="text-justify text-lg">
          Our video post-production solutions are tailored to take your raw footage and morph it into a true work of art. With our team of skilled editors and cutting-edge editing software, we bring your creative vision to life by enhancing visuals, optimizing audio, and incorporating special effects when required. Count on us to manage the technical facets of your video production, allowing you to concentrate on narrating your story. Discover the distinction with our video post-production services.
          </p>

          <ul>
            <li>
              <strong>YouTube Video Enhancement:</strong>
            </li>
            <p className="text-justify text-lg">
            Seamless transitions, eye-catching effects, and professional audio enhancements will add a touch of brilliance to your videos.
            </p>

            <li>
              <strong>Editing Personal Videos:</strong>
            </li>

            <p className="text-justify text-lg">
            Whether it's a family vacation, a milestone event, or any special occasion, we will create a beautifully edited video that captures the unique story of your life.
            </p>
            <li>
              <strong>Refining Corporate Videos:</strong>
            </li>
            <p className="text-justify text-lg">
            We will help you create polished and professional videos that effectively convey your brand's message, values, and offerings.
            </p>

            <li>
              <strong>Expertise In Explainer Videos:</strong>
            </li>
            <p className="text-justify text-lg">
            Engaging visuals, clear narration, and concise storytelling will help you educate and captivate your viewers.
            </p>

            <li>
              <strong>Showcasing Product Excellence:</strong>
            </li>
            <p className="text-justify text-lg">
            Illuminate the features and advantages of your products through our product video editing services. Our visually stunning videos will spotlight your products, boost sales, and leave a memorable mark.
            </p>

            <li>
              <strong>Elevating Educational Content:</strong>
            </li>
            <p className="text-justify text-lg">
            We'll assist you in crafting informative and captivating videos that simplify intricate subjects, empower your audience, and enrich their learning journey.
            </p>


            <li>
              <strong>Compelling Video Ads:</strong>
            </li>
            <p className="text-justify text-lg">
            We'll design compelling videos to effectively market your products, services, or brand, ensuring your ads make a significant impact on your intended audience.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Workflow At Our Video Editing Company:
          </h2>
        
          <ul>
            <li>
              <strong>Share Your Footage:</strong>
            </li>
            <p className="text-justify text-lg">
            Delve into the specifics of your desired video outcome. Share your imaginative input and distinct requirements. We'll skillfully actualize your concepts.
            </p>

            <li>
              <strong>
              Project Setup:
              </strong>
            </li>

            <p className="text-justify text-lg">
            Our adept team will adeptly arrange and classify your footage. Crafting a timeline and storyboard based on your directives will be our priority.
            </p>
            <li>
              <strong>
              Video Crafting:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our skilled editors will work their charm, transforming your rough clips into a compelling video. Seamless transitions, captivating effects, and expert audio enhancements will be seamlessly woven in.
            </p>

            <li>
              <strong>
              Review Phase:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Upon completing the initial edit, we'll send you the video for your assessment. Your feedback is invaluable, and we welcome any modifications needed to align with your expectations.

            </p>

            <li>
              <strong>
              Delivery:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Incorporating your input and executing final refinements, we'll furnish the polished video. It will be poised for sharing on your favored platform, primed to dazzle your audience.
<br />
Let us materialize your vision through our refined video editing procedure!
            </p>


          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Tailored Services For Comprehensive Production:
          </h2>
          <ul>
            <li>
              <strong>
              Strategic Planning:
              </strong>
            </li>
            <p>
            Forge a winning strategy closely aligned with your objectives and target viewers. We'll assist in formulating a comprehensive blueprint to optimize the impact of your video creation.
            </p>
            <li>
              <strong>Creative Conceptualization:</strong>
            </li>
            <p>
            Enlist our imaginative team to animate your concepts. We'll brainstorm ingenious ideas that resonate with your audience, ensuring your video shines amidst competitors.
            </p>
            <li>
              <strong>Production Phase:</strong>
            </li>
            <p>
            Lights, camera, and action! Our adept production crew will oversee every facet of filming, from scouting locations to capturing mesmerizing visuals and captivating performances.
            </p>
            <li>
              <strong>Post-Production Excellence:
</strong>
            </li>
            <p>
            Our skilled post-production editors will perform their artistry. Elevating your footage with seamless transitions, visually arresting effects, and top-notch audio enhancements.
            <br />
            Witness the potency of our customizable services, adaptable to grow into a comprehensive production. 

            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Innovative Video Editing Solutions
          </h2>
          <br />
          <p className="text-justify">
          Elevate your videos with our state-of-the-art video editing solutions. Our team of skilled video editors and dedicated account managers is committed to bringing your vision to life. 
          </p>
          <ul>
            <li>
              <strong>Raw To Refined</strong>
            </li>
            <p>
            From selecting the best raw footage to incorporating stylish cuts and transitions, we infuse that extra layer of creativity to make your videos shine uniquely. Our mastery in motion graphics and typography will hold your audience spellbound, elevating their visual experience.
            </p>
            <li>
              <strong>Audio Mixing</strong>
            </li>

            <p>
            Rest assured, our use of licensed stock footage and soundtracks guarantees top-notch quality for your videos. Our techniques for audio mixing and enhancement will lend a professional and polished sound to your content.
            </p>
            <li>
              <strong>Color Correction And Grading</strong>
            </li>
            <p>
            Employing our multi-camera editing capabilities, we seamlessly merge footage from various angles, delivering a dynamic and immersive viewing journey. Our expertise in color correction and grading further enriches the visual finesse of your videos.
            </p>

            <li>
              <strong>Text Pop-Ups and subtitles</strong>
            </li>
            <p>
            For greater accessibility and engagement, we provide services like subtitles and on-screen text pop-ups. With Dropbox space at your disposal, file transfer becomes a breeze, ensuring a seamless workflow. 
            </p>
            <li>
              <strong>Revisions</strong>
            </li>
            <p>
            With the flexibility to handle projects of any size and unlimited raw footage duration, we are well-equipped for diverse demands. Our prompt turnaround time of 5 working days or 48 hours for expedited delivery ensures you receive your edited videos promptly. Furthermore, we extend 3 complimentary revisions to ensure your utmost satisfaction.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Advantages Of Opting For Our Services:
          </h2>
          <p className="text-justify">
          There are many reasons for choosing us few of them are;
          </p>
          <ul>
            <li>
              <strong>Time-Efficiency:</strong>
            </li>
            <p>
            Liberate valuable time for your core business functions by entrusting your video production to us. We'll manage all intricacies, enabling you to concentrate on your strengths.
            </p>
            <li>
              <strong>Premium Professional Videos:</strong>
            </li>

            <p>
            Enhance your brand's stature with exceptional video content. Our team of adept experts will present your business most finely, leaving an enduring impact on your viewers.
            </p>
            <li>
              <strong>Alleviated Pressure:</strong>
            </li>
            <p>
            Bid farewell to the strain of handling the complexities of video production. We'll oversee all aspects, assuring a seamless and trouble-free journey from beginning to end.
            </p>

            <li>
              <strong>Harmonized Work and Life:</strong>
            </li>
            <p>
            Reestablish equilibrium between work and personal life by delegating your video production requirements to us. Devote more time to loved ones and pursue your interests, while we curate compelling videos for your enterprise.
            </p>
            <li>
              <strong>Business Expansion:</strong>
            </li>
            <p>
            Investing in first-rate videos can foster substantial business growth. Captivate your audience, captivate new clientele, and heighten conversions with our expertly tailored videos.
            <br />
            Select us for an untroubled video production experience that economizes your time, mitigates stress, and propels your business toward success.
            </p>
          </ul>
      
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
   </ServicesPage>
  )
}

export default VideoPostProduction