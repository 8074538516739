// import React, {useState} from "react";
// import { DownOutlined } from '@ant-design/icons';

// const Accordion = (props) => {
//     const [data, setData] = useState({active:1});

//     const handleToggleActive = () => {
//         let activeNew = data.active === 1 ? 0 : 1;
//         setData({...data, active : activeNew});
//     }

//     return (
//         <div  className={` p-2 px-3 rounded-md mb-5 duration-500 group ${data.active === 1 ? 'is-active bg-white' : ''}`}
//         >
//             <div className="flex items-center">
//                 <div className="w-full font-bold">
//                 {props.title}
//                 </div>
//                 <div className="text-xl  cursor-pointer duration-500 group-[.is-active]:rotate-[180deg]" onClick={handleToggleActive}>
//                     <DownOutlined />
//                 </div>
//             </div>
//             <div className="overflow-hidden duration-500 max-h-0 group-[.is-active]:max-h-fit">
//             {props.children}

//             </div>
//         </div>
//     );
// }
// export default Accordion;

import React,{useState} from "react";
import parse from 'html-react-parser';
import { DownOutlined } from '@ant-design/icons';
import {motion} from "framer-motion"
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function DefaultAccordion({ faqs }) {
  const [open, setOpen] = useState("");

  const handleOpen = (value) => setOpen(open === value ? "" : value);
  return (
    <>
      {faqs?.map((item,index) => {
        return (
          <Accordion open={open === index} key={index}  className=" py-2 "   icon={<motion.div   initial={{opacity:0,x:300}} whileInView={{opacity:1,x:0}} viewport={{once:true}} transition={{duration:2}}  className="  group-[.is-active]:rotate-[180deg] ">
            <DownOutlined />
          </motion.div> } >
            <AccordionHeader onClick={() => handleOpen(index)} className="text-lg "  >
             <motion.p
             
             initial={{opacity:0,x:-200}}
             whileInView={{opacity:1,x:0}}
             viewport={{once:true}}
             transition={{duration:2}}
             >
              
               {parse(item?.question)}
              </motion.p>
            </AccordionHeader>
            <AccordionBody  >
                {parse(item.answer)}
            </AccordionBody>
          </Accordion>
        );
      })}

      {/* <Accordion open={open === 2}>
        <AccordionHeader onClick={() => handleOpen(2)}>
          How to use Material Tailwind?
        </AccordionHeader>
        <AccordionBody>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 3}>
        <AccordionHeader onClick={() => handleOpen(3)}>
          What can I do with Material Tailwind?
        </AccordionHeader>
        <AccordionBody>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion> */}
    </>
  );
}
