import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Loader from "../Components/Loader";
import Triangle from "../Asset/Animation/triangle.json";
import Header from "../Components/Header";
import { Helmet } from "react-helmet-async";

function ServicesPage({ data, children }) {
  const [animation, setAnimation] = useState("");
  const apiGetAnimationJson = async () => {
    console.timeStamp("start");
    const response = await fetch(
      "https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728321/lotties%20Json%20Files/nyc%20Home%20page%20lotties/aboutus_it21ob.json"
    );
    const jsonData = await response.json();
    setAnimation(jsonData);
  };

  useEffect(() => {
    apiGetAnimationJson();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <div className=" h-full w-full   relative p-4 lm:p-5 ">
        <Helmet>
          <title>About Us | NYC Pro Web Designers | NY 10036</title>
          <meta
            name="description"
            content="Learn about NYC Pro Web Designers: Your premier choice for web excellence in the heart of New York City"
          />
          <link
            href="https://nycprowebdesigners.com/about"
            rel="canonical"
          ></link>
        </Helmet>
        {/* Circle Animation */}

        <motion.div
          className="w-36 h-36 rounded-l-full blur-[50px] bg-lightred absolute -bottom-5 right-0 hidden md:block "
          initial={{ y: "50vh", visibility: "hidden", opacity: 0 }}
          animate={{ y: 0, visibility: "visible", opacity: 1 }}
          transition={{
            visibility: "show",
            duration: "2",
          }}
        />

        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -top-5  -left-[50px] hidden md:block "
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />
        <motion.div
          className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute -bottom-5
         left-0 hidden md:block"
          initial={{ x: "-50vw", visibility: "hidden", opacity: 0 }}
          animate={{ x: 0, visibility: "visible", opacity: 1 }}
          transition={{
            duration: "2",
          }}
        />

        {/* trinagle Animation */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 bottom-0 left-0 w-[250px] h-[250px]  absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5, easings: "easeInOut", delay: 1 }}
          className="rotate-90 top-0 right-0 w-[250px] h-[250px] absolute hidden md:block"
        >
          <Loader width={"100%"} height={"100%"} data={Triangle} />
        </motion.div>

        {/* main contant div */}
        <div
          className={`container  mx-auto   gap-x-4 flex p-0 h-full justify-center items-center  flex-col sm:flex-row-reverse `}
        >
          {/* big Animatin Div */}
          <motion.div className="sm:w-[60%] h-full flex justify-center ">
            {animation ? (
              <>
                <motion.div
                  initial={{ scale: 0, opacity: 0, height: "50vh" }}
                  animate={{ scale: 1, opacity: 1, height: "100%" }}
                  transition={{ duration: 1 }}
                >
                  <Loader width={"100%"} height={"100%"} data={animation} />
                </motion.div>
              </>
            ) : (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </motion.div>

          {/* Text information Div */}
          <div
            className={`sm:w-[50%]  h-full flex flex-col justify-center gap-y-3   `}
          >
            <motion.h2
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1 }}
              className={`text-[#e14242] font-bold whitespace-pre-line  xsm:text-[38px] xsm:leading-[38px] mm:text-[50px] mm:leading-[50px] lg:text-[70px] lg:leading-[70px]  xl:text-8xl  text-[100px]     uppercase text-left  `}
            >
              About <br /> Us
            </motion.h2>

            {/* <AnimatePresence >  */}

            <motion.h2
              initial={{ opacity: 0, x: -150 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className=" text-[18px] lg:text-xl  font-semibold"
            >
              NYC Pro Web Designer - Software Development Company
            </motion.h2>
            <motion.p
              initial={{ y: 150, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 1 }}
              className="text-justify  "
            >
              We welcome you to software development services where we keep
              quality assurance and provide a digital pathway to your future.
              With a keen interest in technology and a promising history of
              excellence. We are an experienced software development service
              provider offering a wide range of services. 
            </motion.p>
            {/* </AnimatePresence> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
