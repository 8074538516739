import React from 'react';
// import { Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const SideBar = () => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  return (


    <motion.div
      initial={{opacity:0,x:-100}}
      animate={{opacity:1,x:0}}
      transition={{duration:0.8}}
      exit={{ opacity: 0,x:-100 }}

    className='bg-button h-screen w-[200px] sm:w-[250px] md:w-[300px] lg:w-[400px] fixed z-[100] animate-fade-right animate-infinite flex justify-center items-center ' style={{borderRadius: "0px 35px 35px 0px"}} >

      <ul className='text-white text-3xl font-heading gap-4 flex flex-col uppercase  '>
        <Link to="/">Home</Link>
        <Link  to="/about">ABOUT</Link>
        <Link  to="/services">SERVICES</Link>
        <Link  to="/contact">Contact</Link>
      </ul>


    </motion.div>




    // <div className=" bg-red-800  absolute top-0 left-0 h-full w-1/4 bg-gray-800 transition-transform duration-300 transform -translate-x-full md:translate-x-0">
    //   <div className="py-4 px-6">
    //     <h2 className="text-white text-xl font-semibold">Navigation</h2>
    //     <ul className="mt-4">
    //       <li className="text-gray-300 hover:text-white cursor-pointer py-2">Home</li>
    //       <li className="text-gray-300 hover:text-white cursor-pointer py-2">About</li>
    //       <li className="text-gray-300 hover:text-white cursor-pointer py-2">Services</li>
    //       <li className="text-gray-300 hover:text-white cursor-pointer py-2">Contact</li>
    //     </ul>
    //   </div>
    // </div>
  );
};

export default SideBar;
