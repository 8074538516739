import React,{useState} from 'react'
import {motion} from "framer-motion"
import { useNavigate } from 'react-router-dom'

function ServiceBox(props) {
  const [show,setShow] = useState(true)

  const {title,description,bg,hover,svgImg,navigateTo,} = props.info
const navigate  = useNavigate()
  
  return (
    <motion.div 
    onClick={()=> navigate("/"+navigateTo)}
    initial={{y:30,opacity:0,visibility:"hidden"}}
    animate={{y:0,opacity:1,visibility:"visible"}}
    transition={{duration:0.4}}
    onMouseEnter={()=>setShow(false)}
    onMouseLeave={()=>setShow(true)}
    className={`flex flex-col justify-center items-center transition-all duration-1000   rounded-3xl  py-12 px-10 cursor-pointer ${bg} ${hover} gap-y-3 `}>        
        <img  loading="eager" title={title} src={svgImg} width={80} height={80} style={{fill:"green"}}  alt={title} className={`transition-all duration-500 ${!show && "scale-[1.3]" } `} />
        {
           show && <> 
            <h2 className='text-2xl font-bold uppercase justify-center'>{title}</h2>
            <p className={`text-center transition-all duration-1000 ${show ? "block" : "hidden"}`}>{description}</p>
            </>
         }
    </motion.div>




  )
}
export default ServiceBox