import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";


function NFTArt() {
    const data ={
        title: `NFT ART`,
        description:"Non-Fungible Tokens, or NFTs, are like possessed digital valuables. Imagine possessing a unique sticker or a rare trading card, but having it on a computer rather than in your hand. Our business excels at creating these online treasures for consumers. NFTs can be compared to interesting works of art or fun video game accessories. We create them just for you and on an individual basis. It resembles receiving a customized superhero cape.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712599/lotties%20Json%20Files/NftArt_nwbaec.json",
        head:{
          title:" NFT ART | NYC Pro Web Designers | NY 10036",
          description:"Experience the future of art with NYC Pro Web Designers. We specialize in NFT Art creation and promotion",
          canonical:`<link href="https://nycprowebdesigners.com/nft-art" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. What is NFT (Non-Fungible Token) ?",
        answer:"<p>NFTs, or Non-Fungible Tokens, signify exclusive digital assets representing ownership or validation of genuineness for distinct items such as artworks or collectibles.</p>",
      },
      {
        question:
          "2. What is NFT development?",
        answer: `<p>NFT crafting encompasses the formulation and execution of the technology and framework for originating, vending, and managing NFTs. This encompasses devising intelligent contracts, incorporating blockchain, and designing user interfaces.</p>`,
      },
      {
        question: "3. What are the benefits of NFT development?",
        answer:
          "<p>The merits of NFT advancement encompass empowering artists to commercialize their digital creations, ensuring ascertainable possession and origin for digital properties, and fashioning novel prospects for artists and enthusiasts within a decentralized marketplace.</p>",
      },
      {
        question: "4. Why is your service unique from others?",
        answer:
          "<p>Our service distinguishes itself through a wealth of familiarity in NFT development, the provision of collaborative engagement with clientele, the introduction of inventive attributes, and a paramount commitment to the security and integrity of the NFTs we engender.</p>",
      }
    ];
  return (
    <ServicesPage data={data} >
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Maximize The Potential Through Our NFT Art Services:
          </h2>
          <br />
          <p className="text-justify text-lg">
          You can create your NFTs or purchase some that others have created with our assistance. It is like collecting baseball cards or postage stamps, but online. 
          </p>

          <ul>
            <li>
              <strong>Seamless Integration Of NFTs Into Your Shopify Store-Our NFT Gateway Solution:
</strong>
            </li>
            <p className="text-justify text-lg">
            Lift your Shopify store to unprecedented heights using our Shopify NFT Gateway. Our team of adept developers will seamlessly infuse NFT functionality into your store, enabling you to present and vend NFT art to your valued audience. Stay at the forefront of the e-commerce arena by embracing the transformative potential of NFTs.
            </p>
            <li>
              <strong>Enhance Your Creative Expression Through NFT Art Development Services:</strong>
            </li>
            <p className="text-justify text-lg">
            Are you an artist eager to delve into the NFT universe? Our proficient developers are ready to assist you in metamorphosing your artistic creations into enthralling NFTs. With our specialized NFT art development services, you can disclose your talent to a global audience and open up new vistas in the sphere of digital artistry.
            </p>

            <li>
              <strong>Realize Your Vision Of An NFT Marketplace With Our Expert Development:</strong>
            </li>
            <p className="text-justify text-lg">
            Ready to manifest your very own NFT marketplace? 
            <br />
            Our seasoned team can transform your vision into a tangible reality. Leverage our NFT marketplace development services to launch a secure and user-centric platform, where artists and collectors seamlessly interact to trade NFTs. Allow us to aid you in establishing a thriving community within the dynamic ecosystem.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Bring Your Visions To Life Through NFT Development:
          </h2>
          <p className='text-justify text-lg'>
          Envisioning your distinctive NFTs? 
          <br />
            Our proficient team of developers stands ready to convert your concepts into tangible reality. Armed with expertise in NFT development, we're poised to assist you in conceptualizing and crafting emerging digital assets that will enrapture audiences and stand out within the market.
          </p>        
          <ul>
            <li>
              <strong>Smart Contract Development Solutions:
</strong>
       
            </li>

            <p className="text-justify text-lg">
            Eager to establish secure and dependable NFTs? Our team of proficient experts specializes in the development of smart contracts, guaranteeing the construction of your NFTs on a foundation of robust and efficient blockchain technology. With our array of services, you can overhaul how you generate and oversee your NFT assets.
          </p>

            <li>
              <strong>Our Comprehensive Audit Services:</strong>
            </li>

            <p className="text-justify text-lg">
            Don't leave the security of your smart contracts to chance. Our dedicated team extends a comprehensive suite of smart contract audit services, meticulously identifying vulnerabilities to assure the soundness of your NFT ecosystem. Rely on us for a thorough evaluation and recommendations, nurturing a secure and dependable NFT encounter.
          </p>
            <li>
              <strong>ERC721 & ERC1155 Development Expertise:</strong>
            </li>
            <p className="text-justify text-lg">
            Prepared to commence on an exploration of the boundless prospects within the NFT world? Our adept developers specialize in the era of ERC721 and ERC1155 development, empowering you to conceptualize and oversee distinct NFT assets. Whether your aspiration entails crafting unique ERC721 tokens or versatile ERC1155 tokens, our proficiency lies in translating your NFT aspirations into reality.
          </p>

    
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Enhance Your NFT Journey With Our All-Inclusive Offerings:
          </h2>
          <br />
          <p className='text-justify'>
          Seeking comprehensive NFT services that encompass every facet of your project? Look no further! Our enterprise presents a wide array of services, encompassing NFT creation, smart contract formulation, marketplace fusion, and more. Allow us to shepherd you through the complete NFT development process, ensuring your triumph.
          </p>
          <ul>
            <li>
              <strong>Innovative Solutions Fueling NFT Development Triumphs:</strong>
            </li>
            <p>
            In the world of NFT development, the right solutions spell the difference between triumph and stagnation. Our establishment specializes in providing pioneering NFT development solutions that are precisely attuned to your distinct requisites. Be you an artist, collector, or entrepreneur, our solutions empower you to flourish within the NFT ecosystem.
            </p>
            <li>
              <strong>Advanced Marketplace Development:</strong>
            </li>
            <p>
            Are you considering the creation of your own NFT marketplace? Our team of skilled professionals is at your service, offering secure and dependable solutions tailored to meet your distinct requirements. Through our personalized marketplace development services, you can unlock the complete capabilities of NFTs and establish a thriving ecosystem for creators, collectors, and enthusiasts.
            </p>
            <li>
              <strong>Customized Solutions For Your Unique NFT Marketplace Needs:</strong>
            </li>
            <p>
            Recognizing that no two NFT marketplaces are identical, we comprehend the significance of individuality. Our team specializes in delivering tailor-made solutions that perfectly align with your aspirations and needs. From crafting user-friendly interfaces to integrating cutting-edge functionalities, we will collaborate closely with you to craft a distinctive NFT marketplace that sets you apart.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Your Trusted NFT Development Partner
          </h2>
          <br />
          <p className="text-justify">
          Selecting the NFT development firm is the cornerstone of realizing your aspirations. Bolstered by extensive experience and a history of accomplishments, we emerge as the dependable ally you seek. Our assembly of experts collaborates closely with you to comprehend your vision, delivering remarkable NFT development solutions that transcend your anticipations.
          </p>
          <ul>
            <li>
              <strong>Vast Expertise And Proven Track Record</strong>
            </li>
            <p>
            Benefit from our well-established history and extensive proficiency within the world of NFT development. As a dependable partner, we bring a team of seasoned specialists who have successfully delivered a multitude of NFT projects, ensuring your ambitions are achieved with a touch of excellence.
            </p>
            <li>
              <strong>Collaborative Strategy For Embracing Your Vision</strong>
            </li>

            <p>
            Our ethos revolves around close collaboration with clients to genuinely grasp their vision. Our adept team will closely engage with you to deeply comprehend your objectives and aspirations, guaranteeing that the NFT development solutions we furnish transcend what you envisage.
            </p>
            <li>
              <strong>Outstanding NFT Development Offerings</strong>
            </li>
            <p>
            Our steadfast commitment to excellence propels us to provide exceptional NFT development solutions. Throughout the entire journey from idea generation to execution, we harness our expertise to craft inventive and leading-edge NFT solutions that surpass even your most optimistic anticipations.
            <br />
            In essence, selecting our NFT development services opens doors to our extensive know-how, collaborative methodology, and exceptional solutions. Allow us to stand as your reliable partner in actualizing your ambitions.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Benefits Of NFT Art:
          </h2>
          <ul>
            <li>
            1. NFT artwork presents an unparalleled and easily confirmable digital ownership encounter for both artists and collectors.
            </li>
           
            <li>
            2. This technology enables creators to monetize their digital pieces and gain direct royalties from subsequent sales.
            </li>

        
            <li>
            3. The domain of NFT art introduces a decentralized marketplace, eradicating the necessity for middlemen and empowering artists to directly engage with buyers.
            </li>
         
            <li>
            4. This trend unlocks fresh avenues for artists to delve into and experiment with digital media, broadening the confines of conventional artistic expressions.
            </li>
          
            <li>
            5. NFT art ensures transparency and unchangeability, guaranteeing the genuineness and origin of digital artworks.
            </li>
          </ul>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Go For Our NFT Art Services
          </h2>
          <ul>
            <li>
              <strong>Proficiency in NFT Artistry</strong>
            </li>
            <p>
            Leveraging our profound comprehension of the NFT art domain, we bring an extensive reservoir of knowledge and skill to the forefront. Our adept team is well-acquainted with the nuances of NFT art conception, curation, and dissemination, ensuring the distinctiveness of your NFT art within the digital expanse.
            </p>
            <li>
              <strong>Secure and Transparent Transactions</strong>
            </li>

            <p>
            Trust remains paramount in NFT art transactions. We prioritize the security and transparency of each transaction on our platform. Our resilient blockchain technology guarantees to safeguard your NFT art against unauthorized access, while lucid smart contracts instill confidence in both artists and collectors.
            </p>
            <li>
              <strong>Tailored and Personalized Solutions</strong>
            </li>
            <p>
            We acknowledge the distinctiveness of each artist and collector, prompting us to offer tailored and personalized solutions. Whether you're an emerging artist aspiring to exhibit your creations or a seasoned collector in search of exceptional and exclusive pieces, our platform is adaptable to cater to your distinct requisites and preferences.
            </p>
            <li>
              <strong>Effortless User Experience
</strong>
            </li>
            <p>
            We firmly believe that navigating the NFT art domain should be an effortless and gratifying encounter. Our user-friendly interface and instinctual features ensure artists and collectors can seamlessly present, uncover, and acquire NFT art. Prioritizing a seamless user experience enhances your expedition through the NFT art universe.
            </p>

            <li>
              <strong>Committed Support and Community</strong>
            </li>
            <p>
            By selecting us, you become an integral part of a devoted community. Our team is firm in delivering exceptional support, tending to any queries or concerns that may arise. We foster a vibrant community of artists and collectors, promoting cooperation, networking, and the flourishing of the NFT art ecosystem.
            </p>
          </ul>
        </motion.div>
        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default NFTArt