import React, { useRef, useState } from "react";
import CustomButton from "./CustomButton";
import $ from "jquery";
import Swal from "sweetalert2";

function Forms() {
  const firstName = useRef(null);
  const lastName = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const message = useRef(null);

  const [buttonText,setButtonText] = useState(false)
  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: "OK",
      confirmButtonColor: "#E62B4A",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[0-9\s\-()+]+$/;

    if (
      firstName.current.value &&
      lastName.current.value &&
      email.current.value &&
      phone.current.value &&
      message.current.value
    ) {
      if (emailRegex.test(email.current.value)) {
        if (!phoneRegex.test(phone.current.value)) {
          return showAlert("Invalid Phone Number", "Invalid Phone Number", "warning");
        } else {
          setButtonText(true)
          $.ajax({
            type: "POST",
            url: "ajax/mail.php",
            data: {
              f_name: firstName.current.value,
              l_name: lastName.current.value,
              email: email.current.value,
              phone: phone.current.value,
              message: message.current.value,
            },
            success: function (data) {
              setButtonText("done")
              setTimeout(() => {
                setButtonText(false)
              }, 1000);
              firstName.current.value =""
              lastName.current.value =""
              email.current.value =""
              phone.current.value =""
              message.current.value =""
              showAlert("Form Submit","","success")
            },
          });
        }
      } else {
        return showAlert(
          "Invalid Email",
          "Please Fill Correct Email Address",
          "warning"
        );
      }
    } else showAlert("OOPS!", "Please Fill The Form", "error");
  };

  return (
    <form method="post" className="contactFrom" id="contactForm" onSubmit={handleSubmit} >
      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 ">
        <input
          minLength={5}
          type="text"
          placeholder="First Name"
          color="blue"
          ref={firstName}
          className="border-2 border-[#D2D2D2] text-lg py-[10px] pl-5 p-2 rounded-xl bg-[#F8F8F8] hover:border-accent focus:border-blue-700"
          required
          name="f_name"
        />
        <input
          type="text"
          name="l_name"
          ref={lastName}
          placeholder="Last Name"
          className="border-2 border-[#D2D2D2]  text-lg py-[10px] pl-5 p-2 rounded-xl bg-[#F8F8F8] hover:border-accent focus:border-blue-700"
          required
        />
      </div>
      <br />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <input
          type="text"
          ref={email}
          name="email"
          placeholder="Email Address"
          className="border-2 border-[#D2D2D2]  text-lg py-[10px] pl-5 p-2  rounded-xl bg-[#F8F8F8] hover:border-accent focus:border-blue-700"
          required
        />
        <input
          type="number"
          name="phone"
          ref={phone}
          placeholder="Phone Number"
          className="border-2 border-[#D2D2D2]  text-lg py-[10px] pl-5 p-2  rounded-xl bg-[#F8F8F8] hover:border-accent active:border-blue-800"
          required
        />
      </div>
      <br />
      <textarea
        placeholder="Enter Your Message"
        className="border-2 border-[#D2D2D2] text-lg py-[10px] pl-5 p-2  rounded-xl bg-[#F8F8F8] hover:border-accent focus:border-blue-700 w-full"
        rows={5}
        ref={message}
        required
        name="con_message"
      />
      <div className="  flex justify-center mt-8">
        <CustomButton
          padding={true}
          title={buttonText ? buttonText === "done" ? "Done" : "processing..." :"Submit"}
          type="submit"
        />
      </div>
    </form>
  );
}

export default Forms;
