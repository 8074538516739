import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function WhiteBoardAnimation() {
    const data ={
        title: `Whiteboard\n Animation`,
        description:"Experience the thrill as your ideas materialize with our rough animatic, offering a sneak peek into the final animated masterpiece. Marvel as our skilled animators infuse vitality into your illustrations, crafting a dynamic and immersive whiteboard animation. Accelerate the storytelling journey with artfully designed sound effects and music that harmoniously complement your whiteboard animation.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692726282/lotties%20Json%20Files/white-board-animation_bsuho7.json",
        head:{
          title:"White Board Animation | NYC Pro Web Designers | NY 10036",
          description:"NYC's leading web designers excel in crafting compelling whiteboard animations for your brand.",
          canonical:`<link href="https://nycprowebdesigners.com/white-board-animation" rel="canonical">`
        } 
    }
    const faqs = [
      {
        question: "1. What exactly do we mean by whiteboard animated videos?",
        answer: `<p>Whiteboard animated videos are amazing and engaging visual presentations where illustrations are drawn on a background that resembles a whiteboard. These videos are often complemented by a spoken narrative.</p>`,
      },
      {
        question: "2. Why should you contemplate the creation of whiteboard animation videos?",
        answer: `<p>Whiteboard animation videos offer an effective means of conveying intricate concepts straightforwardly and attractively. They can capture attention, enhance viewer retention, and facilitate the delivery of your message in a way that sticks in the viewer's memory.</p>`,
      },
      {
        question: "3. Who stands to gain from the utilization of whiteboard animation videos?",
        answer:
          "<p>The utility of whiteboard animation videos is wide-ranging and can benefit diverse individuals and entities. These include educators, trainers, marketers, businesses, and organizations seeking to elucidate ideas, promote products or services, or captivate their audience through engaging visuals.</p>",
      },
      {
        question: "4. What is the recommended duration for a whiteboard video?",
        answer:
          "<p>Determining the ideal length for a whiteboard animation video hinges on its content and intended purpose. However, a general guideline suggests keeping the video succinct and enthralling, typically ranging from 1 to 3 minutes, to sustain the viewer's interest.</p>",
      },
      {
        question: "5. What constitutes the optimal length for a whiteboard animation video?",
        answer:
          "<p>To ensure maximum impact and engagement with the audience, it is advisable to maintain the duration of whiteboard animation videos within the range of 1 to 3 minutes. This duration allows for effective storytelling and the concise delivery of the intended message.</p>",
      },
    ];
  return (
    <ServicesPage data={data} >
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Exploring The Enchantment Of Whiteboard Animation
          </h2>
          <br />
          <p className="text-justify text-lg">
          When it comes to your tale, we play the role of detectives. To locate everything intriguing, we go quite deeply. Then, we turn your concepts into an incredible narrative using a cool technique called "whiteboard animation." It seems magical!
            <br />
            The amazing authors on our team can take your concepts and transform them into a compelling story. They bring it to life!
            <br />
            And what's this? We also have these incredibly skilled designers! They create illustrations that are so amazing that they genuinely convey the essence of your message. They seem to be able to express the essence of your story through their illustrations
            <br />
            In other words, we write your tale wonderfully and create awesome illustrations to make it awesome. It's like making a hit movie based on your ideas that everyone will enjoy!

          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Power Of Whiteboard Animation: Our Process From Idea To Masterpiece
          </h2>
          <ul>
            <li>
              <strong>
              Discovery Phase: Delving Deep into Your Story
              </strong>
            </li>
            <p>
            Our discovery process goes beyond understanding your message to exploring the heart of your story, resulting in an alluring whiteboard animation experience.
            </p>
            <li>
              <strong>Scriptwriting: Skillfully Transforming Your Ideas Into A Compelling Narrative </strong>
            </li>
            <p>
            Our team of professional scriptwriters efficiently translates your ideas into an engaging story that brings your message to life. We work closely with you to develop a script that appeals to your target audience while correctly telling your story.

            </p>

            <li>
              <strong>Illustration: Meticulously Crafting Visually Spellbinding Artwork</strong>
            </li>
            <p>
            Our talented graphic designers painstakingly craft images that are visually appealing and effectively represent the essence of your story. In close collaboration with the screenwriters, they make sure that every visual element flawlessly accentuates the narrative.
            </p>

            <li>
              <strong>Animatic: Experience The Thrill Of Your Ideas Materializing</strong>
            </li>
            <p>
            With our rough animatic, which provides a preview of the finished animated masterpiece, feel the excitement as your ideas come to life. Before we begin the final animation, our animatic gives you a preview of how your tale will be animated and enables you to make suggestions.
            </p>

            <li>
              <strong>Animation: Crafting A Dynamic And Immersive Whiteboard Animation</strong>
            </li>
            <p>
            Watch in awe as our talented animators breathe life into your illustrations to create a dynamic and appealing whiteboard animation. Our animators put in a lot of effort to master every last aspect as they bring your story to life.
            </p>

            <li>
              <strong>Sound Design: Artfully Designed Sound Effects And Music</strong>
            </li>
            <p>
            Each sound and musical cue is meticulously matched to the visuals by our sound designers and animators, enhancing the emotional impact of your story.
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Things We Are Known For;
          </h2>
          <br />
          <p className="text-justify">
          We are renowned for providing great customer service and for prioritizing the needs of our customers. Our proficiency in the field and dedication to quality set us apart from our rivals. Customers rely on us to consistently produce outstanding results. We take pride in what we do and try hard to go above and beyond;
          </p>
          <ul>
            <li>
              <strong>
              Transforming Your Complex Ideas into Effective Messages 
              </strong>
            </li>
            <p>
            Our team of experts possesses the skills and expertise needed to simplify your ideas and make them more effective. With our help, you can communicate your ideas with ease and achieve the desired impact.
            </p>
            <li>
              <strong>Streamlining Your Social Media Marketing Efforts </strong>
            </li>
            <p>
            Simplify your social media success by trusting us to manage your marketing efforts. Your social media marketing will be handled by our experts, who will streamline the procedure and offer effective results with ease. You can sit back and unwind while we take care of everything, including managing your social media accounts and providing interesting content to keep your business visible online.
            </p>

            <li>
              <strong>Get Top-Quality Services At Affordable Rates</strong>
            </li>
            <p>
            Exceptional Quality, Affordable Rates, Our pricing aligns perfectly with your budget. With our reasonable pricing structure, which never compromises the caliber and efficacy of our services, you may have the best of all worlds. You can reach your objectives without spending a fortune with our assistance. Count on us to provide top-notch services that go above and beyond your expectations.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Serving Various Sects
          </h2>
          <ul>
            <li>
              <strong>Revolutionizing Healthcare: </strong>
            </li>
            <p>
            Shaping the Future of Wellness, embark on a journey with us as we introduce groundbreaking healthcare solutions tailored exclusively for the wellness sector, fundamentally changing the way we approach well-being and health.
            </p>
            <li>
              <strong>Tech Advancements That Ignite Inspiration </strong>
            </li>

            <p>
            Empowering the Digital Landscape, and the vast potential of technology through our innovative solutions, carefully crafted to propel your business confidently into the digital era.
            </p>
            <li>
              <strong>Expanding Your Market Reach</strong>
            </li>
            <p>
            Tap into our extensive expertise in marketing and advertising to create impactful campaigns that leave an indelible mark on your audience.
            </p>
            <li>
              <strong>Cultivating Success from Within</strong>
            </li>
            <p>
            Experience a transformative shift as we assist you in crafting a distinctive corporate identity that sets you apart from competitors and fosters success.
            </p>
            <li>
              <strong>Navigating the Path to Prosperity</strong>
            </li>
            <p>
            Explore our comprehensive financial services tailored to streamline processes and fuel growth within the ever-evolving finance industry.
            </p>
            <li>
              <strong>Inspiring Minds, Shaping Futures</strong>
            </li>
            <p>
            Join us in the transformation of education with innovative solutions that engage, inspire, and empower the upcoming generation, shaping the leaders of tomorrow
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>  
  
  )
}

export default WhiteBoardAnimation