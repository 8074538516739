import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function VideoAnimation() {
    const data ={
        title: `Video\n Animation`,
        description:"Do you desire to leave a lasting impact on your audience? Our personalized video animation services are your perfect solution! Our team of skilled animators is poised to transform your ideas into visually striking animations that will truly resonate.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712601/lotties%20Json%20Files/VideoAnimation_w4qqwk.json",
        head:{
          title:"Video Animation Services | NYC Pro Web Designers | NY 10036",
          description:" Transform your ideas into engaging visuals with NYC's pro web designers and video animation services.",
          canonical:`<link href="https://nycprowebdesigners.com/video-animation-services" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. What kinds of video animations do you provide?",
        answer: `<p>We offer a diverse array of video animations, encompassing both 2D and 3D styles, explainer videos, and meticulous frame-by-frame techniques.</p>`,
      },
      {
        question: "2. Could you make personalized video animations to suit my precise requirements?",
        answer: `<p>Certainly! Our expertise lies in crafting custom video animations that align perfectly with your distinct needs and brand characteristics.
        </p>`,
      },
      {
        question: "3. How long does it usually take to finalize a video animation project? ",
        answer:
          "<p>The timeline for video animation projects can fluctuate based on the intricacy and length of the animation. We collaborate closely with you to set practical schedules and furnish top-notch animations within the agreed timeframe.</p>",
      },
      {
        question: "4. Are voice-over services available for the video animations?",
        answer:
          "<p>Yes, we furnish professional voice-over services to increase your video animations. Our skilled voice artists can infuse your script with alluring voices to bring it to life.</p>",
      },
      {
        question: "5. Can you aid in scripting for video animations?",
        answer:
          "<p>Of course! Our team of seasoned content writers can assist in crafting engaging and persuasive scripts for your video animations, ensuring they effectively convey your message and captivate your audience.</p>",
      },
    ];
  return (
    <ServicesPage data={data}>
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Craft Your Concepts into Dynamic Video Animation Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          In search of fantastic animated videos? We have your back! Our team of professionals is great at producing entertaining videos that viewers like watching. We can create a video that promotes your business or demonstrates how something works.
            <br />
            We'll collaborate closely with you to fully grasp your brand and create the ideal video for you. We take great care to ensure that every last detail is impeccable from start to finish. The videos we produce are so incredible that they surpass your expectations.
            <br />
            With vibrant and attractive videos, our skilled animators, who resemble artists, bring your ideas to life. We can handle all of your video production needs, whether you want a unique video or simply need some assistance. We're here to add some awesomeness and distinction to your videos.
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Comprehensive Service Array
          </h2>
          <br />
          <p className="text-justify">
          From 2D animations to explanatory video animations, meticulously crafted frame-by-frame creations to immersive 3D animations, we offer a full suite;
          </p>
          <ul>
            <li>
              <strong>2D Animation </strong>
            </li>
            <p className="text-justify text-lg">
            Our skilled animators possess the artistry to craft vibrant and spellbinding 2D animations. Whether you require a promotional video, character animation, or motion graphics project, we will breathe life into your concepts using inventiveness and precision.
            </p>

            <li>
              <strong>
              Explanationartory Videos
              </strong>
            </li>

            <p className="text-justify text-lg">
            We will morph your message into a visually gripping narrative that seizes the attention of viewers and leaves an enduring impression.
            </p>
            <li>
              <strong>
              Frame-By-Frame 
              </strong>
            </li>
            <p className="text-justify text-lg">
            If you seek a more traditional, artisanal approach, our frame-by-frame animations are the ideal choice. With scrupulous attention to detail, we will fabricate stunning visuals that spotlight the allure of each frame.
            </p>

            <li>
              <strong>
              3D Animations
              </strong>
            </li>
            <p className="text-justify text-lg">
            From visualizing products to conducting architectural walkthroughs, we will fabricate immersive encounters that etch a lasting mark on your audience
            </p>

            <li>
              <strong>
              Educational Videos
              </strong>
            </li>
            <p className="text-justify text-lg">
            Fascinate and Educate with Engaging Educational Videos that infuse fun and interactivity into learning. Our team of specialists brings intricate concepts to life through alluring visuals and clear explanations.
            </p>

            <li>
              <strong>
              Corporate Videos
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our tailor-made corporate videos spotlight your company's distinctive narrative, values, and products. With our inventive approach, we assist you in making a lasting mark on your intended audience.
            </p>

            <li>
              <strong>
              Animated Music Videos
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our animated music videos are meticulously designed to complement your music, enhancing the overall experience. We collaborate closely with you to craft a visual masterpiece that deeply resonates with your audience.
            </p>

            <li>
              <strong>
              Video Ad Production
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our team excels in creating compelling, attention-commanding video ads that leave a powerful imprint on viewers.
            </p>

            <li>
              <strong>
              Medical Videos
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our specialized knowledge in the field equips us to craft informative and visually appealing videos that adeptly communicate complex medical information.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Animation Process
          </h2>
          <br />
          <p className="text-justify">
          We meticulously adhere to a process that guarantees seamless execution of every project.
          <br />
          Here's a breakdown of our animation service process:
          </p>
          <ul>
            <li>
              <strong>
              Information Gathering: 
              </strong>
            </li>
            <p>
            We initiate by collating all essential project details, encompassing your objectives, target audience, and desired animation style. This process aids us in comprehending your vision and delivering a bespoke solution.
            </p>
            <li>
              <strong>Scripting & Storyboarding: </strong>
            </li>
            <p>
            Our proficient team of scriptwriters and storyboard artists collaborate to develop a compelling script and visually chart out scenes. This stage lays the groundwork for the animation journey.
            </p>
            <li>
              <strong>Scene Sequencing: </strong>
            </li>
            <p>
            With the script and storyboard finalized, we progress to scene sequencing. This entails determining scene order, transitions, and timing to ensure a seamless animation flow.
            </p>
            <li>
              <strong>Essential Insights: </strong>
            </li>
            <p>
            We integrate fundamental analytics to track vital metrics like engagement, views, and click-through rates. This data yields invaluable insights into your animation's performance.
            </p>

            <li>
              <strong>Voiceover And Animation Creation:  </strong>
            </li>
            <p>
            Our adept voiceover artists breathe life into characters with their appealing voices. Concurrently, our animators meticulously craft each frame of the animation to perfection.
            </p>

            <li>
              <strong>Validation: </strong>
            </li>
            <p>
            Your contribution and insights are highly valuable to us during every stage of the process. Once the animation is complete, we showcase it for your endorsement. We're committed to revisions to ensure your satisfaction.
            </p>
            <li>
              <strong>Delivery: </strong>
            </li>
            <p>
            After your approval, we furnish you with the final files in your preferred format. You can effortlessly download and utilize the animation as intended.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Discover The Advantages Of Collaborating With Our Premier Animation Studio
          </h2>
          <br />
          <p className="text-justify">
          Aligning with our foremost animation studio offers a multitude of perks that will enhance your brand's visibility and captivate your target audience. 
          <br />
          Here's why picking us is the right choice:
          </p>
          <ul>
            <li>
              <strong>Top-Quality Service: </strong>
            </li>
            <p>
            As providers of exceptional service, we prioritize delivering top-tier quality in every facet of our work. From the initial idea to the outcome, we ensure your animated videos are of the highest caliber, leaving a memorable mark.

            </p>
            <li>
              <strong>Functional Animation Mastery: </strong>
            </li>

            <p>
            Our adept animators possess the know-how to breathe functionality into animations. Whether it's elucidating intricate concepts, displaying product attributes, or simplifying procedures, our animations are crafted to effectively engage and educate your audience.
            </p>
            <li>
              <strong>Cinematic Excellence:  </strong>
            </li>
            <p>
            We commit to producing animations with a cinematic flair, resulting in visually breathtaking and appealing content. With meticulous attention to detail and cutting-edge techniques, your videos will possess that awe-inspiring element that differentiates them.
            </p>

            <li>
              <strong>Kinematic Expertise: </strong>
            </li>
            <p>
            Our animators are well-versed in the art of kinematic formulas. By applying these principles, we instill fluidity and lifelike movement into characters and objects, generating a genuine and immersive viewer experience.
            </p>

            <li>
              <strong>Boost SEO And Conversions:</strong>
            </li>
            <p>
            Beyond their visual allure, animated videos are a potent tool for augmenting SEO and conversion rates. Through strategic incorporation of your keywords within the video content, we enhance your search engine rankings and drive increased conversions for your business.
            </p>


            <li>
              <strong>Spectacular Simulations And Effects: </strong>
            </li>
            <p>
            We specialize in crafting remarkable simulations and effects that infuse an extra layer of excitement into your animated videos. Whether it involves authentic physics simulations, attractive particle effects, or dynamic transitions, rest assured that your videos will make an enduring impact.
            </p>
          </ul>
        </motion.div>



        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default VideoAnimation