import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from '../../Components/Accordian'

function BookMarketing() {
    const data ={
        title: `Book\n Marketing`,
        description:"As a renowned book marketing firm, we specialize in crafting tailored campaigns to assist authors like yourself in reaching a broader audience and boosting book sales. Our team of seasoned marketers will collaborate closely with you to create effective strategies that align with your book's genre, target readership, and objectives. With our established track record and innovative methods, we'll help you shine in the competitive book market and enhance your book's visibility.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712599/lotties%20Json%20Files/bookmarketing_ju0xfe.json",
        head: {
          title:"Book Marketing Services | NYC Pro Web Designers | NY 10036",
          description:"NYC's web design experts offer tailored book marketing services for successful author branding.",
          canonical: `<link href="https://nycprowebdesigners.com/book-marketing-services" rel="canonical">`,
        },
    }
     const faqs = [
    {
      question: "1. How long does it take for book promotion to start producing results?",
      answer: `<p>Depending on the size of the campaign, the target market, and the chosen marketing tactics, the timescale for book marketing results can change. </p>`,
    },
    {
      question: "2. What is the cost of book marketing?",
      answer: `<p>The expenses associated with book marketing services can differ based on specific needs, the scale of the campaign, and an author's objectives. It's advisable to have a conversation with a book marketing professional to receive a tailored cost estimate.</p>`,
    },
    {
      question: "3. Is a substantial social media following necessary for effective book marketing?",
      answer:
        "<p>Although having a strong presence on social media can be advantageous, it's not the sole determinant of a successful book marketing effort. A targeted approach, captivating content, and strategic promotion can help reach your desired audience, even if you're starting with a smaller following.</p>",
    },
    {
      question: "4. How can we gauge the success of a book marketing campaign?",
      answer:
        "<p>Evaluating the triumph of a book marketing campaign involves considering various indicators, such as book sales, reader engagement, website traffic, social media outreach, and reviews. A thorough analysis of these metrics aids in assessing the campaign's effectiveness.</p>",
    },
    {
      question: "5. Can I handle book marketing independently?",
      answer:
        "<p>While managing certain aspects of book marketing on your own is feasible, collaborating with a professional book marketing team offers expertise, industry connections, and a comprehensive strategy to amplify your book's reach and impact</p>",
    },
  ];
  return (
 <ServicesPage data={data}>
  <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Professional Book Marketing Services and Strategies!
          </h2>
          <br />
          <p className="text-justify text-lg">
          As you would share your favorite video game or movie with your pals, book marketing is the equivalent of letting everyone know about a particularly amazing book you wrote or loved. Consider wanting everyone to try your secret recipe for wonderful cookies. You would need to create brochures, inform your friends, and perhaps even post images online to demonstrate how delicious the cookies are.
            <br />
            Publishers and authors both engage in comparable book marketing strategies. They create eye-catching book covers with vibrant colors, provide information about the book on the back, and sometimes even include reviews from fans. To disseminate the word, they employ social media, websites, and occasionally even bookstores.
            <br />
            Authors may also make appearances in libraries or schools to discuss their works. Authors want everyone to read their fantastic books, just like you would want everyone to try your amazing cookies, and marketing helps them do so!
            <br />
            From alluring social media initiatives to precise email marketing, we possess the know-how to promote your book across a variety of platforms and channels. 
            <br />
            Don't let your book go unnoticed. Join forces with us, and let's work together to make your book a bestseller!
            <br />
            Get in touch with us today to discuss your book marketing requirements.
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Benefits of Book Marketing
          </h2>
          <br />
          <ul>
            <li>
              <strong>Increased Book Exposure:   &nbsp;</strong>
              Efficient book marketing tactics raise your book's recognition among your target demographic. Higher revenue from publishing and recognition could stem from this broadened visibility.
            </li>
            <li>
              <strong>Wider Audience Outreach: &nbsp;</strong>
              Book marketing allows you the opportunity to get acquainted with people outside of your social group. You can interact with readers who might not have otherwise discovered your book with targeted marketing, social media promotions, and email promos.
            </li>

            <li>
              <strong>Strengthened Author Identity:  &nbsp;</strong>
              Book marketing permits you to develop your literary persona and cultivate a reputation among readers as an enduring and competent writer. You can develop an engaging presence with readers by continuously promoting your work.
            </li>

            <li>
              <strong>Accelerated Book Sales:  &nbsp;</strong>
              Your book sales can be dramatically impacted by a well-executed book marketing strategy. You may convince more people to buy your book by efficiently reaching the proper audience with amazing content and alluring offers.
            </li>

            <li>
              <strong>Higher Book Reviews and Ratings:   &nbsp;</strong>
              As part of book marketing campaigns, it's common to contact bloggers, influencers, and reviewers to increase reviews and ratings. Positive reviews can have a significant impact on potential readers' choices and help your book succeed as a whole.
            </li>

            <li>
              <strong>Better Author-Reader Engagement: &nbsp;</strong>
              Through social media, email newsletters, and book-related events, book marketing enables you to directly communicate with your fans. This interaction develops a devoted fan base and motivates readers to follow your upcoming endeavors.
            </li>

            <li>
              <strong>Potential for Book Adaptations: &nbsp;</strong>
              Film producers, agencies, and publishers looking for fascinating stories to adapt into films or television shows may become interested in books with effective marketing. This creates intriguing opportunities for adapting your book for the big screen.
            </li>
            <li>
              <strong>Continuous Book Promotion: &nbsp;</strong>
              Your book's success might be affected by your efforts in book marketing. Even after the original release, continual marketing initiatives can keep your book relevant and boost sales in the long run.
            </li>
           
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Process Of Marketing A Book Involves Several Crucial Stages:
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Research and Planning: 
              </strong>
            </li>
            <p>
            We kick off by conducting thorough market research to identify your book's ideal readers and gain insights into the competitive landscape. From there, we create a personalized marketing strategy tailored to your book's unique strengths.
            </p>
            <li>
              <strong>Building Your Brand and Positioning: </strong>
            </li>
            <p>
            We focus on crafting a compelling author brand and positioning your book effectively in the market. This includes designing attractive book covers, author bios, and promotional materials that resonate with your target audience.
            </p>
            <li>
              <strong>Establishing an Online Presence and Promotion: </strong>
            </li>
            <p>
            We enhance your online presence through strategic social media marketing, author website development, and search engine optimization (SEO) techniques. We produce engaging content, run targeted advertising campaigns, and connect with influential bloggers and reviewers to generate excitement and draw in readers.
            </p>
            <li>
              <strong>Launching Your Book and Hosting Events: </strong>
            </li>
            <p>
            We help plan and execute successful book launches, whether they're virtual or in-person. We coordinate events, secure media coverage, and leverage opportunities for author appearances and book signings to create a buzz and boost sales.
            </p>
            <li>
              <strong>Securing Reviews and Endorsements: </strong>
            </li>
            <p>
            We assist in obtaining honest and favorable reviews from reputable sources, building trust and credibility among readers. We also seek endorsements from influential figures in the industry to further accelerate your book's reputation.
            </p>
            <li>
              <strong>Sustained Marketing and Promotion: </strong>
            </li>
            <p>
            We continue to promote your book through various channels, including social media, email marketing, book tours, and collaborations with fellow authors and influencers. We closely monitor outcomes, make necessary adjustments, and provide regular updates on the progress of your book marketing campaign.
            </p>
            <p className="text-justify">
            Let's hop on this thrilling journey of book marketing together and ensure your book shines its brightest!
            </p>
          </ul>
        </motion.div>
        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
  </ServicesPage>
  )
}

export default BookMarketing