import React from "react";
import ServicesPage from "../../Components/ServicesPage";
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";

function Shopify() {
  const data = {
    title: `Shopify`,
    description:
      "Are you prepared to expand your online business? Our team is here to accompany you on your path to success and is an expert in Shopify development. Let's work together to advance your online business and accomplish your objectives. Discover how your online presence can be transformed by our Shopify expertise. Join our team to realize the full potential of your internet business.",
    upperStyle: false,
    font: false,
    api: "https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712600/lotties%20Json%20Files/shopify_smcx6p.json",
    head: {
      title: " Shopify Development Services | NYC Pro Web Designers | NY 10036",
      description:
        "Elevate your online shop with our Shopify Development Services. We create and enhance your e-commerce platform for success",
      canonical: `<link href="https://nycprowebdesigners.com/shopify-development-services" rel="canonical">`,
    },
  };


  const faqs =[
    {
        question: '1. Distinguishing Shopify from Shopify Plus.',
        answer:`<p>Shopify serves as an ideal choice for smaller to medium-sized businesses, whereas Shopify Plus caters to larger enterprises. Shopify Plus offers advanced attributes such as expandability, personalization, and dedicated support.</p>`
    },
    {
        question:"2. What is the timeframe for Constructing a Shopify Site?",
        answer:`<p>The duration required to establish a Shopify site can fluctuate based on the intricacy of your prerequisites. Crafting a fully operational Shopify site usually necessitates several weeks to a couple of months.<p>
        `
    },
    {
      question: '3. Why NYC Experts for Shopify Site Modifications?',
      answer:`<p>For refining your Shopify site, collaborating with adept professionals like *NYC Pro Web Designers*, situated in New York City, can prove highly beneficial. These experts possess invaluable insights, proficiency, and tailor-made solutions to meet your distinct demands. Reaching out to local agencies or experts in Shopify development is advisable.
      </p>`
  },
  {
    question: '4. What are the merits of Engaging a Shopify Online Store Development Firm?',
    answer:`<p>
    Enlisting the services of a Shopify online store development agency offers a multitude of advantages. These firms possess specialized expertise and hands-on experience in erecting prosperous Shopify stores. They provide expert counsel, manage technical facets, ensure a seamless development trajectory, and deliver a top-notch, personalized online store.</p>`
  }  
  ]
  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Exceptional Shopify Development Services:
          </h2>
          <br />
          <p className="text-justify text-lg">
            We can design a unique Shopify store specifically for you; we're
            like online store magicians. Your brand and aspirations will feel at
            home there.
            <br />
            Everything in your store will be really simple to utilize for
            customers, much like a skateboard ride. And what's this? Because of
            how quick your store will be, like a supercharged race vehicle,
            customers won't have to wait.
            <br />
            The best thing, though? Your online store will stand out in the vast
            internet universe like a bright star in the sky. We warrant that
            your store will be distinctive, just like you!
            <br />
            We'll create a terrific online store for you that's simple to use,
            really quick and distinguishes your company's identity.
          </p>

          <ul>
            <li>
              <strong>Bring Your Vision To Life:</strong>
            </li>
            <p className="text-justify text-lg">
              We specialize in tailoring Shopify development to your unique
              ideas, transforming them into a fully operational and visually
              alluring online store. Our team of skilled developers and
              designers will collaborate closely with you to grasp your needs
              and materialize your vision. From integrating distinct features to
              ensuring adaptability across devices, we meticulously attend to
              every detail to shape a truly distinctive Shopify store.
            </p>

            <li>
              <strong>Craft An Impressive Shopify Store: </strong>
            </li>

            <p className="text-justify text-lg">
              The initial impression holds great significance, particularly in
              the digital sphere. Through our Shopify website design services,
              we curate striking and attractive designs that forge a lasting
              impact on your customers. Our group of talented designers will
              work closely with you to understand your brand's essence and
              fashion an aesthetically pleasing store that mirrors your unique
              flair. From selecting the ideal color palette to constructing
              intuitive navigation, we address every design facet to guarantee a
              seamless user experience.
            </p>
            <li>
              <strong>Enhance Your Online Presence:</strong>
            </li>
            <p className="text-justify text-lg">
              In today's fiercely competitive digital arena, optimizing your
              online presence is paramount. Our services in SAAS development are
              poised to aid you in achieving just that. From transforming ideas
              into tangible products to shaping brand design, and SaaS
              consulting to SaaS UX and UI design, our comprehensive array of
              services ensures your SAAS platform garners attention. We assist
              you in refining your online presence and propelling your
              achievements.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Begin Your Online Store With Shopify With Ease
          </h2>
          <br />
          <p className="text-justify">
            Ready to start your journey into the world of online selling? Look
            no further, your reliable partner for establishing a thriving
            Shopify store is here. Our team of seasoned developers is ready to
            guide you at every step of the process.
          </p>
          <ul>
            <li>
              <strong>Establish Your Shopify Store: &nbsp;</strong>
              Leave the technical details in our capable hands as our experts
              set up your Shopify store. From adjusting store preferences to
              crafting a unique design, we ensure a seamless and visually
              pleasing online platform.
            </li>

            <li>
              <strong>Employ Dedicated Shopify Developers:&nbsp;</strong>
              Need extra help with your Shopify store? Our skilled Shopify
              developers are at your service for any modifications, app
              integrations, or enhancements to functionalities. Allow us to
              transform your distinct vision into reality.
            </li>
            <li>
              <strong>Effortless Dropshipping on Shopify Service:&nbsp;</strong>
              Eager to streamline your business operations? Our dropshipping
              setup service simplifies inventory management and order
              fulfillment without the complexities of storing and shipping
              items. We integrate the essential tools and platforms, making
              dropshipping effortless.
            </li>
           
            <br />
            <p>
              We grasp the significance of SEO-driven content. Our adept content
              creators will fashion attention-grabbing headings and distinct
              content that not only captivate your audience but also accelerate
              your search engine rankings.
              <br />
              Embark on your e-commerce venture today, and let us assist you in
              constructing a standout Shopify store that outshines competitors.
              Reach out to us now to converse about your unique requirements and
              objectives.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Efficient Process For Achieving Success - Our Workflow Discovered
          </h2>
          <br />
          <p className="text-justify">
          We've established a well-organized system to guarantee a smooth encounter for our customers. 
            <br />
            This is how we operate:
          </p>
          <ul>
            <li>
              <strong>Initial Discussion and Defining Requirements: </strong>
            </li>
            <p>
            We start our trip by having a lengthy and in-depth discussion with you. This conversation strives to fully comprehend your particular wants and demands. Through this approach, we can clearly understand your aims and aspirations, which form the basis for creating custom solutions that are exactly in line with your expectations.
            </p>
            <li>
              <strong>Proposal of Schedule and Agreement on Cost: </strong>
            </li>
            <p>
            We methodically construct a comprehensive proposal for your project after fully knowing your unique needs and goals. This thorough proposal comes with a properly laid out project schedule that identifies important deadlines and milestones. In addition, we offer a clear analysis of the project's cost that includes all pertinent costs, giving you total insight into the monetary components of your project. Our dedication to openness makes sure that you have all the information you need upfront, enabling you to make decisions with assurance.
            </p>
            <li>
              <strong>Guarantee and Support After Deployment: </strong>
            </li>
            <p>
            We guarantee the excellence of our work and provide a post-project warranty period to address any unanticipated problems. To preserve top performance and dependability for your website or application long after the project is finished, we also offer continuing post-release maintenance.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Earning Potential Of Your Shopify Shop
          </h2>
          <br />
          <p className="text-justify">
          Our services encompass personalized app development, seamless integration with external systems, smooth data import and export, creation of adaptable themes, effortless migration to/from Shopify, and ongoing upkeep – all geared toward boosting your store's profit margins.
          </p>
          <ul>
            <li>
              <strong>Crafting Tailored Apps: </strong>
            </li>
            <p>
            Our adept developers can design custom applications to precisely match your business requirements. These apps will enhance functionality, introduce distinctive features, and ultimately bolster sales and customer interaction.
            </p>
            <li>
              <strong>Linking with External Systems: </strong>
            </li>

            <p>
            Effortlessly unite your Shopify store with other platforms and systems to simplify operations, broaden your reach, and optimize business processes.
            </p>
            <li>
              <strong>Efficient Data Management:</strong>
            </li>
            <p>
            Our data import and export services ensure efficient control over your product inventory, customer particulars, and order data. This translates to seamless operations and precise reporting.
            </p>

            <li>
              <strong>Adaptable Theme Creation: </strong>
            </li>
            <p>
            Lift user experience and heighten conversion rates with visually appealing, mobile-friendly themes that seamlessly adapt to any device. This ensures a smooth shopping experience for all customers.
            </p>

            <li>
              <strong>Smooth Migration Experience:  </strong>
            </li>
            <p>
            Whether you're transitioning to Shopify or migrating elsewhere, our experts can oversee the process, ensuring a secure and seamless transition devoid of data loss or operational disturbances.
            </p>

            <li>
              <strong>Sustaining Performance:  </strong>
            </li>
            <p>
            Keep your Shopify store operating seamlessly with our holistic maintenance services. We offer routine updates, enhanced security, and performance optimization.
            </p>
            <p>
            Incorporating SEO-driven content strategies, we can enhance your store's visibility, draw in organic traffic, and magnify conversions. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Advantages Of Enhanced Shopify Solutions!
          </h2>
          <ul>
            <li>
              <strong>Global Expansion:  </strong>
            </li>
            <p>
            Unlock the potential of advanced Shopify services to broaden your online enterprise and connect with customers from all corners of the globe. Venture into fresh markets, enlarging your clientele significantly.
            </p>
            <li>
              <strong>Mobile Excellence:  </strong>
            </li>

            <p>
            Raise your online emporium with advanced Shopify services, guaranteeing a fully streamlined encounter for users browsing through mobile gadgets. This ensures smooth shopping on smartphones and tablets alike.
            </p>
            <li>
              <strong>Data and Insights: </strong>
            </li>
            <p>
            Gather an invaluable understanding of customer preferences and actions using Shopify's advanced analytic tools. Grasp popular products, monitor sales trends, and make informed choices to foster business growth.
            </p>

            <li>
              <strong>Recovering Abandoned Carts:  </strong>
            </li>
            <p>
            Minimize missed sales by employing Shopify's feature to recover abandoned carts. Swift reminders are dispatched to customers who left items behind, motivating them to finalize their purchases.
            </p>

            <li>
              <strong>Effortless Payment Alternatives:  </strong>
            </li>
            <p>
            A wide array of payment gateways from Shopify, let patrons pick their favored transaction mode. From credit cards to digital wallets, extend a convenient and secure checkout process.
            </p>

            <li>
              <strong>Integration of Applications:  </strong>
            </li>
            <p>
            Amplify your digital shop's capabilities by seamlessly integrating assorted apps and add-ons from the Shopify App Store. From stock management to patron assistance, a plethora of tools awaits to augment your business operations.
            </p>
            <br />
            <p>
            You can propel your online venture to fresh altitudes, boost conversions, and furnish customers with an unparalleled shopping expedition through these advantages.
            </p>
          </ul>
      
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  );
}

export default Shopify;
