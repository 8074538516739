import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import Accordion from '../../Components/Accordian'
import { motion } from 'framer-motion'

function CGIArt() {
    const data ={
        title: `CG &  CGI \n Art`,
        description:"Welcome to the mesmerizing domain of CG & CGI Artistry! If you're in search of breathtaking visuals, interactive adventures, and a journey into the world of creativity, you've landed in the perfect spot. We are dedicated to transforming ideas into alluring digital wonders. Let's dive into the thrilling universe of CG and CGI Art and see how our services can enhance your creative pursuits.",
        upperStyle:true,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/CgiArt_ih0ecg.json",
        head:{
          title:" CG Artists| NYC Pro Web Designers | NY 10036",
          description:" Elevate your digital presence with CG artists and web designers in NYC's NY 10036, delivering excellence.",
          canonical:`<link href="https://nycprowebdesigners.com/cgi-art" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What is Computer Graphics and Computer-Generated Imagery Art (CG and CGI Art)?",
        answer: `<p>CG and CGI Art, which stands for Computer Graphics and Computer-Generated Imagery Art, involves crafting captivating visuals through digital techniques and software.</p>`,
      },
      {
        question: "2. How much time does it take to produce CG and CGI Art?",
        answer: `<p>The time needed to craft CG and CGI Art varies based on the project's intricacy and scale. It can span from a few days to several weeks or even months for more extensive undertakings.
        </p>`,
      },
      {
        question: "3. Which software and tools are utilized in CG and CGI Art?",
        answer:
          "<p>CG and CGI Art come to life using an array of software and tools, including renowned applications like Autodesk Maya, Cinema 4D, Blender, and Adobe Creative Suite. These tools empower artists to transform their concepts into precise and lifelike creations.</p>",
      },
      {
        question: "4. Can you generate bespoke CG and CGI Art rooted in specific concepts or notions?",
        answer:
          "<p>Certainly! Our team of proficient artists can collaborate closely with you to grasp your concepts and ideas, fashioning tailored CG and CGI Art that mirrors your vision.</p>",
      },
      {
        question: "5. In which industries can CG and CGI Art be advantageous?",
        answer:
          "<p>CG and CGI Art holds relevance across a spectrum of industries, encompassing film and entertainment, advertising, architectural visualization, product presentation, gaming, and immersive virtual reality experiences. It serves as a versatile and visually enthralling medium for conveying ideas and captivating audiences.</p>",
      },
    ];
  return (
    <ServicesPage data={data}>
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Explore the World of CG & CGI Art
          </h2>
          <br />
          <p className="text-justify text-lg">
            Consider yourself an artist, but rather than using paints and brushes, you create magnificent images and even entire universes using a computer
            <br />
            That is the main focus of CG and CGI art!
            <br />
            CG, or "Computer Graphics," refers to the creation of visuals and images using computers. Like digital art, really. You may sketch, color, and create a variety of objects with specialized software, from adorable characters to beautiful landscapes.
            <br />
            These days, CGI stands for "Computer-Generated Imagery." It's like a mystical instrument that aids game developers and filmmakers in bringing their visions to reality. They employ computers to create things that, despite maybe not existing in reality, look quite lifelike. Think of the incredible animals in your favorite films or the mind-blowing visuals in video games; most of those were created with computer graphics.
            <br />
            Therefore, CG and CGI art is like the superpower of your computer for producing wonderful images and realizing incredible adventures!
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Everything Incredibly Concerning & CG Artists' Roles
          </h2>
          <br />
          <p className="text-justify">
          CG Artists are the magicians who wield software to craft intricate 2D and 3D designs. They breathe life into once-impossible ideas, turning dreams into digital marvels. Whether it's crafting lifelike characters, architectural renderings, or breathtaking landscapes, CG Artists are the architects of the virtual world.
          </p>
          <ul>
            <li>
              <strong>
              CG Visuals
              </strong>
            </li>
            <p>
            Our best CG Artists use their expertise to craft top-notch visuals nearly indistinguishable from reality. From product prototypes and architectural designs to enthralling concept art, our CG visuals can captivate your audience, leaving a lasting impression and conveying your vision with unparalleled precision.
            </p>
            <li>
              <strong>Animation</strong>
            </li>
            <p>
            Animation is the heartbeat of the digital world. Our animation services infuse static images with life, creating movement, emotion, and narratives that deeply resonate with your audience. Whether you're seeking to craft a captivating marketing video, an educational animation, or a cinematic masterpiece, our CG Artists possess the skills to breathe life into your ideas, frame by frame.
            </p>

            <li>
              <strong>Interactive Experiences</strong>
            </li>
            <p>
            Interactive experiences are the core of modern digital media. Our CG and CGI Artwork extends to the world of games and interactivity. Our artists excel in creating immersive game environments, lifelike characters, and stunning visual effects that draw players into your game world. With our interactive solutions, you can engage your audience on a whole new level, leaving them craving for more.
            </p>
          </ul>
         
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Advantages of Our CG & CGI Art Services
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Unleash Creativity: 
              </strong>
            </li>
            <p>
            Our CG and CGI artistry knows no limits. We transform your boldest ideas into stunning visuals that inspire awe.
            </p>
            <li>
              <strong>Engage Your Audience:</strong>
            </li>
            <p>
            Whether through eye-catching images, compelling animations, or interactive experiences, our services help you connect with your audience on a deeper level.
            </p>

            <li>
              <strong>Boost Sales:</strong>
            </li>
            <p>
            High-quality visuals and interactive content increase customer engagement, ultimately driving sales and conversions.
            </p>

            <li>
              <strong>Efficiency & Cost Savings:</strong>
            </li>
            <p>
            Skip expensive photoshoots or elaborate set designs. With CG art, you can achieve equivalent or superior results at a fraction of the cost.
            </p>

            <li>
              <strong>Flexibility & Adaptability: </strong>
            </li>
            <p>
            Need changes? No problem! Our digital creations are easily editable and adaptable, ensuring your content stays up to date.
            </p>

            <li>
              <strong>Brand Identity: </strong>
            </li>
            <p>
            Stand out from the competition with unique and visually striking content that solidifies your brand's identity.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Opt for Our CG and CGI Art Services:
          </h2>
          <br />
          <ul>
            <li>
              <strong>Cutting-Edge Tech: </strong>
            </li>
            <p>
            We make use of the latest software and tools to craft mesmerizing visuals with unmatched realism and intricate details.
            </p>
            <li>
              <strong>Diverse Industry Expertise: </strong>
            </li>
            <p>
            Whether it's architectural visualization, product design, or character animation, we possess experience across various sectors to meet your unique requirements.
            </p>

            <li>
              <strong>Obsessive About Details: </strong>
            </li>
            <p>
            We meticulously attend to every facet of our work, ensuring that each pixel is flawless, and every frame narrates a compelling story.
            </p>

            <li>
              <strong>Punctual Delivery: </strong>
            </li>
            <p>
            Recognizing the significance of deadlines, we strive to deliver your projects on schedule, upholding uncompromising quality.
            </p>

            <li>
              <strong>Collaborative Spirit: </strong>
            </li>
            <p>
            We firmly believe in collaborating closely with our clients, involving them in the creative journey to ensure their vision comes to life.
            </p>
            <li>
              <strong>Competitive Pricing: </strong>
            </li>
            <p>
            Our services come at competitive rates, offering outstanding value for the exceptional quality of craftsmanship we deliver.
            </p>
            <li>
              <strong>Client Delight: </strong>
            </li>
            <p>
            We boast a track record of contented clients who have lauded our professionalism, creativity, and knack for surpassing expectations.
            <br />
            In conclusion, the world of CG & CGI Art offers endless creative possibilities, where imagination knows no boundaries. we're here to transform your visions into astonishing digital realities. Whether it's CG Visuals, Animation, Interactive Experiences, or anything in between, our team of CG Artists is prepared to bring your ideas to life. Join us in this digital adventure, and together, let's create something extraordinary!
            <br />
            Contact us today to explore the enchantment of CG & CGI Artistry!
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
      </ServicesPage>
  )
}

export default CGIArt