import React from 'react'
import { useNavigate } from 'react-router-dom'
function SimpleButton({title,bg,color,hover,to}) {

  const navigate = useNavigate()
  return (
    <button onClick={()=>to ? navigate(to) : navigate("/contact")} className={`xsm:text-sm rounded-full border-[2px] mm:text-lg  py-2 px-6 ${bg} ${color} shadow-md border-[#E14242] ${hover} transition-all duration-700  tracking-[3px] font-semibold`}>{title}</button>
  )
}

export default SimpleButton