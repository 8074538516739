import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function MedicalVideos() {
    const data ={
        title: `medical Videos`,
        description:"Welcome to a world where the art of communication meets the precision of medicine. Our team of dedicated experts is committed to crafting engaging and informative medical videos that cater to your unique needs. Join us as we embark on a journey to bridge the gap between complex medical concepts and accessible understanding.",
       upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/medical_video_r8mgit.json",
        head:{
          title:"Medical Videos | NYC Pro Web Designers | NY 10036",
          description:" NYC's leading web designers specialize in creating engaging medical videos for your audience.",
          canonical:`<link href="https://nycprowebdesigners.com/medical-videos" rel="canonical">`
        }

        
    }

    const faqs = [
      {
        question: "1. What advantages do medical videos offer for patient education?",
        answer: `<p>Medical videos efficiently educate patients by visually simplifying intricate medical ideas, processes, and treatments. They enhance comprehension, upgrade patient engagement, and empower individuals to make well-informed choices about their healthcare.</p>`,
      },
      {
        question: "2. How might medical videos be harnessed for healthcare marketing?",
        answer: `<p>Medical videos serve as a potent instrument for healthcare marketing. They exhibit medical establishments, accentuate services, and offer endorsements from content patients. Such videos foster credibility, allure fresh patients, and set your healthcare practice apart.</p>`,
      },
      {
        question: "3. What does the procedure entail for fabricating medical videos?  ",
        answer:
          "<p>Creating medical videos usually involves three stages: pre-production, production, and post-production. Pre-production entails strategizing, scripting, and storyboarding. Production encompasses filming and capturing vital footage. Post-production entails editing, incorporating graphics, and finalizing the video.</p>",
      },
      {
        question: "4. Are there legal aspects to contemplate during medical video production?",
        answer:
          "<p>Indeed, legal facets warrant consideration in medical video production. It's crucial to adhere to laws safeguarding patient privacy, acquire requisite permissions, and accurately portray medical information. Engaging legal experts can help adeptly navigate these considerations.</p>",
      },
      {
        question: "5. How can medical videos seamlessly integrate into medical training regimens?",
        answer:
          "<p>Medical videos significantly enrich medical training curricula. They can vividly illustrate medical procedures, simulate patient interactions, and furnish case studies for discourse. These videos heighten the learning experience and augment knowledge retention.</p>",
      },
    ];
  return (
   <ServicesPage data={data}>
    <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Crafting Compelling Medical Videos To Meet Your Needs:
          </h2>
          <br />
          <p className="text-justify text-lg">
          Our team of experts is dedicated to producing medical videos that are both informative and captivating. We recognize the significance of conveying intricate medical information in a concise and comprehensible manner. Our videos are meticulously tailored to suit each client's specific requirements, whether it pertains to patient education, medical training, or marketing endeavors. Employing cutting-edge technology, we create top-tier videos that seamlessly merge visual appeal with clarity
            <br />
            Place your trust in us to provide videos that will enrapture your viewers and aid you in accomplishing your objectives.
          </p>
          <ul>
            <li>
              <strong>Informative Medical Videos:  &nbsp;</strong>
              Enlightening and visually enthralling videos that adeptly educate and engage your audience on an array of medical subjects.
            </li>
            <li>
              <strong>Tailored Medical Video Production:  &nbsp;</strong>
              Proficient video production services meticulously designed for the medical domain, yielding top-quality videos aligned with your goals.
            </li>

            <li>
              <strong>Healthcare-focused Video Production:   &nbsp;</strong>
              Skillfully crafted videos spotlighting the distinctive facets of your healthcare establishment, bolstering your services and fostering patient confidence.
            </li>

            <li>
              <strong>Dynamic Health Animation:&nbsp;</strong>
              Vibrant and visually arresting animations that demystify intricate medical notions, ensuring accessibility and heightened engagement among your viewers.
            </li>

            <li>
              <strong>Contemporary User Interface Design:  &nbsp;</strong>
              Crafting visually stunning designs that leave a lasting impression on your target audience, our creations ensure a memorable and impactful engagement. 
            </li>

            <li>
              <strong>Impactful Health Commercials:  &nbsp;</strong>
              Persuasive commercials effectively convey your healthcare offerings, etching an enduring impression on spectators and bolstering brand familiarity.
            </li>

            <li>
              <strong>Comprehensive Medical Training: &nbsp;</strong>
              Interactive and enlightening videos thoughtfully devised to train medical professionals, endowing them with the indispensable knowledge and proficiencies for their roles.
            </li>
            <p className="text-justify">
            Witness the prowess of our tailored video production services catering to the medical sphere—an avenue to eloquently convey your message and captivate your audience. Allow us to breathe life into your medical vision through mesmerizing videos.
            </p>
          </ul>

     
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Sequence Medical Video Production Services
          </h2>
          <ul>
            <li>
              <strong>Pre-Production:  &nbsp;</strong>
              Spark your medical video's creative vision with concept creation, scripting, and logistical planning;
            </li>
            <li>
              <strong>1. Concept/Vision Creation:  &nbsp;</strong>
              We bring your ideas to life with creative concepts and visionary storytelling.
            </li>

            <li>
              <strong>2. Scripting/Storyboarding:  &nbsp;</strong>
              We craft compelling narratives and visually stunning stories through meticulous scripting and storyboard creation.
            </li>

            <li>
              <strong>3. Video Shoot Logistics:  &nbsp;</strong>
              We handle all the behind-the-scenes details, ensuring a seamless video shoot experience.
            </li>

            <li>
              <strong>4. Voice Over/On-Camera Talent:   &nbsp;</strong>
              Accelerate your videos with captivating voiceovers or talented on-camera personalities by us.
            </li>

            <li>
              <strong>5. Local Video Crew: &nbsp;</strong>
              We provide or scout out top-notch local video crews to capture your vision flawlessly.
            </li>

            <li>
              <strong>Production:  &nbsp;</strong>
              Capture the essence of your medical story through professional video production, interviews, coaching, and advanced options like teleprompters and green screens.
            </li>


            <li>
              <strong>1. Produce/Direct Video Shoot:  &nbsp;</strong>
              We handle all aspects of video production, from concept to execution, ensuring a seamless and professional shoot.
            </li>

            <li>
              <strong>2. Conduct Medical Team/Patient Interviews:   &nbsp;</strong>
              Our skilled team conducts engaging and informative interviews with medical professionals and patients to capture their unique perspectives.
            </li>


            <li>
              <strong>3. On-Camera Coaching (Medical Team/Talent):   &nbsp;</strong>
              We provide expert coaching to medical teams and talent, helping them deliver confident and impactful on-camera performances.
            </li>

            <li>
              <strong>4. Teleprompter Option:  &nbsp;</strong>
              Enhance your on-camera delivery with our teleprompter option, ensuring smooth and accurate delivery of your script.
            </li>

            <li>
              <strong>5. Green Screen Option:    &nbsp;</strong>
              Unlock endless creative possibilities with our green screen option, allowing you to transport your videos to any virtual environment.
            </li>

            <li>
              <strong>Post-Production:   &nbsp;</strong>
              Transform your medical footage into captivating visual masterpieces with storyboarding, editing, motion graphics, voice-over services, and seamless video hosting and tracking.
            </li>


            <li>
              <strong>1. Post-Shoot Storyboarding:  &nbsp;</strong>
              We bring your footage to life through captivating storyboards that enhance the overall narrative.
            </li>

            
            <li>
              <strong>2. Video Editing: &nbsp;</strong>
              Our expert editors transform raw footage into polished and engaging videos that leave a lasting impact.
            </li>

            
            <li>
              <strong>3. Motion Graphics: &nbsp;</strong>
              Adding a touch of visual magic to your videos with stunning motion graphics that captivate your audience.
            </li>

            
            <li>
              <strong>4. Voice-Over Services:  &nbsp;</strong>
              We lift your videos with professional voice-overs that add depth and emotion to your storytelling.
            </li>
            <li>
              <strong>5. Video Hosting and Tracking:  &nbsp;</strong>
              We provide reliable video hosting solutions with advanced tracking capabilities to monitor your video's performance.
            </li>


          </ul>
   
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Conquer The Aptitude Of Video To Engage And Educate Your Audience
          </h2>
          <br />
          <p className="text-justify">
          By integrating video content into your marketing approach, you can mesmerize your audience and convey information in a manner that is both enthralling and memorable.       
          </p>
          <ul>
            <li>
              <strong>
              Lucid Communication: 
              </strong>
            </li>
            <p>
            Through visual narratives and animations, you can dissect intricate medical information into easily digestible and relatable content, ensuring effective communication of your message.
            </p>
            <li>
              <strong>Enhancing The Patient Experience: </strong>
            </li>
            <p>
            Integrating video into your marketing endeavors fosters a more immersive and empathetic journey for patients. Whether it involves displaying patient accounts, elucidating procedures, or furnishing educational material, video empowers you to forge a profound connection with your audience, nurturing trust and comprehension.
            </p>
            <li>
              <strong>Driving Traffic, Ensuring Triumph: </strong>
            </li>
            <p>
            By integrating video content on your website and promoting it across diverse channels, you can allure a greater number of visitors, holding their attention for extended periods. 
            </p>
            <li>
              <strong>Viewers Into Proactive Patients: </strong>
            </li>
            <p>
            Through crafting compelling and informative videos, you can motivate viewers to transition into patients. 
            </p>

            <li>
              <strong>Economical Marketing:  </strong>
            </li>
            <p>
            In the domain of medical practices, video marketing extends a budget-friendly remedy. Technological advancements have rendered the creation of high-quality videos more accessible and cost-effective. 
            </p>

            <li>
              <strong>Quantifiable Outcomes: </strong>
            </li>
            <p>
            The inclusion of videos in medical marketing campaigns bequeaths manifold advantages, particularly concerning the tracking and analysis of their influence. 
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
   </ServicesPage>
  )
}

export default MedicalVideos