import React from "react";
import Accordion from "../../Components/Accordian";
import {motion} from "framer-motion"

import ServicesPage from '../../Components/ServicesPage'

function LogoDesigning() {
  const data ={
    title: `LOGO DESIGNING`,
    description:"Accelerate your brand’s identity by partnering with us as we are not just a Logo Design Company we evoke with your targeted audience to keep your brand upfront. Our objective is to provide you with high-quality of versatile logos as we focus on fulfilling your vision and goal so that your brand’s logo can speak your unique and resonating story.",
    upperStyle:false,
    font:false,
    width:true,
    api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/logoDesigning_mt6ugj.json",
    head:{
      title:"Logo Designing | NYC Pro Web Designers | NY 10036",
      description:"Elevate your identity with unique logo designs by NYC's leading web designers, promoting brand success",
      canonical:`<link href="https://nycprowebdesigners.com/logo-designing" rel="canonical">`
    } 
    
}
const faqs =[
  {
      question: '1. Why Choose NYC Pro Web Designers for Logo Design NYC?',
      answer:"<p>To have a professional logo that is well-researched for your brand. NYC Pro Web Designers make a good first impression on your customers and audience and build the credibility of your brand. We bring your perception into existence through our resonating craft of logos. </p>"
  },
  {
      question:"2. How Can Customized Logo Design Benefit My Business?",
      answer:`<ul>
      <li>
      It will set you apart from the competitor.
      </li>

      <li>
      It could describe your company's core goals.
      </li>
      <li>
      Obtain all of the design files you require.
      </li>

      <li>
      It will serve as a representation of your company for the ensuing 5+ years.
      </li>
      <li>
      It also serves your intended audience.
      </li>

      </ul>
      `
  },
  {
    question: '3. What Sets NYC Pro Web Designers Apart from Other Logo Design Companies?',
    answer:"<p>We are your brand's collaborator with credible resonance. Our team is committed to grasping your preferences and perceptions. We have experienced and skilled staff who is dedicated to providing you the best visuals that catch the eyes of your targeted audience.</p>"
},
{
  question: '4. How Involved Will I Be in the Logo Design Process?',
  answer:"<p>Vision is the thing that is of utmost importance to us. We work with collaboration and highly appreciate your feedback. We work on your goal, vision, objectives, preferences, and ideas by turning them into visuals that tell your brand’s story. </p>"
},
{
question: `5. What's the Significance of a Professional Logo in Branding?`,
answer:"<p>A logo encompasses far more than an image; it symbolizes your unwavering commitment to excellence. Our logo design agency is supported by a team of skilled designers who understand how to seamlessly blend creativity with strategic thinking. A thoughtfully designed logo can cultivate trust, credibility, and recognition all of which are pivotal components in constructing a robust brand identity.</p>"
},

]

  return (
    <ServicesPage data={data}>
 <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Gateway To Striking Brand Identity: Peerless Logo Design Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Do you want to distinguish your brand? Join forces with NYC Pro Web Designers! 
We're here to help your business uniquely connect with your audience; we're not just a logo design firm. We design your brand to look like a superhero with a stylish outfit.
Our crew of exceptional designers is capable of developing designs that convey joy to hearts across the globe, just like an artist might. To make certain that your logo is created precisely the way you want it, we work closely with you. The story of your brand should be conveyed by your logo, just like in a beloved fairy tale. We want to provide you with a high-quality, adaptable logo. For your brand, it's like having a magic wand! We want people to always remember your logo because it is fantastic.
          </p>

          <ul>
            <li>
              <strong>Discover The Customization Power</strong>
            </li>
            <p className="text-justify text-lg">
            In the ocean of brands, Catch the eye with customized logos for your brand that mirror your unique story. Our Logo design agency can help you generate designs that truly personify your business ambitions and pretensions. We have experience in creating tailored designs that knock around with your targeted audience. Our professional logo design company can help you build a strong relationship with your clients and target market.
            </p>

            <li>
              <strong>Your Perception, Our Priority</strong>
            </li>
            <p className="text-justify text-lg">
            Team up with our Logo Design NYC, and you can recklessly trust that you are in the loop with a professional logo design company that honestly cares about your vision. Our designers are all ears to hear you out, be it your ideas, goals, perceptions, or preferences, and mold them into amazing visuals that instantly capture the story of your brand.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Symphony Of Excellence: Echoes Of Expertise
          </h2>
          <br />
          <p className="text-justify">
          When it is a matter of logo designing, our professionalism is our pride. A bunch of skilled designers make up our strategic thinking team who are aware of how to blend creativity. 
          </p>
          <ul>
            <li>
              <strong>Expertise That Resonates Deeply</strong>
            </li>
            <p className="text-justify text-lg">
            Our team comprises seasoned professionals with a treasure trove of experience. From software development to video animation, we bring a wealth of know-how to the table, aiming to deliver exceptional results that truly connect with your audience.
            </p>

            <li>
              <strong>
              Creative Brilliance: Setting Imagination Free
              </strong>
            </li>

            <p className="text-justify text-lg">
            We firmly believe that creativity knows no limits. With our innovative solutions, we break through the boundaries of imagination to craft enchanting designs, animations, and experiences that make a lasting impression.
            </p>
            <li>
              <strong>
              Smooth Harmony: From Idea to Accomplishment
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our firm attention to detail ensures a smooth journey from the initial concept to the final accomplishment. Whether it's meticulous planning before production or refining every aspect during post-production, we orchestrate each step with precision, guaranteeing an impeccable end product.
            </p>

            <li>
              <strong>
              Success Stories in Tune: Client Testimonials
              </strong>
            </li>
            <p className="text-justify text-lg">
            Don't just take our word for it! Listen to the success stories sung by our delighted clients. They speak highly of our prowess in software development, coding expertise, and web analytics services, lauding our ability to go above and beyond expectations and deliver remarkable outcomes.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Opt for Our Logo Design Services:
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Artistic Creations
              </strong>
            </li>
            <p>
            Our team of talented designers craft logos that are one-of-a-kind, visually appealing, and memorable. We breathe life into your brand's vision with a touch of creativity.
            </p>
            <li>
              <strong>Tailored Exclusively: </strong>
            </li>
            <p>
            We recognize that every business has its own distinct identity. Our logo designs are individually crafted to mirror your brand's character, principles, and target audience, ensuring a perfect match.
            </p>
            <li>
              <strong>Obsessive About Details: </strong>
            </li>
            <p>
            We meticulously attend to every aspect of your logo, from font styles to color selections. We aim to generate a visually striking and harmonious design that leaves an enduring impact.
            </p>
            <li>
              <strong>Adaptability and Expandability:</strong>
            </li>
            <p>
            Our logos are designed to be adaptable across various platforms and mediums. Whether it's in print, digital form, or on merchandise, your logo will maintain its effectiveness and clarity.
            </p>
            <li>
              <strong>Punctual Delivery: </strong>
            </li>
            <p>
            We treasure your time and are committed to delivering your logo designs within the agreed-upon schedules. We understand the significance of prompt service to fulfill your branding requirements.
            <br />
            Choose us for logo designs that leave a lasting impression and authentically represent your brand!
            </p>
          </ul>
        </motion.div>
        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>


    </ServicesPage>
  )
}

export default LogoDesigning