import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function SocialMedia() {
  const data ={
    title: `Social media`,
    description:"Considering expanding your company, are you ready? We are experts in providing exceptional social media marketing solutions that are tailored to your requirements. We are willing to stand by you and assist you in flourishing notwithstanding any challenges you confront in the business world. Consider us as your dependable digital partner, eager to strengthen and shine your brand.",
    upperStyle:true ,
    font:true,
    api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712601/lotties%20Json%20Files/SocialMedia_rqaagt.json",
    head:{
      title:" Social Media Marketing | NYC Pro Web Designers | NY 10036",
      description:" Boost your brand's impact with NYC's top web designers and social media marketing specialists.",
      canonical:`<link href="https://nycprowebdesigners.com/social-media" rel="canonical">`
    }
}
const faqs = [
  {
    question: "1. Which team member will be assigned to manage my account?",
    answer: `<p>Our skilled marketing director will take charge of overseeing your account to ensure its prosperity.</p>`,
  },
  {
    question: "2. How will you familiarize yourself with the details of my business?",
    answer: `<p>Our team will thoroughly research and engage in in-depth conversations with you to gain a comprehensive understanding of your business.
    </p>`,
  },
  {
    question: "3. Who will be responsible for creating the content?",
    answer:
      "<p>Our adept content creators, including myself, will be tasked with forming appealing and SEO-driven content for your social media platforms</p>",
  },
  {
    question: "4. What strategies will be employed to enhance my follower count?",
    answer:
      "<p>We will apply a blend of natural and paid strategies, encompassing tailored content, partnerships with influencers, and well-placed advertisements, all aimed at augmenting your follower tally.</p>",
  },
  {
    question: "5. How can I initiate my journey with Social Media Marketing (SMM)?",
    answer:
      "<p>To embark on your social media journey, we suggest defining precise objectives, pinpointing your intended audience, and devising a content strategy in harmony with your brand. Let's delve into the specifics to initiate the process!</p>",
  },
];

  return (
    <ServicesPage data={data} >
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Social Media Marketing Agency: Fostering Your Business Triumph
          </h2>
          <br />
          <p className="text-justify text-lg">
          Do you want more people to know about your company and to increase its online visibility? You're fortunate, then! Our business is very skilled at assisting small businesses in becoming more visible on social media. Facebook, Instagram, and Twitter, those amusing social media platforms where users like exchanging information and discussing interests.
            <br />
            We resemble a welcoming group of professionals with extensive experience using social media to grow businesses. Businesses need friends, just like you do, and we help them make plenty of new ones online, just like you have friends at school or in your area.
            <br />
            Let's be networking pals and support the success of your company!
          </p>

          <ul>
            <li>
              <strong>Well-Planned Social Media Marketing Solutions</strong>
            </li>
            <p className="text-justify text-lg">
            From formulating attractive content to overseeing paid social media advertising endeavors, our panel of adept professionals guarantees that your brand outshines the congested social media panorama.
            </p>

            <li>
              <strong>Customized Resolutions For Small Enterprises: </strong>
            </li>

            <p className="text-justify text-lg">
            Our dedicated unit collaborates closely with you to devise a tailored social media strategy that resonates with your business objectives and financial plan. Whether it involves devising engaging posts, orchestrating focused ad campaigns, or administering your social media profiles, we provide all-encompassing resolutions to propel your business toward triumph.
            </p>
            <li>
              <strong>Compelling Paid Social Media Advertising</strong>
            </li>
            <p className="text-justify text-lg">
            Paid social media advertising serves as a potent mechanism to amplify your brand's outreach and channel targeted traffic to your website. Our social media marketing agency excels in designing and steering exceptionally effective paid advertising campaigns across diverse social media platforms. 
            </p>

            <li>
              <strong>Proficient Social Media Oversight Services</strong>
            </li>
            <p className="text-justify text-lg">
            From crafting content and scheduling posts to engaging with your community and monitoring performance metrics, our seasoned team oversees every facet of your social media visibility, guaranteeing consistent expansion and engagement with your audience.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Maximize The Potential Of Social Media Promotion: A Step-By-Step Manual
          </h2>
          <br />
          <p className="text-justify">
          We grasp the significance of efficient employment of social media promotion. To guide you in harnessing this influential instrument, comply with these stages:
          </p>
          <ul>
            <li>
              <strong>Researching: </strong>
            </li>
            <p className="text-justify text-lg">
            Delve profoundly into your intended audience, current trends within your sector, and analysis of competitors. This comprehension will navigate the creation of your content and your overall strategy.
            </p>

            <li>
              <strong>
              Formulating Content Strategy: 
              </strong>
            </li>

            <p className="text-justify text-lg">
            Construct a schedule for content creation that harmonizes with your corporate objectives and resonates with your audience. Craft exceptional and shareable content that enriches their existence.
            </p>
            <li>
              <strong>
              Enhance Your Profile: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Ensure your social media profiles are thoroughly enhanced with pertinent keywords, appealing visuals, and persuasive descriptions. This will magnetize more adherents and amplify your prominence.
            </p>

            <li>
              <strong>
              Experimentation: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Test out diverse kinds of content, posting timetables, and strategies to stimulate engagement. Scrutinize the outcomes and refine your approach grounded in what connects most effectively with your audience.
            </p>

            <li>
              <strong>
              Analysis: 
              </strong>
            </li>
            <p className="text-justify text-lg">
            Routinely monitor and dissect vital metrics to gauge the triumph of your social media promotional endeavors. Utilize this data to formulate judicious determinations and enhance your strategy for superior outcomes.
            </p>
            <br />
            <p className='text-justify'>
            By adhering to these stages, you can exploit the power of social media promotion to propel brand recognition, captivate your audience, and fulfill your corporate aspirations.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Effective Social Media Marketing & Management
          </h2>
          <br />
          <p className="text-justify">
          In the contemporary digital domain, social media marketing plays a crucial role in steering business triumph. Our firm provides comprehensive services for managing and marketing your social media presence, enabling you to tap into the potential of renowned platforms like Facebook, Twitter, Instagram, LinkedIn, Google My Business, Google+, and YouTube. Utilizing our expertise, we can raise your brand's online visibility and foster meaningful engagement with your desired audience.
          </p>
          <ul>
            <li>
              <strong>
              Facebook, Twitter, And Instagram;
              </strong>
            </li>
            <p>
            Facebook, Twitter, and Instagram aren't just avenues for social interaction; they wield immense marketing prowess capable of propelling your business forward. Our company excels at leveraging these platforms to craft exceptional social media posts that deeply resonate with your audience. From crafting engaging content to strategically timed postings, we ensure your brand remains prominently positioned, fostering valuable interactions with potential patrons.
            </p>
            <li>
              <strong>Broaden Your Professional Network Via LinkedIn Marketing</strong>
            </li>
            <p>
            LinkedIn stands as the go-to platform for professionals, rendering it a treasure trove for B2B enterprises. Our social media marketing offerings encompass targeted strategies for LinkedIn, facilitating connections with industry peers, showcasing your expertise, and generating valuable business prospects. Relying on our proficiency, your LinkedIn presence will be meticulously tailored to allure the right audience and propel business expansion.
            </p>
            <li>
              <strong>Amplify Local Presence With Google My Business And Google+</strong>
            </li>
            <p>
            In the context of local enterprises, Google My Business and Google+ emerge as indispensable tools. Our enterprise excels in refining your Google My Business profile and optimizing your Google+ page to augment local visibility. Through skillful utilization of these platforms, we ensure your business commands a prominent stance in local search outcomes, driving higher foot traffic and an expanded customer base.
            </p>
            <li>
              <strong>Leverage Potent Video Marketing Through YouTube</strong>
            </li>
            <p>
            As the world's second-largest search engine, YouTube emerges as a prime ground for video marketing. Our company extends adept YouTube marketing services, facilitating the creation of amazing video content, meticulous channel optimization, and broader audience reach. Our strategic methodology empowers you to heighten brand recognition, steer website traffic, and lift conversions through the influential sphere of video marketing.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Outrageous Social Media Management Solutions
          </h2>
          <br />
          <p className="text-justify">
          Do you need assistance with your social media accounts? So let us tell you why choosing us is a wise decision!
          <br />
          Think of social media as a huge playground where you can meet and converse with many pals. However, playing there by yourself might occasionally be challenging. We step in to help with it.
          <br />
          Comparable to the sort of folks that keep you safe while you're having time on the green, we are here to help. We are familiar with all the rules and games, and we can ensure that you have a great time and meet a lot of new friends.
          <br />
          Here's why opting for our social media management solutions is the right choice:
          </p>
          <ul>
            <li>
              <strong>Affordable Pricing: &nbsp;</strong>
              Our pricing options are competitive and well-suited to your budget requirements. Gain top-notch quality without straining your finances.

            </li>
           
            <li>
              <strong>Skilled Experts:&nbsp; </strong>
              Our team consists of proficient specialists who are up-to-date with the latest social media trends and strategies. They will produce alluring content, oversee your accounts, and drive impactful outcomes.

            </li>

            
            <li>
              <strong>Complete Range of Digital Marketing:&nbsp; </strong>
              Going beyond social media management, we offer a comprehensive array of digital marketing services. From enhancing SEO to crafting content, we have every aspect covered.
            </li>
         
            <p className='text-justify'>
            Leverage our expertise to concentrate on your primary business tasks while we handle your social media presence. Allow us to assist you in unlocking your digital potential and realizing your marketing objectives.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Choose Us?
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Cost-Effective Pricing: Enhancing Your Investment's Value
              </strong>
            </li>
            <p>
            Recognizing the significance of affordability, we provide reasonably priced social media services that uphold top-notch quality. We believe that every business, no matter its size, should access professional social media management. Our cost-effective pricing options ensure optimal returns for your investment, freeing up your budget for other critical business areas. Be assured, that our competitive pricing in no way compromises the excellence of our services. 
            </p>
            <li>
              <strong>Skilled Experts: Amplifying Your Social Media Presence</strong>
            </li>
            <p>
            We take pride in our dedicated and proficient team, driven by passion for their work. Armed with the latest social media trends and strategies, they collaborate closely with you to comprehend your business objectives and formulate a customized social media strategy. From crafting spellbinding  content to managing your accounts and engaging your audience, our experts will escalate your social media presence. Their know-how and experience ensure heightened brand visibility, increased engagement, and ultimately, a positive business impact.
            </p>
            <li>
              <strong>Comprehensive Digital Marketing Solutions: A Wholesome Approach</strong>
            </li>
            <p>
            From search engine optimization (SEO) to crafting content, email marketing, and more, we possess the skills and resources to drive outcomes across various digital platforms. Adopting a holistic approach to digital marketing guarantees seamless synergy among all facets of your online presence, yielding maximal influence. Our integrated methodology aids in streamlining your marketing endeavors under a single roof, saving both time and effort.
            </p>
            <li>
              <strong>Distinctive and Engaging Content Crafting: Enthralling Your Audience</strong>
            </li>
            <p>
            Acknowledging the distinctiveness of each business, our content creation approach is personalized. Our creative writers fabricate distinctive and catchy content mirroring your brand's tone and resonating with your intended audience. Be it appealing captions or arresting visuals, our content is tailored to captivate and enthrall, setting you apart from the competition.
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default SocialMedia