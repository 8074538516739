import React from "react";
import ServicesPage from "../../Components/ServicesPage";
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function WebDevelopment() {
  const data = {
    title: `Custom Website development`,
    description:
      "Are you on the lookout for top-quality website development solutions? Your search ends here! Our firm is committed to delivering outstanding services tailored to meet your specific needs. With our expertise in creating custom websites, we design platforms that truly mirror your brand identity and yield effective outcomes.",
    upperStyle: true,
    font: true,
    api: "https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712602/lotties%20Json%20Files/webDevelopment_mbabwv.json",
    head: {
      title: "Custom Website Development Services| NYC Pro Web Designers",
      description:
        "We create unique websites tailored just for you. Our Custom Website Development  Services cater to your specific needs",
      canonical: `<link href="https://nycprowebdesigners.com/custom-website-development-services" rel="canonical">`,
    },
  };

  const faqs = [
    {
      question: "1. Could you provide examples of Shopify website designs you've made for various scenarios?",
      answer: `<p>Yes, of course! We can provide examples that are specially created for your specific requirements.</p>`,
    },
    {
      question: "2. How do you make sure the website seamlessly integrates with our present tools and processes, avoiding any disruptions?",
      answer: `<p>By carefully analyzing your current tools and systems, we ensure a seamless fit and prevent any glitches.
        </p>`,
    },
    {
      question: "3. How much expertise does your team have in creating Shopify websites?   ",
      answer:
        "<p> Our staff has a wealth of knowledge and practical experience building Shopify websites.</p>",
    },
    {
      question: "4. Discuss the benefits of user-friendly interfaces for improving the purchasing experience.",
      answer:
        "<p>Customers enjoy easy and fun purchasing because of user-friendly interfaces, which enhance their whole experience.</p>",
    },
    {
      question: "5. What e-commerce features are offered by your Shopify development services? ",
      answer:
        "<p>To satisfy your objectives, our Shopify development service provides a wide array of unique e-commerce features.</p>",
    },
  ];

  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Professional Services For Custom Web Development
          </h2>
          <br />
          <p className="text-justify text-lg">
            Imagine owning an extremely cool-looking, incredibly user-friendly
            online store. What we do is that.
            <br />
            Consider your preferred video game. Do you know how it's simple to
            play and packed with awesome content? Well, that's how we create our
            internet stores!
            <br />
            People will find it easy to find and purchase what they want when
            they visit your online store. It's similar to easily locating your
            preferred toy at a toy store.
            <br />
            Guess what, too? People who visit your online store will fall in
            love with it and desire to visit it over and over again. Their minds
            will emerge into your closest peers who intend to spend together
            with you all the time.
          </p>

          <ul>
            <li>
              <strong>Expert Shopify</strong>
            </li>
            <p className="text-justify text-lg">
              From intuitive navigation to secure payment gateways, we ensure a
              smooth and protected shopping journey for your patrons.
            </p>

            <li>
              <strong>Advanced Ecommerce Website Development </strong>
            </li>

            <p className="text-justify text-lg">
              We construct dynamic and high-performing Magento websites that
              adeptly manage heavy traffic and support your business expansion.
            </p>
            <li>
              <strong>Robust And Seamless Magento </strong>
            </li>
            <p className="text-justify text-lg">
              In the present mobile-centric world, responsive website
              development is of paramount importance. Our team fabricates
              websites that seamlessly adjust to any screen dimensions,
              guaranteeing an optimal user experience across various devices.
            </p>

            <li>
              <strong>Responsive Website Crafting</strong>
            </li>
            <p className="text-justify text-lg">
              We engineer feature-rich websites with robust content management
              capabilities, empowering you to effortlessly oversee and update
              your site.
            </p>

            <li>
              <strong>Proficient Website Development Offerings</strong>
            </li>
            <p className="text-justify text-lg">
              We combine ingenuity, technical proficiency, and industry best
              practices to deliver remarkable outcomes.
              <br />
              Reach out to us today to unlock your online potential!
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Our Web Development Expertise
          </h2>
          <br />
          <p className="text-justify">
            Searching for outstanding web development services? Look no further!
            <br />
            Our company provides a complete array of solutions to drive your
            online mastery.
          </p>
          <ul>
            <li>
              <strong>Strategic Planning: Crafting a Path to Success</strong>
            </li>
            <p className="text-justify text-lg">
              To fully comprehend your objectives and target market, our
              knowledgeable strategists collaborate closely with you. We
              construct a thorough blueprint that ties your company's goals to
              market trends and lays the groundwork for successful web app
              development.
            </p>

            <li>
              <strong>
                Ignite Growth With An Effective Digital Marketing Game Plan
              </strong>
            </li>

            <p className="text-justify text-lg">
              With our digital marketing tactics, we'll fuel growth and connect
              with your desired audience efficiently. From SEO to social media
              marketing, we'll shape a unique plan aligned with your business
              objectives.
            </p>
            <li>
              <strong>
                Tailored Answers: Crafting Custom Web Applications
              </strong>
            </li>
            <p className="text-justify text-lg">
              Our team of seasoned developers will collaborate closely with you
              to ensure your application is robust, secure, and user-friendly.
            </p>

            <li>
              <strong>
                Optimize Sales With Proficient E-commerce Crafting
              </strong>
            </li>
            <p className="text-justify text-lg">
              Our e-commerce development services are geared to amplify sales
              and heighten the online shopping journey. From user-friendly
              interfaces to seamless payment gateways, we'll help forge a
              prosperous online store.
            </p>

            <li>
              <strong>
              Front-End Design & Development
              </strong>
            </li>
            <p className="text-justify text-lg">
            We'll build impressive user experiences that captivate your audience. Our crew will ensure your site is visually engaging, responsive, and primed for peak performance.
            </p>

            <li>
              <strong>
              Back-End Web Mastery
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our squad of skilled developers will establish a dependable and scalable back-end framework that empowers your website's functionality.
            </p>

            <li>
              <strong>
              Dependable Support & Upkeep For Your Web Projects

              </strong>
            </li>
            <p className="text-justify text-lg">
            We comprehend the value of ongoing aid and maintenance for your web endeavors. Our dedicated team will provide timely updates, security enhancements, and technical support to ensure your website operates seamlessly.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Embrace The Benefits Of Web Developments
          </h2>
          <br />
          <p className="text-justify">
          Are you seeking to enhance your brand and business to new heights? 
          <br />
          The world of web development holds the key to unlocking a plethora of advantages that can drive your online presence forward.
        
          </p>
          <ul>
            <li>
              <strong>
              Mastering Web Development
              </strong>
            </li>
            <p>
            Our team of web developers is fully equipped to craft a website that not only boasts an impressive appearance but also operates with seamless efficiency. We grasp the significance of swift turnarounds, ensuring your website is up and active in record time.
            </p>
            <li>
              <strong>Rapid Results, Enduring Impact</strong>
            </li>
            <p>
            Web development empowers you to establish your brand identity and set yourself apart from competitors. By forging a user-friendly and aesthetically pleasing website, you can create a lasting impression on visitors and transform them into devoted customers.
            </p>
            <li>
              <strong>Unseen Gains Of Web Development</strong>
            </li>
            <p>
            By seamlessly integrating SEO strategies into the development process, we guarantee that your website secures a higher rank in search engine results, propelling organic traffic and amplifying your brand's visibility.
            </p>
            <li>
              <strong>The Gateway To Brand Awareness And Business Growth
</strong>
            </li>
            <p>
            Collaborate with our enterprise and immerse yourself in the firsthand benefits of web development. Our team of adept developers will work hand in hand with you to comprehend your distinctive requisites and deliver a website that surpasses your expectations.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Web-Based Software's Potential
          </h2>
          <br />
          <p className="text-justify">
          Ready to raise your business using web-based software? 
          <br />
          Discover the exceptional capabilities of NYC Pro Web Designers. Our team of professionals stands prepared to offer perceptive business analysis, instinctual user experience (UX), contemporary user interface (UI), resolute performance, seamless integration, and prompt updates to ensure your web solution remains current and competitive.
          </p>
          <ul>
            <li>
              <strong>User-Focused Design</strong>
            </li>
            <p>
            We comprehend that building a triumphant web-based software begins with a strong groundwork. That's why we provide insightful business analysis, guaranteeing a comprehensive understanding of your distinctive needs and objectives. 

            </p>
            <li>
              <strong>The Core Of A Fulfilling Digital Journey</strong>
            </li>

            <p>
            Our instinctual UX design trims bounce rates and curbs user errors, guaranteeing your website or application is effortlessly traversable and user-friendly. We are firm believers that a seamless user experience equates to heightened engagement and conversions, underpinning the triumph of your digital presence.
            </p>
            <li>
              <strong>Timely Upgrades For A Leading-Edge Web Solution </strong>
            </li>
            <p>
            Our accomplished designers will formulate a visually splendid interface that not only impresses but also fosters user involvement and drives conversions.
            </p>

            <li>
              <strong>Accurate Analysis, Intuitive Design</strong>
            </li>
            <p>
            Performance is supreme in the sphere of web-based software. With NYC Pro Web Designers, anticipate fail-safe performance that pledges a fulfilling digital journey for your users. We optimize every facet of your solution, assuring swift loading times, fluid functionality, and uninterrupted user interactions. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Opt For Our Services?
          </h2>
          <p className="text-justify">
          There are many reasons for choosing us few of them are;
          </p>
          <ul>
            <li>
              <strong>Extensive Package:  &nbsp;</strong>
              Web applications grant users the flexibility to connect from any
              device linked to the internet, ensuring utmost convenience.
            </li>
            <li>
              <strong>Versatile Compatibility: &nbsp;</strong>
              Our range of services is all-encompassing, spanning various offerings such as conceptualizing and developing ideas into products, designing brand identities, providing SaaS consulting, and crafting SaaS UX/UI designs.
            </li>

            <li>
              <strong>Comprehensive Business Analysis:  &nbsp;</strong>
              We delve deep into analysis to comprehend your distinct requisites and aspirations. In our pursuit of gaining a comprehensive understanding of your particular needs and aspirations, we delve deep into meticulous analysis. 
            </li>

            <li>
              <strong>Intuitive User Experience Design:  &nbsp;</strong>
              Crafting User-Centric Designs to Reduce High Bounce Rates and Enhance Overall Engagement. Our team specializes in creating designs that are tailored to the needs of users, effectively decreasing instances where visitors leave a website shortly after arriving and improving the overall level of interaction. 
            </li>

            <li>
              <strong>Contemporary User Interface Design:  &nbsp;</strong>
              Crafting visually stunning designs that leave a lasting impression on your target audience, our creations ensure a memorable and impactful engagement. 
            </li>

            <li>
              <strong>Firm Performance Guarantee: &nbsp;</strong>
              We guarantee a smooth merging process and commit to delivering a satisfying digital journey. We aim to ensure that the integration occurs seamlessly, leading to an enjoyable encounter in the digital domain. Rest assured that the content provided is unique and tailored to your needs.
            </li>

            <li>
              <strong>Timely and Consistent Updates:  &nbsp;</strong>
              We furnish regular updates to keep your web solution current and aligned with the latest trends.
            </li>
            <p className="text-justify">
            Rely on NYC Pro Web Designers with confidence to actualize your vision for web-based software. Connect with us now to initiate the journey!
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  );
}

export default WebDevelopment;
