import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";


 
function DigitalMarketing() {

    const data ={
        title: `Digital Marketing`,
        description:"Driving engagement to enhance your digital presence, we have got your back. We have a vast variety of strategies to make you stand out in the market of your preference. We vigilantly observe the algorithm and analyze the content that needs to go visually as it is to make sure your website ranks highest.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692726282/lotties%20Json%20Files/digital-marketing_udmpz8.json",
        head: {
          title: "Digital Marketing Services | NYC Pro Web Designers | NY 10036",
          description:" NYC's top web designers provide comprehensive digital marketing services for your online success.",
          canonical: `<link href="https://nycprowebdesigners.com/digital-marketing-services" rel="canonical">`,
        },
    }

    const faqs =[
      {
          question: '1. What is the role of SEO in digital marketing?',
          answer:"<p>SEO is crucial for online visibility and ranking. It optimizes your websites and leads them to the targeted audiences and search engines too. </p>"
      },
      {
          question:"2. How does PPC advertising work?",
          answer:`<p>Pay-Per-Click (PPC) advertising delivers immediate results through paid advertisements. When an ad is clicked, advertisers are paid, which immediately increases traffic to their website.</p>
          `
      },
      {
        question: '3. Why is content marketing important for businesses?',
        answer:"<p>Content marketing is essential for businesses since it provides viewers with value through amusing, instructional, or informational content. Over time, it increases brand credibility, consumer loyalty, and trust. Quality content boosts lead creation, improves SEO, and draws organic traffic. It works well to engage, cultivate, and turn potential consumers into devoted supporters.</p>"
    },
    {
      question: '4. What benefits does social media bring to a business?',
      answer:"<p>Social media is beneficial for a business in many ways, that includes brand recognition, direct client interaction, and vigilant marketing opportunities. Social media help businesses to connect with people in actual time, increase their traffic and collect insights of the analytical data that plays a pivotal role in customized experiences of the customers. Furthermore, it also offers pocket friendly advertising solutions too, that promote the loyalty of the brand and boosts growth.</p>"
    },
    {
    question: `5. How does Conversion Rate Optimization (CRO) impact website success?`,
    answer:"<p>Through route optimization and promoting activities like form submissions or subscriptions, conversion rate optimization (CRO) aims to convert website visitors into customers. It increases your website's overall efficiency. </p>"
    },
    
    ]




  return (
    <ServicesPage data={data} >
<div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Digital Marketing Services
          </h2>
          <br />
          <p className="text-justify text-lg">
            Our goal is to make your internet presence stand out. Imagine that we are the party planners for your website, which is like a huge party. We have a ton of strategies to make your event (or website) the greatest in town.
            <br />
            Do you remember how you always wanted your pals to attend your birthday party and have a fantastic time? We do that for your website, so there. To increase traffic and interest in what you have to offer, we have unique strategies. We also closely observe the guidelines that govern the online environment, much like we would when following a map. We consider user preferences and make sure your website fulfills those needs. In this manner, your website might be at the top, as the main attraction!
            <br />
            Consequently, consider us your website's best friends while we work to make it the most interesting and well-liked one on the market!

          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          What Services Include In Our Digital Marketing?
          </h2>
          <br />
          <p className="text-justify">
          Set out a groundbreaking digital tenure with our outstanding approach that includes;
          </p>
          <ul>
            <li>
              <strong>SEO Services:</strong>
            </li>
            <p className="text-justify text-lg">
            With our excellent SEO services, you can increase your online visibility and generate organic visitors. To increase website authority and conversion rates, our skilled team offers customized solutions.
            </p>

            <li>
              <strong>
              PPC Services:
              </strong>
            </li>

            <p className="text-justify text-lg">
            Our professionally run PPC strategies discover instant traffic. On platforms like Google Advertising and Bing Ads, we optimize advertising, budgets, and keywords for the highest ROI.
            </p>
            <li>
              <strong>
              Content Marketing:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Content marketing drives traffic and engages your target audience with valuable, shareable content to establish industry authority.
            </p>

            <li>
              <strong>
              Social Media Services:
              </strong>
            </li>
            <p className="text-justify text-lg">
            With our comprehensive social media services, you can leverage your social media presence as a powerful marketing tool. Our team monitors trends, engages with your audience, and adapts strategies based on their feedback
            </p>

            <li>
              <strong>
              Social Media Advertising services:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our data-driven approach identifies the platforms and demographics that matter most to your business. In addition to eye-catching graphics and compelling copy, our ads inspire action and deliver measurable results.
            </p>

            <li>
              <strong>
              Design And Development Services:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Catching the eyes of the audience by generating unique designs and websites. Our design and development services blend aesthetics with functionality. 
            </p>

            <li>
              <strong>
              Email Marketing:
              </strong>
            </li>
            <p className="text-justify text-lg">
            We design visually appealing and personalized email campaigns that nurture leads and foster customer loyalty. 
            </p>
            <li>
              <strong>
              CRO:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Use our skilled CRO services to increase website conversions. Through A/B testing and user journey analysis, we examine user behavior, optimize items, and make certain that every click is worthwhile.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Us For Your Digital Marketing Services?
          </h2>
          <br />
          <p className="text-justify">
          We cross the expectation’s boundaries and help you uplift your website to rank highest. Hence, choosing us for your digital marketing services is a wise decision to make ASAP. 
          <br />
          Collaboration with us uncovers innumerable advantages, a few of which are;
        
          </p>
          <ul>
            <li>
              <strong>
              Trendsetting Insights: 
              </strong>
            </li>
            <p>
            To keep you up to date on the most recent developments, our team of professionals uses cutting-edge approaches and keeps up with market trends. We offer insightful information that can keep your company on the cutting edge.
            </p>
            <li>
              <strong>Customized Business Strategies: </strong>
            </li>
            <p>
            We are aware that every company is different. Our unique business plans are made to fit your objectives, target market, and competitive environment. Together, we craft a plan that distinguishes you from the competition.
            </p>
            <li>
              <strong>Dedicated Commitment: </strong>
            </li>
            <p>
            Whenever you're provided with an assigned account manager, you'll receive one primary point to speak to who will be available to handle your concerns, respond to your inquiries, and make ensure your project obtains the attention it needs. 
            </p>
            <li>
              <strong>Highly Skilled Expert Crew:</strong>
            </li>
            <p>
            The team we have is built up of highly experienced professionals in each of their specialized domains. We are equipped with the skills and expertise to generate fantastic results for your company, from designers and developers to marketers and strategists.
            </p>
            <li>
              <strong>Strong Track Record: </strong>
            </li>
            <p className='text-justify'>
            We have a portfolio of pleased clients who have accomplished their objectives with our help, demonstrating our long history of accomplishment. We are delighted to have assisted countless firms in thriving in their respective industries, and our results speak for themselves.
            </p>
            <p>
            Pick us if you want a thorough strategy, specialized care, and a successful track record. Together, let's grow your company to new heights!
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage >
  )
}

export default DigitalMarketing