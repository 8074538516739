import React,{useState,useEffect}  from "react";
import { motion,   } from "framer-motion";
// import TestimonialImg from "../Asset/Images/testimonial.webp";
import TestimonialCard from "../Components/TestimonialCard";
// import CircleAnimation from "../Asset/Animation/circle.json"
import Loader from "../Components/Loader";


// react swiper js 
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
function Testimonial() {
  const [animation ,setAnimation] = useState("")
  const  apiGetAnimationJson = async ()=>{
    console.timeStamp("start")
      const response = await fetch("https://res.cloudinary.com/dueulh8fr/raw/upload/v1692728322/lotties%20Json%20Files/nyc%20Home%20page%20lotties/circle_a2ynji.json")
      const jsonData  = await response.json()
      setAnimation(jsonData)
  }
  useEffect(() => {
    apiGetAnimationJson()
  }, []);

  return (
    <div className="max-h-screen  flex justify-end relative overflow-hidden items-center ">
      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightblue absolute -top-5  -left-[50px] hidden md:block "
        initial={{ visibility: "hidden",opacity:0 }}
        whileInView={{visibility: "visible",opacity:1 }}
        viewport={{ once: true }}
        transition={{
          duration: "5",
        }}
      />

      <motion.div
        className="w-36 h-36 rounded-full blur-[50px] bg-lightyellow absolute bottom-0 -left-[50px] hidden md:block "
        initial={{ visibility: "hidden",opacity:0 }}
        whileInView={{visibility: "visible",opacity:1 }}
        viewport={{ once: true }}
        transition={{
          duration: "5",
        
        }}
      />

    <motion.div
        className=" bottom-14 left-0 w-[150px] md:w-[200px]  lg:w-[250px]  xl:w-[350px]   absolute z-10"
      >
        <Loader width={"100%"} height={"100%"} data={animation} />
    </motion.div>



      {/* Black Circle */}
      <motion.div
        initial={{ scale: 1,}}
        whileInView={{ scale: 12, position: "absolute", right: "30%", top: "30%" }}
        onResize={(e)=>alert("evnet",e)} 
        viewport={{once:true}}

        transition={{
          // delay: "2",
          duration: 3,
        }}
        className="bg-black rounded-full hidden lg:block text-white absolute lm:!right-[-60%] md:!right-[-33%] lg:!right-[-1%] xl:!right-[22%] top-[45%] w-[150px] h-[150px] overflow-hidden"
      ></motion.div>

{/* small screen circle */}
<motion.div
        initial={{ scale: 1,}}
        whileInView={{ scale: 6,   top: "30%" }}
        onResize={(e)=>alert("evnet",e)} 
        viewport={{once:true}}

        transition={{
          // delay: "2",
          duration: 3,
        }}
        className="bg-black rounded-full  absolute lg:hidden !right-[-57%] mm:!right-[-37%] lm:!right-[-24%] sm:!right-[15%]  md:!right-[27%] top-[45%] w-[150px] h-[150px] overflow-hidden"
      ></motion.div>


<div className=" flex justify-between container z-10  h-screen text-white flex-col ">

      <motion.div
        initial={{ visibility: "hidden" }}
        whileInView={{ visibility: "visible" }}
        viewport={{once:true}}
        transition={{
          delay: "4",
          duration: 2,
        }}
        className="  self-center float-right  w-fit mt-[8%]  "
        // flex flex-col justify-center absolute top-2 right-[30%]  bg-lightblue
      >
        <motion.h2
          initial={{width:0,}}
          animate={{width:"100%"}}
          transition={{duration:2,delay:"4"}}


          className=" xxl:ml-10 font-bold text-transparent  text-3xl mm:text-4xl lm:text-4xl text-right md:text-left md:text-6xl lg:text-7xl xl:text-8xl overflow-hidden  font-heading"
          style={{
            WebkitTextStrokeColor: "white",
            WebkitTextStrokeWidth: "1px",
          }}
        >
          TESTIMONIAL
        </motion.h2>
        <TestimonialCard />
      </motion.div>

      <motion.img
      title="nyc Testimonial"
      loading="eager"
        initial={{x:"100%",scale:1}}
        whileInView={{x:0,scale:1.2}}
        viewport={{once:true}}

        transition={{
          duration:1.2
        }}
        width={"100%"}
        height={"100%"}
        src="https://res.cloudinary.com/dueulh8fr/image/upload/v1692726680/ncy%20Home%20Images/testimonial_twp5nn.webp"
        alt="nyc testimonial"
        className="w-[84%] md:w-[62%]  lg:w-[52%] xl:w-[42%]  z-10 self-end object-contain "
      />
</div>


    </div>
  );
}

export default Testimonial;
