import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion'
import Accordion from "../../Components/Accordian";
 
function CRMSoftwares() {

    const data ={
        title: `WEB apps`,
        description:"Begin your internet business enterprise with our assistance. We excel at creating user-friendly, high-performing websites. Imagine seeing your concepts come to life online! Our incredibly smart professionals will chat with you to learn exactly what you want, whether you want a unique location for your consumers or a hip online store. Then, they'll create a website that improves your company and makes customers love it. It's comparable to having a friend who is skilled at construction and who builds a treehouse in your garden that all of your friends adore. Similar to those buddies, our creations are treehouses that everyone can visit and enjoy online.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712602/lotties%20Json%20Files/WebApp_rvikaa.json",
        head:{
          title:"Web App Development Services| NYC Pro Web Designers | NY 10036",
          description:" We provide complete web application services, including designing, building, testing, and ongoing support and maintenance",
          canonical:`<link href="https://nycprowebdesigners.com/web-app-development-services" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. How long does it take to develop a web?",
        answer:`<p>The timeline for developing a website can vary significantly based on its complexity and features. On an average scale, a basic website could be accomplished within a few weeks, while more intricate projects might necessitate several months for full realization.</p>`,
      },
      {
        question:
          "2. What is so different about NYC Pro Web Designers?",
        answer: `<p>What distinguishes NYC Pro Web Designers is their distinctive fusion of imaginative flair and technical acumen. They adeptly shape visually appealing websites while ensuring flawless functionality and user experience, culminating in websites that genuinely set new standards.
        </p>`,
      },
      {
        question: "3. How many types of web can you develop?",
        answer:
          "<p>Our capabilities extend to a wide array of website types, encompassing, though not confined to, e-commerce platforms, corporate sites, blogs, portfolios, and interactive web applications. Each creation is meticulously tailored to precise requisites and objectives.</p>",
      },
      {
        question: "4. What is the tech stack you use?",
        answer:
          "<p>The technological arsenal we employ encompasses an assortment of tools and technologies, including HTML5, CSS3, JavaScript (inclusive of frameworks like React and Vue.js), backend tools like Node.js or Django, along databases like MySQL or MongoDB. This adaptable toolkit empowers us to fabricate dynamic, responsive websites with peak performance.</p>",
      }
    ];
  return (
    <ServicesPage data={data} >
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Digital Solutions For Businesses: Web Application Development Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Having a strong internet presence is crucial in today's society. Making sure you stand out in a crowded place is analogous. Our team of specialists can assist you in building incredible websites and online applications that outperform those of your rivals. Our team uses the most recent technology and effective procedures to make sure your website is simple for users to use, whether you need a brand-new one or want to improve your present one.
          <br />
          Imagine creating a cool treehouse where you can hang out with your buddies. We take your concepts and create a wonderful online store where people can visit and learn more about you or purchase what you sell.
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Creating A Web Application: A Step-By-Step Guide
          </h2>       
          <ul>
            <li>
              <strong>Strategic Planning: Crafting a Path to Success 
              </strong>
            </li>
            <p className="text-justify text-lg">
            To fully comprehend your objectives and target market, our knowledgeable strategists collaborate closely with you. We construct a thorough blueprint that ties your company's goals to market trends and lays the groundwork for successful web app development.
          </p>

            <li>
              <strong>Product Design: Igniting Creative Brilliance</strong>
            </li>

            <p className="text-justify text-lg">Our skilled designers combine creativity with functionality to produce an engaging, user-friendly online application. Enabling individuals to be captivated and for your product to stand out, we prioritize user-centric design concepts, easy navigation, and visually appealing interfaces.</p>
            <li>
              <strong>Development: Manifesting Vision into Reality</strong>
            </li>
            <p className="text-justify text-lg">
            The strength, scalability, and security of your dream web project are ensured by skilled developers using cutting-edge technology. We carefully build each component, from front end to back end, for maximum performance and an enjoyable experience for users.
          </p>


          <li>
              <strong>Quality Assurance: Delivering Impeccable Results</strong>
            </li>
            <p className="text-justify text-lg">
            Your web application will run flawlessly, work perfectly, and be secure thanks to our committed quality assurance team's diligent testing and attention to detail. With a strategic approach, eye-catching design, and uncompromising dedication to excellence, we'll amplify your company's presence online while creating outstanding web apps that have a lasting impression. Come along with us as we develop a genuinely unique web application.
          </p>

    
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Custom Web Application Development Services
          </h2>
          <br />
          <p className='text-justify'>From conception through deployment, we provide specialized web application development services that are in line with your particular company's needs. To fully customize a solution that improves your brand identification and user engagement, our skilled developers closely collaborate with you to comprehend your needs, examine your processes, and construct the solution. Count on us to produce a unique web app that distinguishes your company.
          </p>
          <ul>
            <li>
              <strong>Building Dynamic, Scalable Solutions: PHP Web App Dev</strong>
            </li>
            <p>
            PHP, a sought-after server-side scripting language, renowned for its adaptability and scalability, takes center stage. Our team of PHP experts harnesses their expansive knowledge and know-how to construct dynamic, sturdy web applications aligned with your business demands. Whether it's an e-commerce platform, content management system, or custom app, count on us for remarkable outcomes.
            </p>
            <li>
              <strong>Creating Interactivity With AngularJS:</strong>
            </li>
            <p>Our AngularJS web application development services step up to the plate. AngularJS, a dynamic JavaScript framework, facilitates crafting dynamic, responsive web apps. Our AngularJS developers leverage this framework's capabilities, forging feature-rich, user-friendly web applications, driving audience engagement, and increasing conversions. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Types Of Applications We Provide: 
          </h2>
          <br />
          <p className="text-justify">
          Reinforcing Your Business with Innovative Solutions with;
          </p>
          <ul>
            <li>
              <strong>1. Enterprise Web Solutions: </strong>
            </li>
            <p>
            Our expertise is in developing scalable, secure web applications that seamlessly interface with your systems to improve CRM and project management cooperation.

            </p>
            <li>
              <strong>2. Web Portals: </strong>
            </li>

            <p>
            Your virtual entryway to a global online presence is one of our web portals. For both employees and customers, our talented developers design user-centric, brand-aligned portals, increasing engagement and pleasure.
            </p>
            <li>
              <strong>3. Customer-Centric Applications: </strong>
            </li>
            <p>
Your virtual entryway to a global online presence is one of our web portals. For both employees and customers, our talented developers design user-centric, brand-aligned portals, increasing engagement and pleasure.

            </p>

            <li>
              <strong>4. Web Applications For Supply Chain Management: </strong>
            </li>
            <p>
            Our web tools save costs and increase operational effectiveness by streamlining supply chain management, enhancing inventory supervision, and optimizing logistics.
            </p>

            <li>
              <strong>5. Digital Services Online: </strong>
            </li>
            <p>
            With our safe and user-friendly web applications, you can give your company a digital augment. We provide well-developed online booking and virtual consultation services for a seamless customer experience.
            </p>

            <li>
              <strong>6. E-commerce Ventures:  </strong>
            </li>
            <p>
            With our specialized e-commerce web software, you may maximize your online trading potential. We guarantee a flawless purchasing experience, from product catalog duration through secure payment channels. Increase sales and easily grow your online business.
            </p>

            <li>
              <strong>7. Healthcare Web Solutions: </strong>
            </li>
            <p>
            Modern healthcare web applications that we create streamline procedures, enhance patient care, and increase productivity.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Enhancing Web Security For Enhanced Resilience 
          </h2>
          <p className='text-justify'>
          To safeguard your online presence and client data, we place a high priority on web security. Our procedures guarantee total assurance in cyber security;
          </p>
          
          <ul>
            <li>
              <strong>Customer-Centric Security: Our Prime Focus </strong>
            </li>
            <p>
            Security for our customers comes first. We put rigorous security measures in place to safeguard client data and provide a secure online experience. Customer confidence is our priority, from encrypted transactions to secure logins.
            </p>
            <li>
              <strong>Data Protection: Safeguarding Your Most Precious Resource 
</strong>
            </li>

            <p>
            We put a high priority on protecting your data's security using innovative encryption, backups, and strong access controls, guaranteeing privacy. You can concentrate on your business knowing that your information is secure with us.
            </p>
            <li>
              <strong>Application Shielding: Safeguarding Your Web Application from Threats  </strong>
            </li>
            <p>
            We place a high priority on the security of your online application and use cutting-edge techniques, such as secure coding and routine assessments. Count on us to keep it safe from threats so you can concentrate on having a great customer experience.

<br />
Let's collaborate to reinforce your web security and safeguard what holds the utmost significance for you!
            </p>
          </ul>
        </motion.div>
<br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Technology Framework: Empowering Innovation And Performance 
          </h2>
          <br />
          <p className="text-justify">
          Let's delve into the essential elements of our technology framework and how they enable us to craft exceptional digital experiences for our clients by;
          </p>
          <ul>
            <li>
              <strong>Frontend Development Languages: 
</strong>
            </li>
            <p>
            Proficient in HTML, CSS, and JavaScript, our skilled developers breathe life into visual aesthetics and interactivity. Whether it's responsive web design or intuitive mobile applications, our frontend development languages ensure a smooth and engaging user experience that captivates your audience.
            </p>
            <li>
              <strong>Backend Development Languages: </strong>
            </li>

            <p>
            Our team excels in backend development languages like Python, Java, and Node.js. These robust languages empower us to create scalable and efficient server-side logic, seamlessly managing data processing, API integrations, and intricate business logic. 
            </p>
            <li>
              <strong>Platform: </strong>
            </li>
            <p>
            Be it WordPress for content management, Shopify for e-commerce, or bespoke platforms customized to your needs, our team guarantees the solidity, security, and user-friendliness of your chosen platform. With our platform mastery, we enable you to optimize efficiency, streamline operations, and accomplish your business objectives.
            </p>
            <li>
              <strong>Databases / Data Storage: </strong>
            </li>
            <p>
            Our expertise encompasses various databases and data storage solutions, including MySQL, MongoDB, and Amazon S3. From designing efficient database structures to implementing data encryption and backup strategies, we ensure the security, accessibility, and scalability of your information to accommodate your growth requirements.
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Merits Of Availing Web App Dev Services By Us:
          </h2>  
          <ul>
            <li>
              <strong>Enhanced Reach:  &nbsp;</strong>
              Web applications grant users the flexibility to connect from any device linked to the internet, ensuring utmost convenience.
            </li>
            <li>
              <strong>Versatile Compatibility: &nbsp;</strong>
              Web applications function seamlessly across multiple platforms, encompassing laptops, smartphones, and tablets, broadening their user base.
            </li>

            <li>
              <strong>Streamlined Updates and Upkeep:  &nbsp;</strong>
              Web applications facilitate centralized updates and maintenance, assuring users of automatic access to the latest version without necessitating manual intervention.
            </li>

            <li>
              <strong>Budget Efficiency:  &nbsp;</strong>
              The creation of a web application often proves more cost-effective compared to constructing separate native applications for diverse platforms.
            </li>

            <li>
              <strong>Scalable Performance: &nbsp;</strong>
              Web applications exhibit the ability to accommodate a substantial volume of concurrent users, rendering them ideal for businesses with projections of expansion.
            </li>

            <li>
              <strong>Effortless Fusion:  &nbsp;</strong>
              Web applications readily harmonize with other web-based services and APIs, augmenting both functionality and user experience.
            </li>

            <li>
              <strong>Real-time Revisions:  &nbsp;</strong>
              Modifications applied to a web application manifest instantaneously for users, negating the need for them to download and install updates.
            </li>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-seo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why Opt For Our Partnership In Achieving Digital Excellence?
          </h2>  
          <br />
          <p className="text-justify">
          We offer a compelling array of reasons for selecting us as your preferred partner. Let's delve into the key differentiators that distinguish us from the competition;
          </p>
          <ul>
            <li>
              <strong>Unmatched Mastery: Experts in Our Field
          </strong>
            </li>
            <p>
            With a wealth of experience and a team comprising highly adept professionals, we bring an unparalleled level of expertise to the table. Our skilled developers, designers, and strategists are true virtuosos in their respective domains. They remain consistently updated with the latest industry trends and technologies. 
            </p>
            <li>
              <strong>Innovative Resolutions: Propelling Your Progress</strong>
            </li>

            <p>
            Innovation is deeply ingrained in our ethos. We thrive on pushing the boundaries and devising ingenious solutions to intricate challenges. From user-friendly interfaces that captivate users to robust backend systems, we ensure that your digital footprint stands out, leaving an enduring impression on your intended audience. 
            </p>
            <li>
              <strong>Collaborative Methodology: Your Vision, Our Proficiency </strong>
            </li>
            <p>
            Our collaborative methodology guarantees that your input remains highly valued throughout the developmental journey. The outcome is a solution that harmonizes seamlessly with your brand identity and business goals.

            </p>
            <li>
              <strong>Punctual Delivery: Precision in Meeting Timelines
</strong>
            </li>
            <p>
            The significance of punctual delivery in today's swiftly evolving digital realm cannot be overstated. Our team stands committed to precision in adhering to timelines, ensuring that your project is handed over punctually and within the stipulated budget. 
            </p>

            <li>
              <strong>Robust Security: Shielding Your Digital Holdings
</strong>
            </li>
            <p>
            The security of your digital possessions and sensitive data is of paramount importance. We accord top priority to implementing robust security measures that safeguard your information, guaranteeing your tranquility of mind. 
            </p>
          </ul>
       
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default CRMSoftwares