import React from "react";
import ServicesPage from "../../Components/ServicesPage";
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function GameDevelopment() {
  const data = {
    title: `Game Development`,
    description:
      " NYC Pro Web Designers says hello to you as a leading game development company. Here we turn your ideas into captivating gaming experiences. Our skilled team of developers brings your vision to life, whether you're an indie developer or an established studio.",
    upperStyle: false,
    font: true,
    api: "https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712597/lotties%20Json%20Files/game_pvbhx4.json",
    head:{
      title:"Game Development Services | NYC Pro Web Designers | NY 10036",
      description:"Elevate your gaming projects with our Game Development Services | NYC Pro Web Designers, where creativity meets excellence",
      canonical:`<link href="https://nycprowebdesigners.com/game-development-services " rel="canonical">`
    }
  };

  const faqs = [
    {
      question: "Q1. What types of game development services do you provide?",
      answer:
        "<p>We offer a range of game development services tailored to cater to your preferences and needs. Our range of services encompasses aspects of game development including creating games, for desktop virtual reality (VR) augmented reality (AR) blockchain technology, HTML5 platforms Unity 3D engine, Unreal Engine, nonfungible tokens (NFTs), and metaverse environments.</p>",
    },
    {
      question:
        "Q2. How can your game development services enhance player experiences?",
      answer: `<p>Our team specializes in crafting captivating gameplay experiences that truly engage players. Through graphics and immersive mechanics, we ensure that our games offer an exciting adventure that keeps players coming back for more.</p>`,
    },
    {
      question: "Q3. Can you develop games for platforms? ",
      answer:
        "<p>Absolutely! We provide Mobile Game Development Services catering to both iOS and Android devices. Our games are optimized to deliver performance on smartphones, tablets, and other mobile devices offering a gaming experience</p>",
    },
    {
      question:
        "Q4. What is the process of collaborating with you for game development?",
      answer:
        "<p>Our approach to game development involves collaboration with our clients. We work closely with you to understand your vision and goals. From the concept stage to the deployment phase, we keep you involved every step of the way. Regular updates are provided along with incorporating your feedback throughout the process to ensure that the end product perfectly aligns with your vision.</p>",
    },
    {
      question: `Q5. Can you assist with strategies to make money from games?`,
      answer:
        "<p>Absolutely! We can guide a variety of monetization strategies, for your games. Our team is skilled in creating models for in-app purchases integrating advertisements and developing premium content strategies that not only generate revenue but also enhance the player experience.</p>",
    },
  ];

  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Gaming Through State-of-the-art: Game Development Services
          </h2>
          <br />
          <p className="text-justify text-lg">
        Greetings, future game creators and enthusiasts!

            <br />
            Are you prepared to transform your gaming visions into reality? You're in luck, as our game development company specializes in turning your fantastic concepts into incredible games.
Imagine you possess a thrilling game concept, such as an adventure through a mystical sphere, battling formidable creatures, or crafting your virtual universe. We're here to help bring those visions to life! Whether you're an individual bursting with imagination or a renowned gaming company, we have got you covered.
    <br />
    Our range of game development services is extensive, and the cherry on top is, it's hassle-free. Leave your worries behind; we'll take your concepts and craft games that will astonish players worldwide.
    <br />
    If you aspire to create the next gaming sensation, regardless of whether you're a youngster with boundless creativity or an adult with a business mindset, count on us to make it a reality.
Let's transform your concepts into games that will leave everyone exclaiming, "Wow, that's incredible!"
<br />
 Get ready to give new meaning to your gaming dreams with us!

          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Enter The World Of Gaming On The Move

          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Mobile Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            In the era of smartphones, the era of mobile gaming has transformed into a worldwide sensation. Our proficient team specializes in crafting exciting and habit-forming mobile games for players to relish anytime and anywhere. Using advanced technologies and innovative strategies, we create immersive experiences that keep players engaged and longing for more, we deeply comprehend the significance of crafting immersive encounters for players on their smartphones. 
            </p>
            <li>
              <strong>
              Designing Board Games For Unforgettable Tabletop Journeys
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Explore the universe of board games through our dedicated board game development solutions. We bring your concepts to life, developing distinctive and interactive experiences for players to relish. From tactical gameplay to alluring storytelling, our team guarantees that every facet of your board game is meticulously designed to mesmerize players and establish enduring memories.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Redefining Limits And Challenging Reality
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Unreal Game Development
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Step into a domain of boundless possibilities through our Unreal game development solutions. By harnessing the prowess of Unreal Engine, we fabricate visually stunning and lifelike games that transport players to new dimensions. From intricate details to dynamic surroundings, our team ensures your game stands out and offers an unforgettable gaming journey. Our team of skilled developers harnesses Unreal Engine's capabilities to create visually striking and realistic games. 
            </p>
            <li>
              <strong>
              Unity 3D Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Experience the might of Unity 3D game development as we transform your gaming concepts into vibrant realities. Our talented developers utilize the capabilities of Unity 3D to produce visually remarkable and seamless gameplay encounters. From immersive landscapes to enthralling characters, we ensure that every element of your game is meticulously designed to enthrall players and leave an indelible mark. We specialize in shaping memorable gaming experiences. 
            </p>
            </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Revolutionizing Gaming With Blockchain Innovation
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Web3 Game Development  
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Witness the gaming future with our Web3 game development services. By harnessing the prowess of blockchain technology, we produce games that deliver decentralized and transparent experiences. From play-to-earn mechanics to in-game economies, our Web3 games redefine the player-developer relationship, empowering gamers to genuinely possess their gaming experiences.
            </p>
            <li>
              <strong>
              NFT Game Development
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Enter the sphere of NFT game development and uncover a fresh domain of digital possession. Our team specializes in devising games that incorporate non-fungible tokens (NFTs), enabling players to amass, exchange, and possess distinctive in-game assets. With our mastery of blockchain technology and gaming, we assist you in tapping into the flourishing NFT gaming market, creating immersive encounters that redefine digital ownership.
            </p>

            <li>
              <strong>
              Metaverse Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Embark on a voyage through the metaverse with our metaverse game development services. We shape games that transcend traditional confines, permitting players to fully engage themselves in interconnected virtual worlds. From social connections to cooperative gameplay, our metaverse games empower players to explore, craft, and connect in unprecedented ways.
            </p>            
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-seo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Enjoyable And Interactive Games For Every Platform
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              HTML5 Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Delight in games that run seamlessly across all platforms with our HTML5 game development services. Our adept developers harness the capabilities of HTML5 technology to shape amazing and interactive gaming experiences. Whether you're gaming on a desktop, laptop, or mobile device, our HTML5 games guarantee the joy of gaming wherever you may be.

            </p>
            <li>
              <strong>
              AR&VR Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Dive into augmented and virtual worlds with our AR & and VR game development services. Utilizing state-of-the-art technologies, we forge games that blur the line between real and virtual worlds. From mind-bending augmented reality experiences to fully immersive virtual reality adventures, our AR & VR games transport players to novel dimensions of exhilaration and immersion.
            </p>           
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Gaming With Trust And Clarity
          </h2>
          <br />
          <ul>
            <li>
              <strong>
              Blockchain Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Witness the gaming future through our blockchain game development services. By employing the capabilities of blockchain technology, we fabricate games that provide secure and transparent experiences. From decentralized in-game economies to provably equitable gameplay, our blockchain games revolutionize player interactions with virtual universes, ensuring trust and equity at every juncture.
            </p>
            <li>
              <strong>
              Desktop Game Development 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Raise your gaming escapade with our desktop game development solutions. Our proficient developers craft immersive and high-performance games meticulously optimized for desktop platforms. From stunning visuals to seamless gameplay, our desktop games furnish an unforgettable gaming experience right at your fingertips.
            </p>           
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Why Choose Our Team For Your Game Development Requirements?
          </h2>
          <br />
          {/* <p className="text-justify text-lg"> */}
            <ul>
              <li> <strong>
                1. Immersed in Game Development Expertise:
                 </strong>
                 </li>
              <p>
                Our team of skilled game developers is deeply passionate about
                crafting captivating and immersive gaming experiences. With
                their extensive knowledge of the gaming industry, they are
                dedicated to creating exceptional games that truly engage
                players.
              </p>
              <li>
                <strong>

                2. Agile Approach:
                </strong>
                </li>
             
              <p>
                Employing an agile development strategy enables us to swiftly
                adapt to evolving requirements. This ensures the timely delivery
                of top-quality games within budget, while also granting us the
                flexibility to accommodate necessary modifications throughout
                the development process.
              </p>
              <li>
                <strong>
                3. Diverse Engagement Options:
                </strong>
                </li>
              <p>
                We provide a range of engagement models tailored to your
                specific needs. Whether you favor a fixed-price arrangement, a
                time and materials model, or a dedicated team structure, our
                services can be customized to offer optimal value for your
                investment.
              </p>
              <li>
                <strong>

                4. Broad Portfolio:
                </strong>
                
                </li>
              <p>
                Our extensive array of completed projects underscores our
                versatility and mastery in game development. From casual mobile
                games to intricate multiplayer experiences, we've effectively
                delivered diverse gaming ventures spanning various genres and
                platforms.
              </p>
              <li>
                <strong>5. Prioritizing Accessibility:</strong>
              </li>
              <p>
                Inclusivity is a paramount concern in our game development
                approach. With utmost care, we intricately fashion our games to
                ensure that people with varying abilities can partake in and
                relish our creations. Our primary aim is to establish
                interactive encounters that cater to all individuals, regardless
                of their cognitive or cultural backgrounds, ensuring inclusivity and accessibility for everyone.
              </p>
              <li>
                <strong>6. Adaptable Communication:</strong>
              </li>
              <p>
                We recognize the significance of seamless communication
                throughout the development journey. Our team is firmly committed
                to maintaining transparent and open lines of dialogue, keeping
                you consistently informed and engaged at each phase of the
                process. We deeply value your input and feedback, guaranteeing
                that your vision materializes in the final product.
              </p>
            </ul>
          {/* </p> */}
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-social p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
            Our Comprehensive Process Ensures A Smooth Voyage
          </h2>
          <br />
          <ul>
            <li>
              <strong>Gathering Your Requirements:</strong>
            </li>
            <p>
              We invest time in comprehending your game vision and objectives,
              collecting all essential details to craft a personalized solution.
            </p>

            <li>
              <strong>Crafting A Plan:</strong>
            </li>
            <p>
              Our team carefully strategizes each phase of the development
              journey, creating a clear route to efficient execution.
            </p>

            <li>
              <strong>Creating Art Concepts And Assets:</strong>
            </li>
            <p>
              Our skilled artists breathe life into your game through
              captivating art concepts and the production of top-notch assets
              that enhance their visual allure.
            </p>

            <li>
              <strong>Developing And Testing The Game:</strong>
            </li>
            <p>
              Our experienced developers code the game, integrating features and
              mechanics that align with your vision. Thorough testing guarantees
              a seamless, glitch-free experience.
            </p>

            <li>
              <strong>Providing Support & Maintenance:</strong>
            </li>
            <p>
              We offer continual assistance and upkeep to tackle any arising
              issues, ensuring your game remains in prime condition.
            </p>
            <li>
              <strong>Managing Game Operations And Monetization:</strong>
            </li>
            <p>
              We guide you in optimizing your game for ongoing operations,
              implementing strategies for revenue generation, and heightened
              player engagement.
              <br />
              Are you ready to give life to your game? Reach out to us today and
              let's embrace this thrilling adventure together!
            </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  );
}

export default GameDevelopment;
