import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";


function WordPressDevelopment
() {

    const data ={
        title: `Wordpress\n Development`,
        description:"Are you looking for WordPress whizzes to create beautiful and useful websites? This is where your search comes to an end. Meet the digital artists who make up our team; they know how to release alluring, amazing websites. We're your go-to web superheroes, ready to make your online vision a reality, whether it's a modest blog or a cutting-edge online store.",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712602/lotties%20Json%20Files/wordpress_jy8wqq.json",
        head:{
          title:"WordPress Development Services | NYC Pro Web Designers | NY 10036",
          description:" Maximize your online presence with our WordPress Development Services. We expertly build, customize, and optimize websites for you",
          canonical:`<link href="https://nycprowebdesigners.com/wordpress-development-services" rel="canonical">`
        }
    }
    const faqs = [
      {
        question: "1. What does the Define stage in your IT process involve?",
        answer: `<p>The Define stage entails close collaboration to grasp your objectives, needs, and project hopes.</p>`,
      },
      {
        question: "2. What occurs during the Design stage? ",
        answer: `<p>In the Design stage, our skilled team develops user-friendly and visually pleasing solutions based on the established project requirements.
          </p>`,
      },
      {
        question: "3. How do you ensure prompt delivery in the “Deliver” stage?",
        answer:
          "<p>During the “Deliver” stage, we emphasize smooth execution and timely completion by paying meticulous attention to details and employing efficient project management.</p>",
      },
      {
        question: "4. What is encompassed in the “Develop” stage?",
        answer:
          "<p>The “Develop” stage involves our proficient developers coding and refining your solution for optimal performance, ensuring it aligns with your specific requirements.</p>",
      },
      {
        question: "5. How does your distinct approach benefit my project?",
        answer:
          "<p>Our distinctive approach of defining, designing, delivering, and developing guarantees a customized, inventive project that's delivered with exceptional quality.</p>",
      },
    ];
  
  return (
    <ServicesPage data={data} >
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Our Outstanding WordPress Development Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Are you looking for people who can use WordPress to create truly outstanding websites? Your search is finished, then! We are a business that excels at creating websites that look fantastic and function fantastically.
            <br />
            We have a lot of bright, intelligent people who are WordPress gurus. Websites that they create can perform fascinating tasks in addition to being attractive to the eye. Whether you want a basic blog or a sophisticated online store, we can make it happen.
            <br />
            In essence, we're like the superheroes of website creation, and we're here to bring your concepts to life online. So, you've arrived at the perfect location if you need a great website!
          </p>

          <ul>
            <li>
              <strong>Harness Customization:</strong>
            </li>
            <p className="text-justify text-lg">
            We grasp the distinctiveness of each business and offer personalized WordPress development services. Our team collaborates closely with you to grasp your specific goals, ensuring the outcome aligns seamlessly with your brand identity. Be it tailored themes, plugins, or advanced features; we've got you covered.
            </p>

            <li>
              <strong>Revamp Your Digital Experience: </strong>
            </li>

            <p className="text-justify text-lg">
            Our comprehensive range of WordPress services encompasses all aspects of creating an impressive online presence. From responsive design and effortless navigation to optimized performance and search engine visibility, we meticulously craft a website that captivates and engages your visitors.
            </p>
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          A Unique Approach To Web Solutions: Our Process Revealed
          </h2>
          <br />
          <p className="text-justify">
          Our method of achieving web solutions stands out due to its distinctiveness. Through our Define, Design, Deliver, and Develop approach, we ensure that not only are your projects successful, but they are also finely tuned for optimal search engine performance.
          </p>
          <ul>
            <li>
              <strong>Turning Vision Into Reality: Our Route To Success</strong>
            </li>
            <p className="text-justify text-lg">
            Our belief centers on transforming your concepts into tangible realities. Our journey commences with a comprehensive Define phase, during which we closely collaborate with you to grasp your aspirations, needs, and anticipations. This phase establishes the bedrock for a conquering project.
            </p>

            <li>
              <strong>
              Discovering Ingenuity
              </strong>
            </li>

            <p className="text-justify text-lg">
            The exceptional approach encompasses the Define phase, wherein we partner with you to outline the scope and objectives of your undertaking. Subsequently, we transition to the Design stage, where our adept team fashions instinctive and visually appealing solutions. This leads us to the Deliver phase, where we breathe life into your project with painstaking precision. Finally, the development phase emerges, engaging our adept developers in crafting and optimizing your solution for peak performance.
            </p>
            <li>
              <strong>
              Our Unconventional Approach 
              </strong>
            </li>
            <p className="text-justify text-lg">
            The journey commences with a thorough Define phase, delving deep into your project prerequisites. This turns into the Design phase, during which our imaginative team gives form to your notions. Moving forward, the delivery phase ensures seamless execution and punctual completion. Ultimately, the Develop phase enters the scene, as our skilled developers fabricate robust and scalable solutions.
            </p>

            <li>
              <strong>
              The Victorious Formula
              </strong>
            </li>
            <p className="text-justify text-lg">
            Our process of defining, designing, Deliver, and Developing unlocks the door to your success. We articulate your project's aims and needs, devise ingenious solutions, yield outstanding outcomes, and create cutting-edge technology. With our guidance, your path to accomplishment stands illuminated.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Unleash Your Website's Potential With Expert WordPress Services
          </h2>
          <br />
          <p className="text-justify">
          Our team of specialists will closely collaborate with you to forge a personalized plan aligned with your business aims. From pinpointing your target audience to executing effective marketing approaches, we've got everything covered.
          </p>
          <ul>
            <li>
              <strong>
              Excellence In Front-End WordPress Development Services
              </strong>
            </li>
            <p>
            Our team of front-end WordPress developers specializes in crafting visually striking and user-friendly interfaces. We blend state-of-the-art design trends with seamless functionality, ensuring your website not only appears fantastic but also delivers an exceptional user experience.
            </p>
            <li>
              <strong>Proficient UX/UI Design And Tailored Website Creation</strong>
            </li>
            <p>
            We firmly believe that a visually enthralling and user-friendly website is pivotal in engaging your visitors. Our talented crew of designers and developers will work together to craft a striking website that not only enthralls your guests but also offers a smooth user experience. 
            </p>
            <li>
              <strong>Dedication To Security And Speed</strong>
            </li>
            <p>
            Our experts will optimize your site to ensure swift loading, seamless navigation, and an uninterrupted user experience. Furthermore, we implement robust security measures to safeguard your site against potential threats and vulnerabilities
            </p>
            <li>
              <strong>Skilled Content Transfer Services</strong>
            </li>
            <p>
            If you're considering migrating your content to a new platform, fret not. Our adept team will manage the complete content migration process, ensuring a smooth and precise transfer of your invaluable data. We make certain to retain your SEO rankings, metadata, and other crucial elements, minimizing disruptions during migration.
            </p>
            <li>
              <strong>Your Partner In Steady Expansion</strong>
            </li>
            <p>
            We firmly believe that a website is a dynamic entity that demands ongoing development and support. Our team is at your service for consistent maintenance, updates, and enhancements to ensure seamless website operation. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          A Comprehensive Site Analysis 
          </h2>
          <br />
          <p className="text-justify">
          We will scrutinize every facet of your website, encompassing design, functionality, and SEO effectiveness. Armed with our expert suggestions, you can optimize your website for enhanced visibility, heightened conversions, and an enriched user experience.
          </p>
          <ul>
            <li>
              <strong>Introducing The BuddyPress Experts </strong>
            </li>
            <p>
            BuddyPress, a potent plugin, empowers you to introduce social networking features to your WordPress website. Whether you aspire to establish a forum, generate user profiles, or enable discreet messaging, our proficiency is poised to breathe life into your vision.
             
            </p>
            <li>
              <strong>Potent E-Commerce Solutions </strong>
            </li>

            <p>
            We will guide you in configuring a secure and user-friendly online store that stimulates sales and propels your financial performance. From item listings to payment gateways, our team is committed to ensuring the success of your e-commerce website. 
            </p>
            <li>
              <strong>Ongoing Maintenance And Support </strong>
            </li>
            <p>
            Your website represents an investment, and we are dedicated to assisting you in safeguarding and enhancing it. Our continuous maintenance and support services are designed to guarantee that your website is secured and running smoothly. 
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Why opt For Our Services In WordPress Development:
          </h2>
          <ul>
            <li>
              <strong>Proficiency and Know-how:</strong>
            </li>
            <p>
            Our team consists of adept WordPress developers with vast experience in crafting top-notch, personalized WordPress solutions. Their expertise enables effective handling of various projects, ranging from uncomplicated websites to intricate e-commerce platforms. Our developers remain updated with the latest WordPress trends and adhere to best practices, ensuring your website is constructed using the most advanced methods and technologies.
             
            </p>
            <li>
              <strong>Personalization and Adaptability: </strong>
            </li>
            <p>
            The WordPress development services we provide are centered around delivering bespoke solutions that align with your precise objectives. Our developers can personalize every element of your WordPress site, including design, layout, features, and functions. With a wide array of themes, plugins, and customizations at our disposal, we can establish a website that impeccably mirrors your brand and fulfills your business aims.
            </p>
            <li>
              <strong>SEO-friendly Web Solutions:</strong>
            </li>
            <p>
            Our developers adhere to SEO best practices to guarantee your website is optimized for search engines right from the outset. By implementing correct URL structures, meta tags, keyword optimization, and other SEO strategies, we enhance your website's visibility in search engine rankings. This can lead to amplified organic traffic and heightened online prominence for your business.
            </p>

            <li>
              <strong>Adaptable Design for All Devices:</strong>
            </li>
            <p>
            We provide a smooth user experience across diverse devices and screen dimensions. We confirm your website's complete responsiveness, seamlessly adjusting to various screen sizes and orientations. This cultivates better user engagement, diminishes bounce rates, and raises overall user contentment.
            </p>

            <li>
              <strong>Continuous Assistance and Upkeep:</strong>
            </li>
            <p>
            We furnish consistent support and maintenance services to ensure your website remains secure, up-to-date, and optimized for peak performance. Our team is accessible to tackle any technical glitches, provide updates, and aid with any modifications or enhancements you might require. With our steadfast support, you can dedicate your attention to core business activities while we tend to your WordPress website.
            </p>
          </ul>
        </motion.div>


        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )

}

export default WordPressDevelopment