import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from 'framer-motion';
import Accordion from "../../Components/Accordian";


function ThreedAnimation() {
  const data ={
    title: `2D / 3D Animations`,
    description:"Hello there, You know how when you watch animations or play video games, there are these beautiful moving images that bring everything to life? Indeed, that is what we do, but online! We create amazing 2D and 3D animations that can transform your website or other online content into a vibrant journey.",
    upperStyle:false ,
    font:true,
    api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712600/lotties%20Json%20Files/ThreedAnimation_tp31ny.json",
    head:{
      title:"3D Animation | NYC Pro Web Designers | NY 10036",
      description:" 3D Animation | NYC Pro Web Designers: Where creativity meets technology, crafting immersive visual experiences",
      canonical:`<link href="https://nycprowebdesigners.com/3d-animation" rel="canonical">`
    }
}
const faqs = [
  {
    question:
      "1. What are the benefits of using animations in online content?",
    answer:
      "<p>Animations bring numerous advantages to online content. They simplify complex ideas, engage audiences with alluring visuals, and help establish a memorable brand identity. Additionally, animations can simplify technical concepts, enhance user engagement, and make your brand stand out in the digital world.</p>",
  },
  {
    question:
      "2. How do animations simplify complex ideas?",
    answer: `<p>Animations excel at breaking down intricate concepts into easily understandable visuals. Through 2D and 3D animations, our creative team can transform complicated ideas into engaging visuals, making them more accessible and memorable. This simplification helps your audience comprehend and remember important information.</p>`,
  },
  {
    question:
      "3. How do animations enhance user experience on websites?",
    answer:
      "<p>Animations play a significant role in improving user experience on websites. They can guide visitors through your services, showcase product functionalities, and provide interactive tutorials. By strategically incorporating animations, we create a seamless and immersive digital journey that captivates visitors from their first interaction with your website.</p>",
  },
  {
    question:
      "4. What sets our animation services apart?",
    answer:
      "<p>Our animation services distinguish themselves through a dedication to creative excellence, technical mastery, tailored solutions, and a collaborative ethos. Our team comprises skilled artists, designers, animators, and scriptwriters who transcend boundaries to craft animations that deeply resonate. By harmonizing ingenuity with technical prowess, we deliver visually attractive and operationally potent animations meticulously aligned with your distinctive business requisites.</p>",
  },
  {
    question: `5. How can you initiate your journey with our animation services?`,
    answer:
      "<p>To embark on an odyssey of creativity and ingenuity, simply engage in a conversation with us to explore your animation concepts. We embrace a collaborative methodology, ensuring the precise transmission of your vision. Whether your preference lies in 2D or 3D animations, our team stands ready to lift your brand's prominence and leave an indelible mark on your audience.</p>",
  },
];
  return (
    <ServicesPage data={data}>
      <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-logo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Level Up Your Brand With Our Exceptional 2D and 3D Services:
          </h2>
          <br />
          <p className="text-justify text-lg">
          Consider your favorite cartoon characters when thinking of 2D animation. On your website, we can make them talk, move, and do a variety of entertaining tasks. Make your drawings come to life!
            <br />
            Imagine being able to enter a world where there are flying automobiles, dinosaurs, or even superheroes in 3D animation. These worlds are conceivable thanks to 3D animation. It is like having your online playground.
            <br />
            Why do we act in this manner? Because we want to assist you in making your website or online content dynamic and interesting for all visitors. 
            <br />
            So, keep NYC Pro Web Designers in mind if you ever need some web design magic. We're here to make your internet fantasies come true!
          </p>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Shaping an Enhanced User Experience through Compelling Visuals:
          </h2>
          <br />
          <p className="text-justify text-lg">
          We recognize the profound impact of visual content in elevating user experiences. Our skilled team seamlessly blends imagination and technology to craft enthralling 2D and 3D animations that forge enduring connections. By seamlessly integrating visually enchanting components, we ensure your website captivates and gratifies your audience, fostering enduring loyalty.
          </p>
          <ul>
            <li>
              <strong>
              Transmitting Elaborate Concepts via Animated Narratives:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Our skilled team excels at metamorphosing tangled ideas into visually enthralling narratives. Through the medium of animation, we possess the capability to streamline messages, rendering them more approachable for your audience to comprehend. This not only aids their understanding of the concept but also forges a profound connection with your brand. 
            </p>
            <li>
              <strong>
              Enthralling Interest in Striking Visual Embellishments:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            In today's whirlwind digital realm, seizing attention stands as a paramount objective. Our web design maestro specializes in fabricating arresting visual effects that grant your website unparalleled prominence. From exquisite transitions to mesmerizing particle displays, we guarantee your website seizes the rapt attention of visitors, etching an indelible mark.
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-web p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Forging Brand Identity with Unforgettable Animated Logos:
          </h2>
          <br />
          <p className="text-justify text-lg">
          Your logo functions as the countenance of your brand, and animated logos thrust it into the sphere of the extraordinary. Our gifted designers breathe life into your logo through animation, infusing dynamism and character reflective of your brand's essence. A memorable animated logo not only ensnares gazes but also creates an enduring imprint in your audience's psyche.
          </p>
          <br />
          <ul>
            <li>
              <strong>
              Enhancing Conversion Rates with Interactive Animation:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Interactive animations hold the prowess to transmute passive visitors into engaged patrons. By interweaving interactive facets into your website, we fabricate an immersive milieu that catalyzes user engagement. Be it interactive product showcases, alluring quizzes, or enthralling games, we shall propel your conversion rates skyward, fostering business expansion.
            </p>
            <li>
              <strong>
              Forging Immersive Virtual Expeditions for Realty and Travel:
              </strong>
            </li>
            <p className="text-justify text-lg" >Within the domains of real estate and tourism, virtual expeditions wield transformative potential. Our team forges immersive virtual journeys, permitting your audience to traverse properties or destinations from the confines of their abodes. By delivering a pragmatic and interactive encounter, you can present your offerings in a singularly amazing and authentic manner.</p>
            </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Elevating E-learning with Interactive and Enlightening Animations: 
          </h2>
          <br />
          <p className="text-lg text-justify">
          In the world of e-learning, engagement reigns supreme. Our interactive and educational animations are meticulously tailored to infuse mirth and involvement into learning. From animated tutorials to interactive quizzes, we orchestrate the delivery of educational content that arrests your audience's attention and resonates deeply.
          </p>
          <ul>
            <li>
              <strong>
              Enthralling Social Media Audiences through Animated Infographics:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            In this digital age, seizing attention on social media stands as a pivotal objective. Our animated infographics emerge as the quintessential conduit to convey intricate data in a visually stimulating and pleasing manner. We amalgamate compelling animations with informative substance, ensuring your message commands attention amid the social media flux.
            </p>
            <li>
              <strong>
              Delivering Potent Presentations via Animated Slideshows:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Presentations wield profound efficacy in conveying information adeptly. Our animated slideshows raise your presentations to the echelons of distinction, enriching them with dynamic visuals and seamless transitions. Capitalizing on our expertise, you can mesmerize your audience and orchestrate impactful presentations that endure in memory.
            </p>
                     
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-seo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Unconventional Animated 2D Content:
          </h2>
          <br />
          <p className="text-lg text-justify">
          Within the bustling digital expanse, differentiation emerges as a cardinal imperative. Our distinctive animated content affords you a vantage point, ensnaring attention and bequeathing an indelible imprint. Be it animated banners, elucidative videos, or interactive constituents, we empower you to craft content that distinguishes your brand in resplendent isolation.
          </p>
          <ul>
            <li>
              <strong>
              Generating Marvel 2D Animations:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            We reticulate your story through the spell of our 2D animations. Our enthusiastic and skilled team of artists and professionals collaborates smoothly to turn your concepts into real-life animations. 2D animations are not just motile images it is more than that, we understand the fact that it is about summoning emotions and voiding a long-term imprint on the viewer’s mind. 
            </p>
            <li>
              <strong>
              Appealing 2D Video Animations Services:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            When a gridlocked visual falls short, NYC Pro Web Designer’s 2D animation services step in to rule the world of animations. Presume your ideas turning into hyped narratives, entire fluid motion, and enchanting characters. No matter if it is an advertisement, engagement of social content, or explainer videos; 2D video animations hook up the attention of the audience and light up the engagement of your brand.</p>
                     
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-logo p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Fabricating Realities With 3D Animations:
          </h2>
          <br />
          <p className="text-lg text-justify">
          Scoop out the ground of capabilities with our twenty-first-century 3D animation services. The team of armed and skilled experts that we have radiates on obstacles, transforming your howling concepts into mesmerizing three-dimensional experiences. With our detailed-oriented and leading-edge strategies and techniques, we craft visuals that outshine the screen and oscillate with your target market. 
          </p>
          <ul>
            <li>
              <strong>
              Engross In The Landscape Of 3D Video Animation Services:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            You imagine we craft this is the formula which we work on, we provide amazing 3D video animation services that give your thoughts a life. Be it constructive walkthroughs, product trials, or pictorial experiences, our 3D animations compute keenness to your concepts. Immerse your targeted audience in an era where they can engage, interact, bridge the gap, and delve into a whole new level. 
            </p>
            <li>
              <strong>
              We Craft Compelling Stories Through Animations:
              </strong>
            </li>
            <p className="text-justify text-lg" >
            We at, NYC Pro Web Designers believe that animations are not only a service it is an art that speaks volumes regarding your brand story and we know how to do that efficiently. Our professional animators, designers, and storytellers conspire deeply to grasp your brand’s goals and objectives. We understand that each animation is crucial and must be a unique mirror image of your recognition</p>    
          </ul>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          The Process of Animation:
          </h2>
          <br />
          <p className="text-lg text-justify">
          Our animation process is a detailed-oriented journey that intermixes expertise; technology and creativity. 
          </p>
          <ul>
            <li>
           <b>1. </b>It begins with the generation of an idea and brainstorming, where we develop the narrative foundation. 
            </li>
            
            <li>
            <b>2. </b> After that storyboarding, where the flow of scenes is sketched out makes sure that the storyline is smooth and seamless. 

            </li>
            <li><b>3. </b> As soon as the blueprint gets ready, our team of animators and designers blows life into your concepts and visions. </li>
            <li><b>4. </b> With careful attention to every nook and hook, be it expressions, movement, or any other detail, we generate characters that oscillate with your targeted market. 
</li>
<li><b>5.</b> Our animation process then further improves it with more artistic techniques, making sure that the visual looks stunning and polished in the outcome. </li>
              
          </ul>
          <br />
          <p className='text-justify text-lg'>
          Shake hands with us to conquer the alluring power of 2D and 3D animations. From ideation to execution, we blow life into your concepts, leaving a long-lasting impression on your audience
          </p>
          <p className='text-justify text-lg' >
          Let's hop on a journey of creativity, technology, and innovation. Ring us today to converse about your animation aspirations!
          </p>

        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-social p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Merits Of 2D And 3D Animations:
          </h2>
          <br />
          <p className="text-lg text-justify">
          In today's digital era, alluring your audience plays a vital role than ever. Visual content has become predominant in the digital compass, and animations speak volumes as one of the most monopolizing forms. Be it for education, marketing campaigns, or entertainment, 2D and 3D animations stand tall and disclose their prowess in delivering ideas and messages effectively. 
          <br />
Let's dig into the outrageous world of animations and look for the remarkable merits they bring to the table;
          </p>
          <ul>
            <li>
              <strong>
              In-depth and Compelling Storytelling 
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Both the Animations either 2D or 3D, offer an appealing canvas for storytelling. If we talk about 2D animations, the simpler the design is, it allows for aligned narratives, making difficult ideas easier. On the other side of the story, 3D animation comes up with an extra dimension, literally. It has depth is more realistic and can engage the viewer in a completely new world. By structuring these animations into your content, you can convey high-tech messages in an engaging manner that leaves a lasting impact on the user.
            </p>
            <li>
              <strong>
              Compelling Visuals That Engage
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Visuals having a good hook are the foundation of effective connections, and visual content takes this to a whole new level. The sharpness of colors, the facility of motion, and the keen importance given to detail in both 2D and 3D animations spontaneously capture the attention of the users and viewers. The engagement through it not only keeps your audience stumped but also makes your messages adhered to.</p>   
            <li>
              <strong>
              Simplifying The Entangled Ideas
              </strong>
            </li>
            <p className="text-justify text-lg" >
            The IT industry is overt for its perplexing ideas and lingo. Here, animations serve as a savior. By using 2D and 3D animations, sophisticated technical ideas can be split down into easily digestible bites. Let’s just Imagine briefing intricate cloud gauge processes or tricky coding algorithms with approachable visual symbols! This ability to simplify entangled ideas can essentially build up your content's impact.</p>  

            <li>
              <strong>
              Memorable Branding
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Generating a strong brand identity is important for any business and animations can play a major role in this regard. Incorporating animated characters or elements that provide a mirror image of your brand's values and services can help depict your company in the minds of your targeted audience. Think of these animations as digital rabbit’s feet that make your brand more engaging and memorable.</p>  

            <li>
              <strong>
              Enhanced User Experience
              </strong>
            </li>
            <p className="text-justify text-lg" >
            When visitors check in on your website, you want them to have a remarkable experience. Animations, when used accordingly, can improve the user expedition. They can contrive visitors through your services, showcase the product’s performance, and even provide engaging tutorials. In this way, your business can offer value right from the moment someone enters your digital domain.</p>  

            <li>
              <strong>
              Maintaining a Competitive Edge
              </strong>
            </li>
            <p className="text-justify text-lg" >
            In a landscape of constant technological advancement and evolving audience inclinations, incorporating animations into your content strategy becomes a testament to your dedication to remaining contemporary and pertinent. Embracing state-of-the-art methods, whether in 2D or 3D, establishes your presence as a visionary entity within a swiftly shifting environment.</p> 

            <li>
              <strong>
              Creating an emotional bond
              </strong>
            </li>
            <p className="text-justify text-lg" >
            Whether you opt for relatable characters or aim to evoke feelings through visually spellbinding scenarios, animations have the power to forge a genuine emotional connection with your viewers. This bond translates into increased engagement, bolstered trust, and ultimately, higher conversions.</p> 
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto  bg-animation p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          An Innovative Partner: Why Choose Us?
          </h2>
          <br />
          <p className="text-justify text-lg">
          NYC Pro Web Designers are the best fit to be chosen for animation services because we possess the;
          </p>
            <ul>
              <li> <strong>
              Creative Excellence:  
                 </strong>
                 Our skilled team of artists, designers, animators, and scriptwriters is thirsty to cross creative boundaries to deliver animations that speak volumes. 
              </li>
           
              <li>
                <strong>
                Technical Prowess: &nbsp;
                </strong>
                By intermixing different masteries, i.e.: creativity and technical, we craft animations that are not only compelling visually but also operational that too seamlessly. 
                </li>
             
              <li>
                <strong>
                Custom-Made Solutions:&nbsp; 
                </strong>
                 We firmly believe that every project is different and unique. That is the main reason we provide customized animation solutions according to the niche of your business and target market.
              </li>
            
              <li>
                <strong>

                Collaborative Approach: &nbsp;
                </strong>
                 We work on a co-creation formula. Throughout the whole process, we ensure your presence and keep you in the loop which results in the faithful and exact translation of your vision onto the screen. 
                </li>
                <li>
                  <strong>Meeting Deadlines:&nbsp; </strong>
                  We know how important is to meet the deadlines and deliver the services on time. We work efficiently maintaining our workflow which helps us deliver your animations on time with high quality. 
                </li>
              
            </ul>
        </motion.div>
        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>

    </ServicesPage>
  )
}

export default ThreedAnimation