import React from "react";

function CustomButton(props) {
  return (
    // <div>
    //   <button className="btn " type={props?.type} >
    //     <div onClick={props?.onClick}  className={`text-sm lg:text-base  ${props.padding? "!px-6": ""}`} >{props.title}</div>
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="25px"
    //       height="25px"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //     >
    //       <path
    //         d="M11.6801 14.62L14.2401 12.06L11.6801 9.5"
    //         stroke="white"
    //         strokeWidth="2"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       ></path>
    //       <path
    //         d="M4 12.0601H14.17"
    //         stroke="white"
    //         strokeWidth="2"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       ></path>
    //       <path
    //         d="M12 4C16.42 4 20 7 20 12C20 17 16.42 20 12 20"
    //         stroke="white"
    //         strokeWidth="2"
    //         strokeMiterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       ></path>
    //     </svg>
    //   </button>
    // </div>
    <div className="body">
      <button  className={`z-[1001]  text-sm lg:text-base btn btn-hover  ${props.padding? "!px-6": ""}`} id="con_submit"  onClick={props?.onClick} type={props?.type} >
        <span className="btn-text font-heading font-semibold hover:!text-black ">{props.title}</span>
      </button>
</div>
  );
}

export default CustomButton;
