import React from 'react'
import {motion} from 'framer-motion'

function PortfolioImage(props) {
  return (

    <motion.img
    width={"100%"}
    height={"100%"}
    loading='eager'
    title={props.item.category}
    key={props?.item?.id}
    whileHover={{scale:1.05}}
    transition={{duration:0.5}}
    id={props?.item?.category}
    onLoad={()=><p>loading...</p>}
    className={ `item rounded-3xl lg:rounded-[70px] m-2 w-[240px] h-[240px] mm:w-[280px] mm:h-[280px] lm:w-[300px] lm:h-[300px]  sm:w-[250px] sm:h-[250px]  md:w-[200px] md:h-[200px]  lg:w-[250px] lg:h-[250px]  xl:h-[260px] xl:w-[260px] xxl:h-[300px] xxl:w-[300px]  cursor-pointer hover:shadow-xl object-cover`}  src={props.item.image} alt="Project" />

  )
}


export default PortfolioImage