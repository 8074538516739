import React from 'react'
import ServicesPage from '../../Components/ServicesPage'
import { motion } from "framer-motion";
import Accordion from "../../Components/Accordian";

function MotionGraphic() {
    const data ={
        title: `Motion\n Graphic`,
        description:"Our team is a bunch of creative minds who are pretty darn good at designing and animating, and we're all about making graphics that not only look amazing but also draw your audience right in. Whether you're into 2D or 3D stuff, we're here to chat with you, understand your ideas, and make them come alive. But it's not just about pretty pictures; we're big believers in storytelling too. We want to help you tell your brand's story through dynamic motion graphics, all customized to suit your unique needs and goals. So, let's get started on creating something awesome together!",
        upperStyle:false,
        font:true,
        api:"https://res.cloudinary.com/dueulh8fr/raw/upload/v1692712598/lotties%20Json%20Files/motionGraphic_epp7rc.json",
        head:{
          title:"Motion Graphic | NYC Pro Web Designers | NY 10036",
          description:" NYC's web design professionals elevate your digital presence with captivating motion graphics and innovative design.",
          canonical:`<link href="https://nycprowebdesigners.com/motion-graphic" rel="canonical">`
        }
    }

    const faqs = [
      {
        question: "1. What's the deal with motion graphics, and how do they stand apart from traditional animation?",
        answer: `<p>Motion graphics are like the cool, modern cousin of animation. They're all about conveying information or telling stories visually using things like text, graphics, and images. The big difference? Well, motion graphics are short and sweet, often laser-focused on getting a specific message across, whereas traditional animation tends to involve characters and longer narratives.</p>`,
      },
      {
        question: "2. What software do the pros use for making motion graphics?",
        answer: `<p>The pros have some favorite tools up their sleeves. Adobe After Effects, Adobe Premiere Pro, Cinema 4D, and Blender are among the top choices. They're like the artist's palette, offering a wide range of features for creating and animating eye-catching graphics.</p>`,
      },
      {
        question: "3. Can I use motion graphics for marketing and ads?   ",
        answer:
          "<p>Absolutely! Motion graphics are marketing superheroes. They can grab attention, explain complex ideas, and make social media, websites, and video ads pop. They're like the secret sauce for conveying brand messages, promoting products, and ensuring that information sticks with your audience.</p>",
      },
      {
        question: "4. What's the secret recipe for a killer motion graphic design?",
        answer:
          "<p>Crafting a fantastic motion graphic is like making a delicious dish. You'll need a pinch of clear messaging, a dash of captivating visuals, a smooth animation blend, a sprinkle of harmonious colors and fonts, and a dash of compatibility with your target audience and platform.</p>",
      },
      {
        question: "5. How can I dive into creating my own motion graphics?",
        answer:
          "<p>Ready to take the plunge into motion graphics creation? Start by learning the basics of design and animation. Pick a software tool that suits your style. Experiment with simple projects to build your skills gradually. Online tutorials and courses can be your trusty guides. Practice, seek feedback, and stay updated on the latest trends to keep your skills sharp in this exciting field.</p>",
      },
    ];


  return (
    <ServicesPage data={data} >
       <div className="  overflow-hidden container mx-auto  mt-6 p-4 lm:p-5 text-lg">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-logo p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Power Of Visual Storytelling: Motion Graphics Services
          </h2>
          <br />
          <p className="text-justify text-lg">
          Welcome to our dedicated webpage exploring the captivating realm of motion graphics! 
            <br />
            We specialize in delivering exceptional motion graphics services that truly breathe life into your brand.
            <br />
            With our team of skilled designers and animators, we possess the expertise to craft visually stunning and immersive motion graphics that effortlessly engage your audience. Whether you're seeking 2D or 3D motion graphics, our adept artists collaborate closely with you to grasp your vision and transform it into reality.
            <br />
            Beyond crafting beautiful visuals, our motion design services encompass the power of storytelling. Our team is committed to helping you effectively convey your brand's message through dynamic motion graphics. Recognizing the uniqueness of each brand, we tailor our services to meet your distinct needs and objectives.
            <br />
            As a leading motion graphics studio, we take pride in delivering impactful animations of the highest quality. Our team keeps abreast of the latest industry trends and techniques, ensuring your motion graphics are contemporary, visually appealing, and leave an indelible impression. Whether you aim to enhance marketing campaigns, craft explainer videos, or infuse creativity into your website, our motion graphics services are poised to help you stand out. Allow us to bring your concepts to   and elevate your brand with our expert motion graphics solutions.
          </p>
          <ul>
            <li>
              <strong>Your Media With Dynamic Motion Graphics:</strong>
            </li>
            <p className="text-justify text-lg">
            Energize your media by transitioning from static to sensational through our dynamic motion graphics. Our skilled animators will infuse movement, vigor, and excitement into your content, creating an engaging and memorable experience. Enhance your brand and captivate your audience with our top-tier motion graphics services.
            </p>

            <li>
              <strong>
              Harness The Power Of Animation In Your Media:
              </strong>
            </li>

            <p className="text-justify text-lg">
            Unlock your media's full potential with the magic of animation. Our proficient animators will infuse life into your presentations, videos, and graphics, constructing a visually striking and immersive encounter. Stand out and leave an enduring impact with our captivating animation services.
            </p>
            <li>
              <strong>
              Engage And Mesmerize Through Animated Presentations:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Revitalize your presentations with animated visuals that enthrall and captivate your audience. Our animators will metamorphose static slides into dynamic and interactive presentations that leave a lingering impression. Seize attention, convey your message effectively, and craft truly memorable presentations.
            </p>

            <li>
              <strong>
              Give Life To Your Products With Striking Explainer Videos:
              </strong>
            </li>
            <p className="text-justify text-lg">
            Seize your audience's focus and highlight your products' unique features with our striking explainer videos. Our talented animators will forge compelling visuals and animations that spotlight your products' advantages and functionalities, simplifying customer comprehension and fostering a connection with your brand.
            </p>

           
          </ul>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-animation p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Elevate Your Visuals With Our Dynamic Offerings
          </h2>
          <br />
          <p className="text-justify">
          From the lively animated text that dances across screens to advanced animated titles that add excitement to your videos, our motion graphics services infuse your content with enchantment. Watch your words come to life and captivate your viewers with our captivating animations."
          </p>
          <ul>
            <li>
              <strong>From Typography To Logos, We've Got You Covered!</strong>
            </li>
            <p className="text-justify text-lg">
            Make a statement with animated typography that injects personality and flair into your visuals. Our skilled team can also breathe life into your logos, crafting eye-catching animations that leave a memorable mark. Stand out and present your brand in a fresh and distinctive light."
            </p>

            <li>
              <strong>
              Striking UI/UX Animations
              </strong>
            </li>

            <p className="text-justify text-lg">
            Elevate user experience with our captivating UI/UX animations. Seamless transitions and interactive elements will captivate your audience, creating a smooth and delightful user journey. Elevate your website or app, ensuring an indelible impact on users."
            </p>
            <li>
              <strong>
              Eye-Catching Graphic Loops And Animated Titles Await
              </strong>
            </li>
            <p className="text-justify text-lg">
            Make an unforgettable impression with our attention-grabbing graphic loops and animated titles. Whether you need to capture attention on social media or enhance your video content, our dynamic animations will make your visuals pop. Stand out and make a statement with our captivating designs."
            </p>

            <li>
              <strong>
              Let Our Animations Bring Your Vision To Life
              </strong>
            </li>
            <p className="text-justify text-lg">
            Highlight your products in the most captivating way possible. Our product animations will give life to your vision, showcasing the features and advantages of your offerings. From comprehensive views to interactive demos, our animations will make your products shine, enhancing customer engagement."
            </p>

            <li>
              <strong>
              Animated Ads That Grab Attention And Deliver Results
              </strong>
            </li>
            <p className="text-justify text-lg">
            Make your ads stand out and seize attention with our animated ad services. Our team crafts visually striking and compelling animations that captivate your audience and drive results. Boost click-through rates and conversions with our attention-commanding animated ads."
            </p>

            <li>
              <strong>
              Customize Templates And Create Stunning Visuals Effortlessly
              </strong>
            </li>
            <p className="text-justify text-lg">
            Short on time? Our template editing services simplify the customization of pre-designed templates, enabling you to craft stunning visuals swiftly. Whether you require social media graphics or video intros, our editing services save time and effort while yielding professional outcomes."
            </p>

            <li>
              <strong>
              Seamless Transitions And Effects
              </strong>
            </li>
            <p className="text-justify text-lg">
            Enhance your videos' seamless, polished quality with our transition effects. From smooth fades to dynamic wipes, our professional touch elevates video quality, captivating your audience. Make a lasting impression with visually striking transitions and effects."
            </p>
          </ul>
        </motion.div>

        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-web p-4 rounded-xl text-lg"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Stay Ahead Of The Curve: 
          </h2>
          <br />
          <p className="text-justify">
          From concept to execution, our game development services breathe life into your ideas. Whether crafting a mobile game, console game, or virtual reality experience, our expert team guides you through development, delivering a game that captivates players and beckons them back."     
          </p>
          <ul>
            <li>
              <strong>
              Simplify Business Operations
              </strong>
            </li>
            <p>
            Elevate your business with our CRM solutions. Streamline operations, manage customer relationships, and boost efficiency with our adaptable CRM platform. From lead management to customer support, our CRM solutions aid organization and growth.
            </p>
            <li>
              <strong>Optimize Business Processes</strong>
            </li>
            <p>
            Simplify and optimize operations with our ERP solutions. Our comprehensive ERP platform seamlessly integrates departments and functions, providing real-time insights and streamlining processes. From inventory management to financial reporting, our ERP solutions foster a competitive edge."
            </p>
            <li>
              <strong>Master Social Media Landscape</strong>
            </li>
            <p>
            Maximize your social media presence with our expert services. Our team crafts engaging content, manages social media accounts, and implements strategies for growth and results. Stay connected with your audience, building a robust online presence through our social media services.
            </p>
            <li>
              <strong>Harness E-Commerce Power</strong>
            </li>
            <p>
            Ready to transition online? Our e-commerce development services aid in crafting visually appealing and user-friendly online stores that drive conversions. From seamless checkouts to mobile compatibility, our e-commerce solutions ensure a seamless and enjoyable shopping experience for customers."
            </p>
          </ul>
        </motion.div>
        <br />
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          className="w-[100%] md:w-[70%] mx-auto bg-mobile p-4 rounded-xl"
        >
          <h2 className="text-center text-xl lm:text-2xl  md:text-3xl font-semibold">
          Unleash Your Potential On The Internet With Our Exceptional Skills
          </h2>
          <br />
          <p className="text-justify">
          Discover the difference through our team of specialists who possess unparalleled expertise in creating websites, providing e-commerce solutions, and managing hosting services. From attractive designs to flawless functionality, we will bring your ideas to life and help you stand out in the digital world.
          </p>
          <ul>
            <li>
              <strong>Save Time And Accomplish More With Our Effective Solutions!</strong>
            </li>
            <p>
            Bid farewell to lengthy development processes and endless revisions. Our time-efficient approach ensures prompt project completion without compromising on quality. Concentrate on expanding your business while we take care of the technical aspects, giving you more time to achieve your objectives.
            </p>
            <li>
              <strong>Enhance Your Profits with Our Budget-Friendly Services!</strong>
            </li>

            <p>
            Maximize your return on investment with our cost-effective solutions. We understand the significance of budget-friendly decisions, which is why we offer competitive pricing without compromising quality. Attain the highest value for your investment and observe your business flourish.
            </p>
           
           <br />
           <p className="text-justify">
           Connect with us today to discuss your project, and together, let's embark on an exhilarating journey of visual storytelling!
           </p>
          </ul>
        </motion.div>

        <div className="flex   flex-col-reverse items-center sm:flex-row mt-10 ">
          <div className=" sm:w-[60%] h-full  ">
            <Accordion faqs={faqs} />

            {/* <Accordion /> */}
          </div>
          <div className=" sm:w-[40%] h-full flex flex-col justify-center items-center text-4xl uppercase font-bold ">
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Frequently
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: 0.2 }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Asked
            </motion.h2>
            <motion.h2
              initial={{ x: "100%" }}
              whileInView={{ x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 2, type: "spring" }}
              className="first-letter:text-6xl first-letter:text-[#E62B4A]"
            >
              Questions
            </motion.h2>
          </div>
        </div>
      </div>
    </ServicesPage>
  )
}

export default MotionGraphic